import { Action, createReducer, on } from '@ngrx/store';
import { setHeaderSpacer } from '@store/actions/header-spacer.action';
import {
  HeaderSpacerState,
  initialHeaderSpacerState,
} from '@store/states/header-spacer.state';

const _headerSpacerReducer = createReducer(
  initialHeaderSpacerState,
  on(setHeaderSpacer, (state, action): HeaderSpacerState => {
    return {
      ...state,
      headerSpacer: action.headerSpacer,
    };
  })
);

export function headerSpacerReducer(state: HeaderSpacerState, action: Action) {
  return _headerSpacerReducer(state, action);
}
