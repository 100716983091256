import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getOrderIdAndUser } from '@store/selectors/auth.selector';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit, OnDestroy {
  details!: any;
  detailsSubscription!: Subscription;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.detailsSubscription = this.store
      .select(getOrderIdAndUser)
      .pipe()
      .subscribe(data => {
        if (data) {
          this.details = data;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.detailsSubscription) this.detailsSubscription.unsubscribe();
  }
}
