import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, map, throwError } from 'rxjs';
import { SendOtp } from '@core/models/otp';
import { BaseService } from './base.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class OtpService extends BaseService {
  constructor(
    private storageService: StorageService,
    private httpClient: HttpClient,
    private store: Store
  ) {
    super();
  }

  send(credentials: SendOtp) {
    return this.httpClient
      .post(`${this.serverRootPathV2}returns/generate-otp`, credentials)
      .pipe(
        map((val: any) => val),
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }
}
