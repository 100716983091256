<div class="cookie-toast" id="cookie-toast" *ngIf="!dismissed" [@toastAnimation]>
	<div class="cookie-toast-content">
		<h3 class="cookies-toast-heading">We use Cookies</h3>
		<br>
		<button type="button" class="cookie-toast-close-btn" (click)="dismissNotice()">&#10005;</button>
		<p class="cookie-toast-text">
			This website uses cookies and analytics technologies. We do this to better understand how visitors
			use our site and to offer you a more personal experience. By using our website, you consent to the
			recording, use,
			and sharing of your website activity by us and our service providers. Please see our <a
				href="https://deliverysolutions.co/privacy" class="privacy-link" target="_blank"
				[captureEvent]="'click-ds-policy'"><u>Privacy
					Notice</u></a> for
			more
			information.
		</p>
	</div>
</div>