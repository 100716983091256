import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { Swiper, SwiperOptions } from 'swiper/types';

@Directive({
  selector: '[appSwiper]',
})
export class SwiperDirective implements AfterViewInit {
  @Input() config?: SwiperOptions;
  swiper!: Swiper;

  constructor(private el: ElementRef<SwiperContainer>) {}

  ngAfterViewInit(): void {
    Object.assign(this.el.nativeElement, this.config);

    this.el.nativeElement.initialize();
    this.swiper = this.el.nativeElement.swiper;
  }

  next() {
    this.swiper.slideNext();
  }

  prev() {
    this.swiper.slidePrev();
  }
}
