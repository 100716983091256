import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import {
  Broadcast,
  HostedReturnsConfig,
} from '@core/models/returns-config.interface';

@Component({
  selector: 'ds-broadcast-message',
  templateUrl: './broadcast-message.component.html',
  styleUrls: ['./broadcast-message.component.scss'],
})
export class BroadcastMessageComponent implements OnInit {
  @Input() hostedReturnsConfig!: HostedReturnsConfig;

  public showBCMessage = false;
  public show = false;
  public broadcast: Broadcast = {
    active: false,
    header: '',
    body: '',
  };

  constructor(private storageService: StorageService) {}

  ngOnInit(): void {
    const { broadcast } = this.hostedReturnsConfig.components;
    this.broadcast = broadcast;

    let val = broadcast.active;
    const bcMessage = this.getCopyOfBCMessage(); // get a copy of current message
    try {
      // this checks if broadcast message has changed or not. if it did change then we need to show the message despite user having dismissed it before.
      const savedBCMessage =
        this.storageService.get(
          this.hostedReturnsConfig.brandName + 'showBCMessage'
        ) || JSON.parse('');
      if (!savedBCMessage) {
        val = true;
      } else if (
        savedBCMessage.header !== bcMessage.header ||
        savedBCMessage.body !== bcMessage.body
      ) {
        val = true;
      } else {
        val = false;
      }
    } catch {}

    this.showBCMessage = val;
  }

  public closeBCMessage() {
    this.showBCMessage = false;
    const broadCastMessage = this.getCopyOfBCMessage();
    this.storageService.set(
      this.hostedReturnsConfig.brandName + 'showBCMessage',
      broadCastMessage
    );
  }

  getCopyOfBCMessage() {
    const broadCastMessage = {
      ...this.hostedReturnsConfig.components.broadcast,
    } as any; // hack, else vs code will cry for delete on the next line since active ain't optional key
    delete broadCastMessage.active;
    return broadCastMessage;
  }
}
