<div class="nearby-locations">
	<div class="box selected-location p-3 mb-3" *ngIf="defaultScreen && customerAddress">
		<div class="box1-heading">
			Showing Nearby Stores From<br>
			<div class="addr">
				{{customerAddress.street}}<br>
				{{customerAddress.street2 ? customerAddress.street2+',' : ''}}<br *ngIf="customerAddress.street2">
				{{customerAddress.city}}, {{customerAddress.state}}, {{customerAddress.zipcode}}<br>
				{{customerAddress.country}}
			</div>
		</div>
		<div class="change-address">
			<a href="javascript:void(0)" class="text-decoration-underline" (click)="changeAddressOption()"
				[captureEvent]="'click-change-address'">Change
				Address</a>
		</div>
	</div>

	<div *ngIf="defaultScreen && customerAddress">
		<div *ngIf="storeListCount === 0" class="title text-center">No nearby stores to display</div>
		<div *ngIf="otherLocations?.length>0">
			<ng-container *ngFor="let location of otherLocations">
				<app-dropoff-location [location]="location"></app-dropoff-location>
			</ng-container>
		</div>
		<ng-container *ngIf="showNearbyStoreLoader">
			<div class="item-list-box p-3 mb-2 mb-md-3 drop-off-location placeholder-loading bg-white"
				*ngFor="let random of [1,2,3,4]">
				<div class="col-12 col-md-10 data-row mb-1 linear-background"></div>
				<div class="col-10 col-md-8 data-row mb-1 linear-background"></div>
				<div class="col-8 col-md-6 data-row mb-1 linear-background"></div>
				<div class="col-6 col-md-4 data-row linear-background"></div>
			</div>
		</ng-container>
		<div class="bg-white p-3 mb-2 mb-md-3 text-center" *ngIf="showViewMoreButton">
			<button type="button" class="w-100 btn btn-ds-primary view-more" (click)="viewMore()"
				[disabled]="showNearbyStoreLoader" [captureEvent]="'click-view-more-store'">{{!showNearbyStoreLoader ?
				'View More' : ''}}
				<span *ngIf="showNearbyStoreLoader" class="spinner-border spinner-border-sm text-dark" role="status"
					aria-hidden="true"></span>
			</button>
		</div>
	</div>

	<div *ngIf="!defaultScreen || !customerAddress">
		<app-address-form *ngIf="customerAddress" [address]="customerAddress" (getAddress)="viewStores($event)"
			[addressType]="addressTypes.NEARBY_LOCATION"></app-address-form>
		<app-address-form *ngIf="!customerAddress" (getAddress)="viewStores($event)" [showDescription]="true"
			[addressType]="addressTypes.NEARBY_LOCATION"></app-address-form>
	</div>
</div>