import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SelectedItemListState } from '@store/states/selected-item-list.state';

export const SELECTED_ITEM_LIST_STATE = 'selectedItemList';

const getItemListState = createFeatureSelector<SelectedItemListState>(
  SELECTED_ITEM_LIST_STATE
);

export const getAllSelectedItems = createSelector(
  getItemListState,
  (state: SelectedItemListState) => {
    return state?.selectedItemsDetail;
  }
);
