import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';

import { HostedReturnsConfig } from '@core/models/returns-config.interface';
import { ItemListService } from '@services/itemlist.service';
import { RedirectorService } from '@services/redirector.service';
import { StorageService } from '@services/storage.service';
import { UserPilotService } from "@core/services/user-pilot.service";
import { showToastError } from '@store/actions/error.action';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-redirector',
  templateUrl: './redirector.component.html',
  styleUrls: ['./redirector.component.scss'],
})
export class RedirectorComponent implements OnInit, OnDestroy {
  dataSubscription!: Subscription;
  hostedConfig!: HostedReturnsConfig;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public store: Store,
    public storageService: StorageService,
    private userPilotIdentifier: UserPilotService,
    private itemListService: ItemListService,
    private redirectorService: RedirectorService
  ) { }

  ngOnInit(): void {
    combineLatest([
      this.activatedRoute.data,
      this.activatedRoute.paramMap,
    ]).subscribe(([data, parameters]) => {
      if (data['configResp'] === undefined) {
        this.router.navigate(['/service-unavailable'], {
          skipLocationChange: true,
        });
        return;
      }
      this.hostedConfig = data['configResp'];
      let tenantId = '';
      let brandExternalId = '';

      const tenantIdFromUrl = parameters.get('tenantId');
      const brandExternalIdFromUrl = parameters.get('brandExternalId');

      if (tenantIdFromUrl && brandExternalIdFromUrl) {
        tenantId = tenantIdFromUrl;
        brandExternalId = brandExternalIdFromUrl;
      } else if (
        this.hostedConfig?.tenantId &&
        this.hostedConfig?.brandExternalId
      ) {
        tenantId = this.hostedConfig?.tenantId;
        brandExternalId = this.hostedConfig?.brandExternalId;
      }
      const params: any = new URLSearchParams(window.location.search);
      const token = params.get('token');
      if (!token) {
        this.router.navigate([`/${tenantId}/${brandExternalId}/login`]);
        return;
      }
      this.itemListService.getItemList().subscribe({
        next: resp => {
          const { orderExternalId, customer } = resp?.invoiceInfo ?? {};
          this.userPilotIdentifier.setupUserPilotIdentifier(customer, this.hostedConfig?.tenantId, this.hostedConfig?.brandExternalId);
          if (!orderExternalId) {
            this.router.navigate([`/${tenantId}/${brandExternalId}/login`]);
            return;
          }
          this.storageService.setNewKey(orderExternalId);
          if (resp?.token) {
            this.storageService.set('token', resp?.token);
            this.storageService.set('authResp', resp);
          }

          const entities = {
            tenantId,
            brandExternalId,
            orderExternalId,
          };
          this.redirectorService.redirectToPage(resp, entities);
        },
        error: error => {
          console.error('error while fetching itemlist in redirector=>', error);

          // n003 is added here as a dummy orderId for acheiving redirection
          const orderId = encodeURIComponent(btoa('n003'));
          this.router.navigate([`/${tenantId}/${brandExternalId}/${orderId}/main/common-error-page`]);
          return;
        },
      });
    });
  }


  ngOnDestroy(): void {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
  }
}
