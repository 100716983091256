import { Component, EventEmitter, Input, Output } from '@angular/core';

export type ItemAttributeTypeOption = { available: boolean, name: string, value?: string };
export type ItemAttributeType = {
  key: string,
  mappedKey?: string,
  name: string,
  type: 'text' | 'image',
  options: ItemAttributeTypeOption[],
  selectedOption: string,
}

@Component({
  selector: 'app-item-attribute-type',
  templateUrl: './item-attribute-type.component.html',
  styleUrls: ['./item-attribute-type.component.scss'],
})
export class ItemAttributeTypeComponent {
  @Input() attribute!: ItemAttributeType;

  @Output() bySelectAttributeType = new EventEmitter();

  selectAttributeType(selectedAttribute: ItemAttributeTypeOption) {
    if (selectedAttribute.available) {
      switch (this.attribute.type) {
        case 'text': this.attribute.selectedOption = selectedAttribute.name;
          break;
        case 'image': this.attribute.selectedOption = selectedAttribute.value!;
          break;
      }
      this.bySelectAttributeType.emit(this.attribute);
    }
  }
}
