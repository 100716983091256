import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() showBackButtonIcon = true;
  @Input() title = '';
  @Input() alignment = 'center';

  @Output() backButtonClick = new EventEmitter();

  obBackIconClick() {
    this.backButtonClick.emit();
  }
}
