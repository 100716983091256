import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-common-error-page',
  templateUrl: './common-error-page.component.html',
  styleUrls: ['./common-error-page.component.scss'],
})
export class CommonErrorPageComponent implements OnInit, OnDestroy {
  contactUrl = '';
  dataSubscription!: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.dataSubscription = this.activatedRoute.data.subscribe(data => {
      if (data['configResp'] === undefined) {
        this.router.navigate(['/service-unavailable'], {
          skipLocationChange: true,
        });
        return;
      }
      if(data['configResp']?.components?.needHelp?.active) {
        this.contactUrl = data['configResp']?.components?.needHelp?.helpUrl || '';
      }
    });
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
  }
}
