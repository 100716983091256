import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-single-ad',
  templateUrl: './single-ad.component.html',
  styleUrls: ['./single-ad.component.scss'],
})
export class SingleAdComponent {
  @Input() adConfig!: any;
  @Input() eventName: string | undefined;
}
