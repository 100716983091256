import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderExternalIdState } from '@store/states/order-id.state';

export const ORDER_EXTERNAL_ID_STATE = 'orderExternalId';

const getOrderExternalIdState = createFeatureSelector<OrderExternalIdState>(
  ORDER_EXTERNAL_ID_STATE
);

export const getOrderExternalIdFromUrl = createSelector(
  getOrderExternalIdState,
  (state: OrderExternalIdState) => {
    return { orderExternalId: state?.orderExternalId };
  }
);
