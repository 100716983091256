import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getConfig } from '@store/selectors/config.selector';
import {
  HostedReturnsConfig,
  NeedHelp,
  ReturnPolicy,
} from '@core/models/returns-config.interface';

@Component({
  selector: 'app-policy-footer',
  templateUrl: './policy-footer.component.html',
  styleUrls: ['./policy-footer.component.scss'],
})
export class PolicyFooterComponent implements OnInit, OnDestroy {
  @Input() view!: string;
  configSubscription!: Subscription;
  returnPolicy: ReturnPolicy | null = null;
  help: NeedHelp | null = null;

  constructor(private store: Store) {}

  ngOnInit() {
    this.configSubscription = this.store
      .select(getConfig)
      .pipe()
      .subscribe((config: HostedReturnsConfig) => {
        this.returnPolicy = config?.components?.returnPolicy;
        this.help = config?.components?.needHelp;
      });
  }

  ngOnDestroy(): void {
    if (this.configSubscription) this.configSubscription.unsubscribe();
  }
}
