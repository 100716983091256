import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormGroup, ControlContainer, UntypedFormGroup } from '@angular/forms';
import { takeUntil, first, delay } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  SearchCountryField,
  CountryISO,
} from '@deliverysolutions/ngx-intl-tel-input';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Component({
  selector: 'ds-phone-number-input',
  templateUrl: './ds-phone-number-input.component.html',
  styleUrls: ['./ds-phone-number-input.component.scss'],
})
export class DsPhoneNumberInputComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() formControlNameCustom!: string;
  @Input() nameCustom!: string;
  @Input() disabled = false;
  @Input() inputId!: string;
  @Input() phoneValidation = true;
  @Input() placeHolder = '';
  @Input() customCss = '';
  @Output() phoneDirtyCheck = new EventEmitter<boolean>();

  form!: FormGroup;
  private destroy$ = new Subject();
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.Canada,
  ];
  customCssStr = 'form-control';
  phoneUtil = PhoneNumberUtil.getInstance();

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form = <UntypedFormGroup>this.controlContainer.control;
    this.setDisableState(this.disabled);

    if (this.form && this.formControlNameCustom) {
      const control = this.form.get(this.formControlNameCustom) as any;
      control.valueChanges
        .pipe(
          first(value => !!value),
          delay(100),
          takeUntil(this.destroy$)
        )
        .subscribe((value: any) => {
          if (value) {
            this.phoneDirtyCheck.emit(true);
            const processedValue = this.removeCountryCode(value);
            control.setValue(processedValue, { emitEvent: false });
          }
        });
    }
  }

  ngOnChanges(): void {
    this.setDisableState(this.disabled);
    this.customCssStr = `form-control ${this.customCss}`;
  }

  removeCountryCode(phoneNumber: any): string {
    if (!phoneNumber) {
      return phoneNumber;
    }

    const parsedNumber =
      typeof phoneNumber === 'string'
        ? this.phoneUtil.parse(phoneNumber, CountryISO.UnitedStates)
        : this.phoneUtil.parse(phoneNumber.number, CountryISO.UnitedStates);

    const countryCode = parsedNumber.getCountryCode();
    const e164Number = this.phoneUtil.format(
      parsedNumber,
      PhoneNumberFormat.E164
    );

    return e164Number.toString().replace(`+${countryCode}`, '');
  }

  setDisableState(value: boolean): void {
    if (this.form && this.form.controls[this.formControlNameCustom]) {
      if (value) {
        this.form.controls[this.formControlNameCustom].disable();
      } else {
        this.form.controls[this.formControlNameCustom].enable();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
