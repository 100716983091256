import { Injectable } from '@angular/core';
import { MD5 } from 'crypto-js';
import { Customer } from '@core/models/invoice-info';
import { Userpilot } from 'userpilot';
import { ActivatedRoute, Router } from '@angular/router';

const parsePathFromRoute = (router: Router) => {
  try {
    const url: string = router.url;
    if (url.match(/itemlist/)) {
      return '/itemlist'
    } else if(url.match(/login/)) {
      return '/login'
    } else if(url.match(/returns/)) {
      return '/returns'
    }
    return url;
  } catch (e) {
    return "";
  }
};

@Injectable({
  providedIn: 'root',
})
export class UserPilotService {
  constructor(private router: Router) {}
  setupUserPilotIdentifier(
    customer: Customer,
    tenantId: string | undefined,
    brandExternalId: string | undefined
  ) {
    const uniqueUserPilotIdentifier = customer.phone || customer.email;
    const userId = MD5(uniqueUserPilotIdentifier!).toString();
    Userpilot.identify(userId, {
      tenantId,
      company: {
        id: tenantId,
        module: `hosted-returns`,
        brand: brandExternalId,
      },
    });
  }

  addEventToUserPilot(captureEvent: string, path?: string) {
    if (captureEvent) {
      captureEvent = this.validateCapturedEvent(captureEvent);
      if (!path) {
        path = parsePathFromRoute(this.router);
      }
      Userpilot.track(captureEvent, {
        page: path,
      });
      Userpilot.track(`Page-${path}`, {
        event: captureEvent,
      });
    }
  }
  private validateCapturedEvent(captureEvent: string) {
    captureEvent = captureEvent
      .replace(/_/g, '-')
      .replace(/\s+/g, '-')
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .toLowerCase();
    return captureEvent;
  }
}
