export enum MethodsCategory {
  SHIP_IT_BACK = 'ship-it-back',
  SHIP_IT_BACK_QR_CODE = 'ship-it-back-qr-code',
  SHIP_IT_BACK_QR_CODE_BOX = 'ship-it-back-qr-code-box',
  RETURN_TO_STORE = 'return-to-store',
  KEEP_ITEM = 'keep-item',
  HOME_PICKUP = 'home-pickup',
  HOME_PICKUP_QR_CODE = 'home-pickup-qr-code',
  HOME_PICKUP_QR_CODE_BOX = 'home-pickup-qr-code-box',
}
