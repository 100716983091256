import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReturnsConfigService } from '@core/services/returns-config.service';
import { BaseComponent } from '@shared/components/base.component';
import { ReturnMethodGroup } from '@core/models/return-method-group';
import {
  getAuthResponse,
  getSanitisedOrderId,
} from '@store/selectors/auth.selector';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Item } from '@core/models/item';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { ReturnService } from '@features/returns/services/returns.service';
import { ReturnMethod } from '@core/models/return-method';
import { SelectedItemDetails } from '@store/states/selected-item-list.state';
import { getAllSelectedItems } from '@store/selectors/selected-item-list.selector';
import { showToastError } from '@store/actions/error.action';
import { getConfig } from '@store/selectors/config.selector';
import { HostedReturnsConfig } from '@core/models/returns-config.interface';
import { CUSTOM_ERROR_CODES } from '@constants/generic-constants';
import { MethodsCategory } from '@core/constants/method-category';
import { ExchangeMetadata } from '@core/models/return-request';
import { generateRandomString } from '@core/utils/generate-random';
import { FeeService } from '@services/fee.service';
import { FeeCalculate, FeeCalculateResponse } from '@core/models/fee-calculate';
import { UploadFileType } from '@features/returns/components/upload-file/upload-file.component';

@Component({
  selector: 'app-return-preview',
  templateUrl: './return-preview.component.html',
  styleUrls: ['./return-preview.component.scss'],
})
export class ReturnPreviewComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  urlEntities!: {
    tenantId: string | undefined;
    brandExternalId: string | undefined;
    orderExternalId: string | undefined;
  } | null;
  urlParamsLink = '';
  hostedReturnsConfig!: HostedReturnsConfig;
  returnRequestLoader = false;
  masterReturnMethodLoader = true;
  calculatedReturnFeeLoader = false;
  calculatedReturnFeeError = false;
  masterReturnMethodError = false;
  itemListSubscription!: Subscription;
  selectedItems: SelectedItemDetails[] = [];
  masterReturnMethods: ReturnMethodGroup[] = [];
  returnMethods?: ReturnMethodGroup[] = [];
  itemList: Item[] = [];
  invoice!: {
    customer: { email: string; phone: string; name: string };
    orderId: string;
    orderExternalId: string;
  };
  estimatedReturnFee = 0;
  detailsSubscription!: Subscription;
  selectedItemsSubscription!: Subscription;
  sanitizedOrderIdDetails!: { orderId: string; sanitizedOrderId: string };

  constructor(
    private router: Router,
    private store: Store,
    private feeService: FeeService,
    private returnService: ReturnService,
    private configService: ReturnsConfigService
  ) {
    super({ hostedReturnService: configService });
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    if (this.itemListSubscription) this.itemListSubscription.unsubscribe();
    if (this.detailsSubscription) this.detailsSubscription.unsubscribe();
    if (this.selectedItemsSubscription) this.selectedItemsSubscription.unsubscribe();
  }

  mapReturnReason(returnItem: Item) {
    const returnReason = !NullEmptyChecker.isDeepNull(returnItem, 'returnReason') && JSON.parse(JSON.stringify(returnItem.returnReason));

    if (NullEmptyChecker.isNonEmptyArray(returnReason?.proofOfReturnReason)) {
      returnReason.proofOfReturnReason = returnReason.proofOfReturnReason.map((proof: UploadFileType) => proof.fileUrl);
    }

    return returnReason;
  }

  getCalculatedReturnFee() {
    return new Promise((resolve, reject) => {
      if (NullEmptyChecker.isNonEmptyArray(this.returnMethods)) {
        const displayRefundMethods = this.hostedReturnsConfig?.componentVisibility?.displayRefundMethods;
        const feeCalculateBody = this.returnMethods?.map((returnMethod: ReturnMethodGroup) => {
          const itemList = returnMethod.itemList?.map(returnItem => {
            const itemFound: Item = this.itemList.find(
              item => returnItem.__refId === item.__refId
            )!;

            const item = { ...itemFound };
            delete item.returnRequest;

            const returnReason = this.mapReturnReason(returnItem);

            const itemMap = {
              ...item,
              quantity: returnItem?.quantity,
              returnReason,
              customerAddress: returnItem?.address,
              ...(returnItem.locationExternalId && { locationExternalId: returnItem.locationExternalId }),
              ...(returnItem.exchangedItem && { exchangedItem: returnItem.exchangedItem })
            };

            if (displayRefundMethods && NullEmptyChecker.isDeepNull(itemMap, 'exchangedItem')) {
              itemMap['refundMode'] = returnItem?.refundMode;
            }

            delete itemMap.address;
            return itemMap
          });

          return {
            itemList,
            returnMethod: {
              name: returnMethod.name,
              code: returnMethod.code,
              type: returnMethod.type,
              displayName: returnMethod.displayName,
            },
            noOfBoxes: returnMethod.returnLabelBox,
            componentId: returnMethod.componentId,
          }

        });

        this.calculatedReturnFeeLoader = true;
        this.calculatedReturnFeeError = false;
        this.feeService.getCalculatedReturnFee({ list: <FeeCalculate[]>feeCalculateBody }).subscribe({
          next: calculatedReturnFee => {
            this.calculatedReturnFeeLoader = false;
            this.calculatedReturnFeeError = false;
            if (NullEmptyChecker.isNonEmptyArray(calculatedReturnFee)) {
              this.mapCalculatedReturnFee(calculatedReturnFee);
            }
            resolve(calculatedReturnFee)
          },
          error: error => {
            this.calculateReturnFee();
            console.error('Error while fetching calculated return fee=>', error);
            this.calculatedReturnFeeLoader = false;
            this.calculatedReturnFeeError = true;
            reject(error)
          },
        });
      } else {
        this.calculateReturnFee();
        this.calculatedReturnFeeLoader = false;
        this.calculatedReturnFeeError = true;
        console.error('Error return methods not found');
        reject()
      }
    });
  }

  mapCalculatedReturnFee(calculatedReturnFees: FeeCalculateResponse[]) {
    if (NullEmptyChecker.isNonEmptyArray(this.returnMethods)) {
      for (const returnMethod of this.returnMethods!) {
        const calculatedReturnFee = calculatedReturnFees.find(fee => fee.componentId === returnMethod.componentId);
        returnMethod.labelBoxReturnFee = calculatedReturnFee?.fee || 0;
        returnMethod.returnFee = calculatedReturnFee?.fee || 0;
      }
    }
    this.calculateReturnFee()
  }

  private getReturnMethodGroup(
    itemList: Item[],
    masterMethod: ReturnMethodGroup
  ) {
    const item = itemList[0];
    const exchangeMetadata = itemList.find(item => item.exchangeMetadata?.length)?.exchangeMetadata ?? [];
    const returnMethodGroup = {
      rmaId: 0,
      returnFee: item?.returnFee || 0,
      labelBoxReturnFee: item?.returnFee || 0,
      returnLabelBox: 1,
      error: false,
      address: item?.address,
      itemList,
      ...(item?.locationExternalId && { locationExternalId: item.locationExternalId }),
      ...(exchangeMetadata.length && { exchangeMetadata }),
    };

    const componentId = generateRandomString(25);
    return {
      ...masterMethod,
      ...returnMethodGroup,
      componentId
    };
  }

  init() {
    this.urlEntities = this.getTenantAndBrandExternalId();
    this.store
      .select(getConfig)
      .pipe()
      .subscribe(config => {
        if (config) {
          this.hostedReturnsConfig = config;
        }
      });
    this.detailsSubscription = this.store
      .select(getSanitisedOrderId)
      .pipe()
      .subscribe(data => {
        this.sanitizedOrderIdDetails = data
          ? data
          : { orderId: '', sanitizedOrderId: '' };
      });
    const orderId = encodeURIComponent(
      btoa(`${this.urlEntities?.orderExternalId}`)
    );
    this.urlParamsLink = `/${this.urlEntities?.tenantId}/${this.urlEntities?.brandExternalId}/${orderId}`;
    this.getSelectedItems();
  }

  getSelectedItems() {
    this.selectedItemsSubscription = this.store
      .select(getAllSelectedItems)
      .pipe()
      .subscribe(selectedItems => {
        if (this.urlEntities?.orderExternalId)
          this.selectedItems =
            selectedItems[this.sanitizedOrderIdDetails.sanitizedOrderId];
        this.getMasterReturnMethods();
      });
  }

  getItemList() {
    this.itemListSubscription = this.store
      .select(getAuthResponse)
      .pipe()
      .subscribe(resp => {
        this.itemList = resp.itemList;
        this.invoice = resp.invoiceInfo;
        this.prepareReturnMethod();
      });
  }

  getMasterReturnMethods() {
    if (!NullEmptyChecker.isNonEmptyArray(this.selectedItems)) {
      return;
    }
    const codes: string[] = <string[]>[
      ...new Set(
        this.selectedItems
          .map(item => item?.returnMethod?.code)
          .filter(item => item)
      ),
    ];

    this.masterReturnMethodLoader = true;
    this.returnService.getMasterReturnMethods(codes).subscribe({
      next: methods => {
        this.masterReturnMethodLoader = false;
        methods = methods.filter((method: ReturnMethod) => method.active);
        if (NullEmptyChecker.isNonEmptyArray(methods)) {
          this.masterReturnMethodError = false;
          this.masterReturnMethods = methods;
          this.getItemList();
        } else {
          this.masterReturnMethodError = true;
        }
      },
      error: error => {
        console.error('Error while fetching master return methods=>', error);
        this.masterReturnMethodError = true;
        this.masterReturnMethodLoader = false;
      },
    });
  }

  async prepareReturnMethod() {
    const uniqueLocationIds = [
      ...new Set(
        this.selectedItems
          .map(item => item.locationExternalId)
          .filter(item => item)
      ),
    ];
    const returnMethods: ReturnMethodGroup[] = [];
    for (const masterMethod of this.masterReturnMethods) {
      const itemList = this.selectedItems
        .filter(item => item?.returnMethod?.code === masterMethod.code)
        .map(selectedItem => {
          const returnMethodItem = <Item>{ ...selectedItem };
          const item = this.itemList.find(
            item => item.__refId === selectedItem.__refId
          );
          returnMethodItem.itemQuantity = returnMethodItem.quantity;
          returnMethodItem.image = item?.image;
          if (!item?.locationExternalId) {
            delete item?.locationExternalId;
          }
          return returnMethodItem;
        });
      if (NullEmptyChecker.isNonEmptyArray(itemList)) {
        if (
          NullEmptyChecker.isNonEmptyArray(uniqueLocationIds) &&
          masterMethod.type !== MethodsCategory.KEEP_ITEM &&
          masterMethod.type !== MethodsCategory.RETURN_TO_STORE
        ) {
          // grouping logic based on locationExternalId
          for (const locationId of uniqueLocationIds) {
            const itemsBasedOnLocation = itemList.filter(
              item => item.locationExternalId === locationId
            );
            const group = this.getReturnMethodGroup(
              itemsBasedOnLocation,
              masterMethod
            );
            if (NullEmptyChecker.isNonEmptyArray(group.itemList))
              returnMethods.push(group);
          }
          // group items with no locationExternalId present together
          const itemsWithoutLocation = itemList.filter(
            item => !item.locationExternalId
          );
          if (NullEmptyChecker.isNonEmptyArray(itemsWithoutLocation)) {
            const group = this.getReturnMethodGroup(
              itemsWithoutLocation,
              masterMethod,
            );
            if (NullEmptyChecker.isNonEmptyArray(group.itemList))
              returnMethods.push(group);
          }
        } else {
          // grouping logic based on return method type
          const group = this.getReturnMethodGroup(itemList, masterMethod);
          if (NullEmptyChecker.isNonEmptyArray(group.itemList))
            returnMethods.push(group);
        }
      }
    }
    this.returnMethods = returnMethods.filter(method => method.itemList);
    await this.getCalculatedReturnFee();
  }

  navigateToItemList() {
    this.router.navigate([`/${this.urlParamsLink}/main/itemlist`]);
  }

  calculateReturnFee() {
    if (NullEmptyChecker.isNonEmptyArray(this.returnMethods)) {
      setTimeout(() => {
        const estimatedReturnFee = this.returnMethods!.reduce(
          (total, returnMethod) => total + returnMethod.labelBoxReturnFee!,
          0
        );
        this.estimatedReturnFee = estimatedReturnFee;
      });
    }
  }

  submitReturnRequest() {
    const orderExternalId = this.invoice.orderExternalId;
    const displayRefundMethods =
      this.hostedReturnsConfig.componentVisibility.displayRefundMethods;

    let exchangeMetadata: ExchangeMetadata[] = [];
    const list = this.returnMethods?.map(returnMethod => {
      exchangeMetadata = returnMethod.exchangeMetadata!;
      let locationExternalId: string | null = '';
      const itemList =
        returnMethod.itemList?.map(returnItem => {
          const itemFound: Item = this.itemList.find(
            item => returnItem.__refId === item.__refId
          )!;

          const item = { ...itemFound };
          delete item.returnRequest;
          item.locationExternalId = returnItem.locationExternalId;

          const returnReason = this.mapReturnReason(returnItem);

          const itemMap = {
            ...item,
            orderExternalId,
            quantity: returnItem?.quantity,
            returnReason,
            customerAddress: returnItem?.address,
            ...(returnItem.exchangedItem && { exchangedItem: { ...returnItem.exchangedItem, quantity: returnItem?.quantity } })
          };

          if (displayRefundMethods && NullEmptyChecker.isDeepNull(itemMap, 'exchangedItem')) {
            itemMap['refundMode'] = returnItem?.refundMode;
          }
          locationExternalId = item.locationExternalId ?? null;
          delete itemMap.locationExternalId;

          return itemMap;
        }) ?? [];

      const masterReturnMethod = this.masterReturnMethods.find(
        masterReturnMethod => masterReturnMethod.code === returnMethod.code
      );
      const pickupTime = returnMethod.pickupTime ?? null;
      const dropoffTime = returnMethod.dropoffTime ?? null;
      const pickupInstructions = returnMethod.pickupInstructions ?? null;
      const timeZone = returnMethod.timeZone ?? null;
      return {
        returnMethod: {
          code: masterReturnMethod?.code,
          name: returnMethod.name,
          type: returnMethod.type,
          displayName: masterReturnMethod?.displayName,
          fee: returnMethod.labelBoxReturnFee,
        },
        noOfBoxes: returnMethod.returnLabelBox,
        itemList,
        ...(locationExternalId && { locationExternalId }),
        ...(pickupTime && { pickupTime }),
        ...(dropoffTime && { dropoffTime }),
        ...(pickupInstructions && { pickupInstructions }),
        ...(timeZone && { timeZone }),
      };
    });

    const returnRequest = {
      orderBeingReturned: orderExternalId,
      orderIdBeingReturned: this.invoice.orderId,
      customer: this.invoice.customer,
      list,
      ...(exchangeMetadata && { exchangeMetadata }),
    };

    this.returnRequestLoader = true;
    this.returnService.initiateBulkReturnRequest(returnRequest).subscribe({
      next: (resps: { results: { rmaId: string }[] }) => {
        this.returnRequestLoader = false;
        const rId = resps.results
          ?.map((result: { rmaId: string }) => result.rmaId)
          .join(',');

        this.router.navigate([`/${this.urlParamsLink}/main/request-status`], {
          queryParams: { rId },
        });
      },
      error: error => {
        this.returnRequestLoader = false;
        if (CUSTOM_ERROR_CODES.includes(error?.message)) {
          this.router.navigate(
            [`/${this.urlParamsLink}/main/request-placed/error`],
            {
              queryParams: {
                code: error?.message,
              },
            }
          );
        } else {
          this.store.dispatch(
            showToastError({
              showToast: true,
              errorMsg: error?.message || 'Something went wrong!',
              close: true,
              timeoutMs: 3200,
            })
          );
        }
        console.error('Error in initiate return request=>', error);
      },
    });
  }
}
