import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReturnsConfigService } from './returns-config.service';

// function _window(): any {
// 	// return the global native browser window object
// 	return window;
// }

// @Injectable()
// export class WindowRef {
// 	get nativeWindow(): any {
// 		return _window();
// 	}
// }

// eslint-disable-next-line @typescript-eslint/no-namespace
declare const google: any;
// declare namespace google {
//   // eslint-disable-next-line @typescript-eslint/no-namespace
//   export namespace maps {
//     export type GeocoderResult = [];
//   }
// };

@Injectable()
export class GoogleMapsService {
  private googleMapUrl: string;
  loadGoogleMapsAPI: Promise<void>;
  isGoogleMapsLoaded = false;

  constructor(
    private windowRef: Window,
    private returnsConfigService: ReturnsConfigService
  ) {
    this.googleMapUrl = `https://maps.googleapis.com/maps/api/js?callback=_onGoogleMapLoaded&key=${this.returnsConfigService.hostedReturnsConfig.googleMapsApiKey}&libraries=drawing,geometry,places,visualization`;
    const window: any = windowRef;
    this.loadGoogleMapsAPI = new Promise(resolve => {
      window['_onGoogleMapLoaded'] = (ev: any) => {
        this.isGoogleMapsLoaded = true;
        resolve();
      };
      if (!this.isGoogleMapsLoaded) {
        this.loadGoogleMaps();
      } else {
        resolve();
      }
    });
  }

  loadGoogleMaps() {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = this.googleMapUrl;
    script.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(script);
    this.isGoogleMapsLoaded = true;
  }

  fetchAddressFromLatAndLong(lat: number, lng: number) {
    const geocoder = new google.maps.Geocoder();

    return new Observable(observer => {
      geocoder.geocode(
        {
          location: {
            lat,
            lng,
          },
        },
        (results: [], status: string) => {
          if (status === google.maps.GeocoderStatus.OK) {
            observer.next(results);
            observer.complete();
          } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
            observer.next(null);
            observer.complete();
          } else {
            observer.error('Geocoding error: ' + status);
          }
        }
      );
    });
  }
}
