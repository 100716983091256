<form autocomplete="off" [formGroup]="nearByLocationForm" (ngSubmit)="viewLocation()">
	<div class="box p-3 change-address-screen d-flex flex-column">
		<div class="top-line mb-4" *ngIf="showDescription">
			Enter an address from which you would like to view the nearby stores.
		</div>
		<div class="nearby-stores-options d-flex mb-3" *ngIf="address">
			<div class="col">
				<div class="title">Your Address</div>
				<div class="addr">
					{{address.street}},<br>
					{{address.street2 ? address.street2 + ',' : ''}}<br *ngIf="address.street2">
					{{address.city}}, {{address.state}}, {{address.zipcode}} {{address.country}}
				</div>
			</div>
			<div class="col-auto custom-radio-box d-flex pe-2">
				<div class="container">
					<div class="round">
						<input type="radio" id="deliveryAddress" name="addressOption" (change)="onToggleChange()"
							formControlName="addressOption" [value]="'delivery_addr'" />
						<label for="deliveryAddress"></label>
					</div>
				</div>
			</div>
		</div>
		<div class="nearby-stores-options d-flex mb-3">
			<div class="col d-flex justify-content-between flex-column">
				<div class="title" *ngIf="address">Alternate Address</div>
				<div class="current-location d-flex align-items-center"
					*ngIf="(form.get('addressOption')?.value === 'manual_address' || !address)">
					<div class="me-1">
						<i-feather name="map-pin" class="pin"></i-feather>
					</div>
					<div class="current-location-text  cursor-pointer text-decoration-underline"
						(click)="getUserCurrentLocation()" [captureEvent]="'click-use-current-location'">
						Use Current Location
					</div>
				</div>
			</div>
			<div class="col-auto custom-radio-box d-flex pe-2" *ngIf="address">
				<div class="container">
					<div class="round">
						<input type="radio" id="manualAddress" name="addressOption" (change)="onToggleChange()"
							formControlName="addressOption" [value]="'manual_address'" />
						<label for="manualAddress"></label>
					</div>
				</div>
			</div>
		</div>
		<div class="addr-box-form row position-relative" [formGroupName]="'addressForm'">
			<ng-container *ngIf="(form.get('addressOption')?.value === 'manual_address' || !address)">
				<div class="mb-3 col-md-12">
					<!-- <label for="street1Input" class="form-label">Street 1<sup
							class="text-danger mandatory-fields-sign">*</sup></label> -->
					<input type="text" class="form-control" id="street1Input" aria-describedby="street"
						placeholder="Street 1" formControlName="street" appGooglePlace
						(bySelect)="addressAutoCompleteEventHandler($event)">
				</div>
				<div class="mb-3 col-md-12">
					<!-- <label for="street2Input" class="form-label">Street 2</label> -->
					<input type="text" class="form-control" id="street2Input" aria-describedby="street2Input"
						placeholder="Street 2" formControlName="street2">
				</div>
				<div class="mb-3 col-md-12">
					<!-- <label for="aptsuitInput" class="form-label">Apt/Suite</label> -->
					<input type="text" class="form-control" id="aptsuitInput" aria-describedby="aptsuite"
						placeholder="Apt/Suite" formControlName="apartment">
				</div>
				<div class="mb-3 col-md-12">
					<!-- <label for="countryInput" class="form-label">Country<sup
							class="text-danger mandatory-fields-sign">*</sup></label> -->
					<ng-select #selectStoreCountry formControlName="country" id="country"
						labelForId="pickup-before-country" name="country" [searchable]="false" dropdownPosition="bottom"
						placeholder="Country" [clearable]="false" (change)="onCountryChange($event)">
						<ng-template ng-header-tmp>
							<ds-ngselect-searchbox [selectElement]='selectStoreCountry'></ds-ngselect-searchbox>
						</ng-template>
						<ng-option value="">Country</ng-option>
						<ng-option *ngFor="let country of countries" [value]="country.code2">
							{{country.name}}
						</ng-option>
					</ng-select>
				</div>
				<div class="mb-3 col-md-12">
					<!-- <label for="stateInput" class="form-label">State<sup
							class="text-danger mandatory-fields-sign">*</sup></label> -->
					<ng-select formControlName="state" dropdownPosition="bottom" [searchable]='false' #selectState
						placeholder="State" [clearable]="false" id="state" name="state">
						<ng-template ng-header-tmp>
							<ds-ngselect-searchbox [selectElement]='selectState'></ds-ngselect-searchbox>
						</ng-template>
						<ng-option value="">State</ng-option>
						<ng-option *ngFor="let state of STATE_LIST" [value]="state.code">{{state.name}}
							({{state.code}})

						</ng-option>
					</ng-select>
				</div>
				<div class="mb-3 col-md-6">
					<!-- <label for="cityInput" class="form-label">City<sup
							class="text-danger mandatory-fields-sign">*</sup></label> -->
					<input type="text" class="form-control" id="cityInput" aria-describedby="city" placeholder="City"
						formControlName="city">
				</div>
				<div class="mb-3 col-md-6">
					<!-- <label for="zipCodeInput" class="form-label">Zipcode<sup
							class="text-danger mandatory-fields-sign">*</sup></label> -->
					<input type="text" class="form-control" id="zipCodeInput" aria-describedby="zipcode"
						placeholder="Zipcode" formControlName="zipcode">
				</div>
			</ng-container>
		</div>
		<div class="d-flex justify-content-center flex-grow-1">
			<button type="submit" class="btn btn-ds-primary align-self-end w-100" [disabled]="disableViewStoreButton"
				[captureEvent]="changeAddressForm ? 'click-save-address' : 'click-view-stores'">
				<app-bubble-loader *ngIf="showLoader"></app-bubble-loader>
				<ng-container *ngIf="!showLoader">
					<span *ngIf="(fetchingCurrentLocation && form.get('addressOption')?.value === 'manual_address')"
						class="spinner-border spinner-border-sm text-dark" role="status" aria-hidden="true">
					</span>
					{{(fetchingCurrentLocation && form.get('addressOption')?.value === 'manual_address') ? 'Fetching
					Locations' : changeAddressForm ? 'Save' : 'View Stores'}}
				</ng-container>
			</button>
		</div>
	</div>

</form>