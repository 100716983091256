import { createAction, props } from '@ngrx/store';

export const TOAST_ERROR_ACTION = '[error toast] show error toast';

export const showToastError = createAction(
  TOAST_ERROR_ACTION,
  props<{
    showToast: boolean;
    errorMsg: string;
    close?: boolean;
    data?: Record<string, any>;
    timeoutMs?: number;
  }>()
);
