export const environment = {
  production: false,
  sandbox: false,
  appUrl: 'https://qa.returns.deliverysolutions.co/',
  serverUrl: 'https://qa.customer-api.deliverysolutions.co/api/v2/',
  streamServiceUrl: 'https://qa.streaming.deliverysolutions.co/stream/v2',
  googleMapsAPIKey: '',
  googleTagmanagerID: 'GTM-MGTNCVZ',
  awsConfig: {
    fileStorage: {
      bucket: 'https://qa.files.deliverysolutions.co',
    },
  },
  serviceWorkerEnabled: true,
  checkUpdateInterval: 600000,
  env: 'qa',
  googleMapsApiKeyDecryptionPassphrase: 'vMrpNRaRAL2RuCF',
  userPilotAuthKey: "",
};
