import { createReducer, on } from '@ngrx/store';
import { setOrderExternalId } from '@store/actions/order-id.action';
import {
  OrderExternalIdState,
  initialOrderExternalIdState,
} from '@store/states/order-id.state';

const _orderExternalIdReducer = createReducer(
  initialOrderExternalIdState,
  on(setOrderExternalId, (state, action): OrderExternalIdState => {
    return {
      ...state,
      orderExternalId: action.orderId,
    };
  })
);

export function orderExternalIdReducer(
  state: OrderExternalIdState,
  action: any
) {
  return _orderExternalIdReducer(state, action);
}
