<div class="item-list-box list-box mb-0" *ngIf="hostedReturnsConfig.components.broadcast.active && showBCMessage">
	<div class="broadcast-message-container border-radius-5px p-3">
		<div class="d-flex justify-content-between align-items-center">
			<h2 class="f-18px m-0 title">{{ broadcast.header }}</h2>
			<button class="btn btn-sm py-0 text-white" type="button" (click)="closeBCMessage()"
				[captureEvent]="'click-close-broacast-message'">&#10005;</button>
		</div>

		<!-- This is for mobile screens-->
		<div class="pt-2 message box-content f-14 d-block d-md-none" *ngIf="broadcast.body">
			{{ show ? broadcast.body : (broadcast.body | slice: 0:70)
			}}<span *ngIf="broadcast.body.length > 70 && !show">...</span>
			<a class="ms-3 noWrap" *ngIf="broadcast.body.length > 70" (click)="show = !show">{{
				show ? "Read Less" : "Read More"
				}}</a>
		</div>

		<!-- This is for web-->
		<div class="pt-2 message box-content f-14 d-none d-md-block" *ngIf="broadcast.body">
			{{ show ? broadcast.body : (broadcast.body | slice: 0:210)
			}}<span *ngIf="broadcast.body.length > 210 && !show">...</span>
			<a class="ms-3 noWrap" *ngIf="broadcast.body.length > 210" (click)="show = !show">{{
				show ? "Read Less" : "Read More"
				}}</a>
		</div>
	</div>
</div>