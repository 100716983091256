import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  NgZone,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AddressAutoCompletion } from '@core/utils/addressAutocompletion';
import countryData from '@core/models/jsons/countries.json';
import stateData from '@core/models/jsons/USA_States.json';
import { GoogleMapsService } from '@services/google-maps.service';
import { AddressTypes } from '@constants/addressTypes-constants';
@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit, OnDestroy {
  nearByLocationForm!: UntypedFormGroup;
  @Input() address!: any;
  showForm = false;
  usa_state: any;
  countries: any;
  STATE_LIST: any;
  selectedStateCode = '';
  countrySubscription!: Subscription | undefined;
  @Output() getAddress = new EventEmitter();
  addressFromCurrentLocation!: any;
  disableViewStoreButton = true;
  @Output() byClose = new EventEmitter();
  formSubscription!: Subscription;
  @Input() showHeader = false;
  @Input() changeAddressForm = false;
  // Made a new change in the design, so this variable had to be introduced
  fetchingCurrentLocation = false;
  showLoader = false;

  /**
   * This input parameter is used, to show text on the top of the sidenav
   * condition to show the line:-
   * When customer address is not present and clicked on "view nearby stores" option
   */
  @Input() showDescription = false;

  /**
   * This input parameter is used to see which type of address entity we are showing = 'customeraddress' / 'nearbylocation'.
   * below are some conditions we are imposing based on this `addressType`.
   * - for customer address, latitude and longitude is not mandatory as customer can enter manual address.
   * - for customer address, If customer enters manual address, we are deleting latitude and longitude from backend payload object to avoid validation errors.
   */
  @Input() addressType = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private zone: NgZone,
    private googleMapsService: GoogleMapsService
  ) { }

  ngOnInit(): void {
    this.STATE_LIST = [];
    this.init();
  }

  init() {
    this.usa_state = stateData;
    this.countries = countryData;
    this.nearByLocationForm = this.formBuilder.group({
      addressOption: ['', [Validators.required]],
      addressForm: this.formBuilder.group({
        street: [''],
        street2: [''],
        apartment: [''],
        country: [''],
        state: [''],
        city: [''],
        zipcode: [''],
        latitude: [''],
        longitude: [''],
      }),
    });
    this.formSubscription = this.nearByLocationForm.valueChanges.subscribe(
      formValues => {
        this.disableViewStoreButton = this.nearByLocationForm.invalid;
      }
    );
    if (!this.address)
      this.form.get('addressOption')?.setValue('manual_address');
    if (this.address) this.form.get('addressOption')?.setValue('delivery_addr');
    this.onToggleChange();
    this.setStateList('US', '');
  }

  get form() {
    return this.nearByLocationForm;
  }

  showError(error: any) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.error('User denied the request for Geolocation.');
        break;
      case error.POSITION_UNAVAILABLE:
        console.error('Location information is unavailable.');
        break;
      case error.TIMEOUT:
        console.error('The request to get user location timed out.');
        break;
      case error.UNKNOWN_ERROR:
        console.error('An unknown error occurred.');
        break;
    }
  }

  getAddressByLatAndLong(lat: number, lng: number) {
    this.googleMapsService.fetchAddressFromLatAndLong(lat, lng).subscribe(
      results => {
        if (!results) {
          return;
        }

        const placesInfo = AddressAutoCompletion.getPlaceInfo(
          (results as any)[0]
        );
        const placeValues = AddressAutoCompletion.prepareAddressJson(
          (results as any)[0],
          placesInfo
        );
        const formattedAddress = {
          street: placeValues.streetNumber
            ? placeValues.streetNumber + ' '
            : '' + placeValues.street,
          street2: '',
          apartment: '',
          country: placeValues.countryName,
          state: placeValues.stateName,
          city: placeValues.cityName,
          zipcode: placeValues.postalCode,
          latitude: placeValues.latitude,
          longitude: placeValues.longitude,
        };
        this.addressFromCurrentLocation = formattedAddress;
        const {
          street,
          street2,
          apartment,
          country,
          state,
          city,
          zipcode,
          latitude,
          longitude,
        } = formattedAddress;
        const addressForm = this.form.get('addressForm');
        addressForm?.patchValue({
          street,
          street2,
          apartment,
          country,
          state,
          city,
          zipcode,
          latitude,
          longitude,
        });
        this.disableViewStoreButton = false;
        this.fetchingCurrentLocation = false;
      },
      error => {
        console.error(error);
      }
    );
  }

  /**
   * Problem was validators are not getting cleared on whole formgroup
   * Hence, removed old code and added else block.
   * it is recommended to clear validators of controls instead of whole formGroup;
   * https://stackoverflow.com/questions/67882825/clearvalidators-not-working-as-expected-along-with-form-reset-method-in-angular
   */
  onToggleChange() {
    const addressForm = this.form.get('addressForm');
    if (this.form.get('addressOption')?.value === 'manual_address') {
      addressForm
        ?.get('street')
        ?.setValidators([Validators.required, this.noSpaceAllowed]);
      addressForm?.get('street')?.updateValueAndValidity();
      addressForm
        ?.get('country')
        ?.setValidators([Validators.required, this.noSpaceAllowed]);
      addressForm?.get('country')?.updateValueAndValidity();
      addressForm
        ?.get('state')
        ?.setValidators([Validators.required, this.noSpaceAllowed]);
      addressForm?.get('state')?.updateValueAndValidity();
      addressForm
        ?.get('city')
        ?.setValidators([Validators.required, this.noSpaceAllowed]);
      addressForm?.get('city')?.updateValueAndValidity();
      addressForm
        ?.get('zipcode')
        ?.setValidators([Validators.required, this.noSpaceAllowed]);
      addressForm?.get('zipcode')?.updateValueAndValidity();
    } else {
      addressForm?.get('street')?.clearValidators();
      addressForm?.get('street')?.updateValueAndValidity();
      addressForm?.get('country')?.clearValidators();
      addressForm?.get('country')?.updateValueAndValidity();
      addressForm?.get('state')?.clearValidators();
      addressForm?.get('state')?.updateValueAndValidity();
      addressForm?.get('city')?.clearValidators();
      addressForm?.get('city')?.updateValueAndValidity();
      addressForm?.get('zipcode')?.clearValidators();
      addressForm?.get('zipcode')?.updateValueAndValidity();
    }
  }

  viewLocation() {
    this.showLoader = true;
    const option = this.nearByLocationForm.get('addressOption')?.value;
    let addressOb = null;
    if (option === 'delivery_addr') {
      addressOb = this.address;
    } else if (option === 'current_location') {
      addressOb = this.addressFromCurrentLocation;
    } else if (option === 'manual_address') {
      addressOb = this.form.value.addressForm;
    }

    /**
     * In order to avoid validation error from backend,
     * delete longitude and latitude if we are sending it as empty string for addressType === `customeraddress`
     */
    if (this.addressType === AddressTypes.CUSTOMER_ADDRESS) {
      addressOb?.latitude === '' && delete addressOb.latitude;
      addressOb?.longitude === '' && delete addressOb.longitude;
    }

    this.getAddress.emit({ address: addressOb });
  }

  addressAutoCompleteEventHandler(e: any) {
    this.zone.run(() => {
      const placesInfo = AddressAutoCompletion.getPlaceInfo(e);
      const placeValues = AddressAutoCompletion.prepareAddressJson(
        e,
        placesInfo
      );
      this.nearByLocationForm
        .get('addressForm.street')
        ?.setValue(
          (placeValues.streetNumber ? placeValues.streetNumber + ' ' : '') +
          placeValues.street
        );
      this.nearByLocationForm.get('addressForm.street2')?.setValue('');
      this.nearByLocationForm.get('addressForm.apartment')?.setValue('');
      this.nearByLocationForm
        .get('addressForm.country')
        ?.setValue(this.getCountryCode(placeValues.countryName));
      this.selectedStateCode = this.getState(placeValues.stateName);
      this.nearByLocationForm
        .get('addressForm.state')
        ?.setValue(this.selectedStateCode);
      this.nearByLocationForm
        .get('addressForm.city')
        ?.setValue(placeValues.cityName);
      this.nearByLocationForm
        .get('addressForm.zipcode')
        ?.setValue(placeValues.postalCode);
      this.nearByLocationForm
        .get('addressForm.latitude')
        ?.setValue(placeValues.latitude);
      this.nearByLocationForm
        .get('addressForm.longitude')
        ?.setValue(placeValues.longitude);
    });
  }

  /**
   * @method :-  this method will find out the state from array of usa state.
   * @param stateName :- passing stateName
   */
  getCountryCode(countryName: string) {
    let country_code = '';
    this.countries.forEach((item: any) => {
      if (countryName.toLowerCase() === item.name.toLowerCase()) {
        country_code = item.code2;
      }
    });
    return country_code;
  }

  /**
   * @method :-  this method will find out the state from array of usa state.
   * @param stateName :- passing stateName
   */
  getState(stateName: string) {
    let state_code = '';
    this.STATE_LIST.forEach((item: any) => {
      if (stateName === item.name) {
        state_code = item.code;
      }
    });
    return state_code;
  }

  setStateList(countryCode: string, selectedStateCode?: string) {
    let list = [];
    for (let i = 0; i < this.countries.length; i++) {
      if (countryCode.toLowerCase() === this.countries[i].code2.toLowerCase()) {
        list = this.countries[i].states;
        break;
      }
    }
    this.STATE_LIST = list;
    if (!this.STATE_LIST.length) {
      this.selectedStateCode = '';
    }
    const stateCode = selectedStateCode
      ? selectedStateCode
      : this.selectedStateCode;
    this.nearByLocationForm.get('address.state')?.setValue(stateCode);
  }

  onCountryChange(e: any) {
    const selectedCountry = e;
    this.setStateList(selectedCountry);
  }

  closeBox() {
    const ob: any = {};
    this.byClose.emit(ob);
  }

  ngOnDestroy(): void {
    this.showLoader = false;
    if (this.countrySubscription) this.countrySubscription.unsubscribe();
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }

  getUserCurrentLocation() {
    if (navigator.geolocation) {
      this.disableViewStoreButton = true;
      this.fetchingCurrentLocation = true;
      navigator.geolocation.getCurrentPosition(
        position => {
          this.getAddressByLatAndLong(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        error => {
          this.disableViewStoreButton = false;
          this.fetchingCurrentLocation = false;
          this.showError(error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  /**
   * validator function to make sure there are no space at the start of the user input using regex.
   * @param control
   * @returns ValidationErrors if there are space at the start of the user input
   */
  noSpaceAllowed(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;
    if (!value) {
      return null;
    }
    if (/^\s/.test(value)) {
      return { noSpaceAllowed: true };
    }
    return null;
  }
}
