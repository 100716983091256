<div class="mb-2 mb-md-3 product-gallery" *ngIf="recommendations && recommendations.length>0">
	<div class="col-11 col-sm-11 mx-auto col-md-12 swiper-container">
		<div class="title pb-2">Recommended Products</div>
		<swiper-container appSwiper #swiper [config]="swiperConfig" init="false">
			<swiper-slide *ngFor="let product of recommendations">
				<div class="swiper-wrapper">
					<a [href]="product.productUrl" [captureEvent]="'click-recommended-product-url'" target="_blank"
						rel="noopener noreferrer" class="product-link text-decoration-none swiper-slide d-block">
						<div class="product-wrapper position-relative">
							<div class="img-container">
								<img [src]="product.imageUrl" alt="Recommended product image" />
							</div>
						</div>
						<div>
							<span class="product-name">{{product.productName}}</span>
						</div>
					</a>
				</div>
			</swiper-slide>
		</swiper-container>
	</div>
</div>