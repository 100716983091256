import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multi-sku-footer',
  templateUrl: './multi-sku-footer.component.html',
  styleUrls: ['./multi-sku-footer.component.scss'],
})
export class MultiSkuFooterComponent {
  @Input()
  primaryButton = true;

  @Input()
  primaryButtonText = 'Confirm';

  @Input()
  primaryButtonIcon!: string;

  @Input()
  mobileView = false;

  @Input()
  disabled = false;

  @Input()
  showLoader = false;

  @Input()
  secondaryButton = false;

  @Input()
  secondaryButtonText = 'Cancel';

  @Input()
  secondaryButtonLink!: string;

  @Input()
  info!: string;

  @Input()
  subInfo!: string;

  @Input()
  subInfoIcon!: string;

  @Input() returnFeeLoader = false;

  @Input() estimatedReturnFee!: string;

  @Output()
  primaryButtonClick = new EventEmitter();

  onPrimaryButtonClick() {
    this.primaryButtonClick.emit();
  }
}
