<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="phone-number-update-container">
  <!-- Modal Header -->
  <div class="header">
    <div class="header-content">Update Phone Number</div>
    <div (click)=closeUpdatePhoneModal()>
      <i-feather class="close-button" name="X"></i-feather>
    </div>
  </div>

  <!-- Modal Body -->
  <div class="body">
    <p class="content-text"> Enter a valid phone number to continue.</p>
    <label for="phoneNumber" class="phone-label">Phone Number</label>
    <form [formGroup]="phoneForm">
      <div class="update-phone-number col-md-11 col-12">
        <ds-phone-number-input formControlNameCustom="phoneNumber" custom="contact" inputId="phoneNumber"
          [customCss]="isPhoneNumberInvalid || isPhoneNumberEmpty ? 'invalid-phone' : ''"
          [placeHolder]="'2015551234'">
        </ds-phone-number-input>
        <div *ngIf="isPhoneNumberInvalid || isPhoneNumberEmpty">
          <i-feather class="alert-icon" name="alert-circle"></i-feather>
          <small *ngIf="isPhoneNumberInvalid" class="text-danger">
            Invalid Phone Number</small>
          <small *ngIf="isPhoneNumberEmpty" class="text-danger">
            Phone Number is Mandatory</small>
        </div>
      </div>
    </form>
  </div>

  <!-- Modal Footer -->
  <div class="footer">
    <button class="save-button" [disabled]="isPhoneNumberInvalid || isPhoneNumberEmpty" (click)="updatePhoneNumber()">
      <ng-container *ngIf="!showLoader">
        Update
      </ng-container>
      <app-bubble-loader *ngIf="showLoader"></app-bubble-loader>
    </button>
  </div>
</div>