import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { StorageService } from '@core/services/storage.service';
import { AppComponent } from '@app/app.component';

export const authGuard: CanActivateFn = (route, state) => {
  const storageService = inject(StorageService);
  const router = inject(Router);

  let orderExternalId;
  if (route.component instanceof AppComponent) {
    orderExternalId = route.params?.['orderId'];
  } else {
    orderExternalId = route.parent?.paramMap.get('orderId');
  }
  storageService.setNewKey(atob(decodeURIComponent(orderExternalId)));
  const isLoggedIn = storageService.get('token');

  if (isLoggedIn) {
    return true;
  }

  // Redirect to the login page
  const [, tenantId, brandExternalId] = state.url.split('/');
  return router.navigate([`/${tenantId}/${brandExternalId}/login`]);
};
