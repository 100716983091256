import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, map, throwError } from 'rxjs';
import { loginSuccess } from '@store/actions/auth.action';
import { AuthCreds } from '@features/auth/models/auth-creds';
import { BaseService } from '@services/base.service';
import { StorageService } from '@services/storage.service';
import { STATUS_CODE } from '@constants/generic-constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(
    private storageService: StorageService,
    private httpClient: HttpClient,
    private store: Store
  ) {
    super();
  }

  login(credentials: AuthCreds, queryString: any = {}) {
    return this.httpClient
      .post(`${this.serverRootPathV2}returns/lookup`, credentials, {
        params: queryString,
      })
      .pipe(
        map((val: any) => {
          this.store.dispatch(loginSuccess({ resp: val }));
          return val;
        }),
        catchError(e => {
          let errorMsg: string;
          console.log('error in authentication', e);
          if (
            e.status === STATUS_CODE.internalServerError ||
            e.status === STATUS_CODE.unauthorized
          ) {
            errorMsg = 'Something went wrong. Please try again later.';
          } else if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => {
            return {
              message: errorMsg,
              code: e.status,
            };
          });
        })
      );
  }
}
