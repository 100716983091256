import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[appAutowidth]',
})
export class AutoWidthDirective implements OnInit {
  @Input() maxSize?: number = 20;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    setTimeout(() => {
      this.resize();
    }, 500);
  }

  @HostListener('keyup') onKeyUp() {
    this.resize();
  }

  @HostListener('focus') onFocus() {
    this.resize();
  }

  @HostListener('blur') onBlur() {
    this.resize();
  }

  ngOnInit() {
    this.resize();
  }

  resize() {
    const size = this.el.nativeElement.value.length + 1 || 1;
    if (size < this.maxSize!)
      this.el.nativeElement.setAttribute('style', `width:${size}ch`);
  }
}
