import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';
import { returnsConfingResolver } from '@app/resolvers/returns-config.resolver';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    resolve: {
      configResp: returnsConfingResolver,
    },
    data: {
      page: 'login',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
