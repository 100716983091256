<div class="error-toast-container" [ngClass]="{
        'col-4': screenSize > 769, 
        'col-12': screenSize < 768,
        'col-5':  !bannerImage && screenSize > 769,
        'position-center':  !bannerImage && screenSize > 769}" *ngIf="errorMessage">
    <div class="error-toast">
        <div class="w-100 h-100 d-flex align-items-center justify-content-between">
            <span class="error-text">
                <sup>
                    <i-feather class="alert-triangle" name="alert-triangle"></i-feather>
                </sup>
                <span [innerHTML]="errorMessage"></span>
            </span>
            <i-feather name="x" class="close-icon" (click)="closeError()"></i-feather>
        </div>
    </div>
</div>
<div class="container-fluid" id="login-component" [ngStyle]="{'min-height':'100dvh'}">
    <div class="row position-relative" style="min-height: inherit;">
        <div class="back-navigation" *ngIf="showOtpScreen">
            <i-feather name="arrow-left" class="back-icon" (click)="goToLogin()"></i-feather>
        </div>
        <a [href]="hostedReturnConfig?.components?.banner?.pageTitle" target="_blank"
            class="left-box col-12 col-md-8 p-0 d-none d-md-block"
            *ngIf="(screenSize > 769 && bannerImage) || (!showOtpScreen && bannerImage)">
            <div class="login-page-img" [ngStyle]="{'background-image': 'url(' + bannerImage + ')'}"></div>
        </a>
        <div class="right-box p-0 position-relative"
            [ngClass]="{'col-12': !bannerImage, 'col-md-4': bannerImage, 'fill-box': showOtpScreen}">
            <div class="login-container mx-auto"
                [ngClass]="{'col-md-7':!bannerImage,'col-lg-5': !bannerImage,'col-xl-4': !bannerImage,'max-500px':!bannerImage}"
                [ngStyle]="{'min-height': (bannerImage && screenSize<768) ? 'auto' : 'calc(100vh - 85px)'}">
                <div class="logo text-center" *ngIf="!showOtpScreen">
                    <img [src]="headerLogo?.url" height="70px" alt="logo" />
                </div>
                <div class="return-title text-center" *ngIf="!showOtpScreen">Returns</div>
                <div class="login-box" *ngIf="!showOtpScreen">
                    <div class="option-selected-text text-center">Enter <span>Order ID</span> and
                        <span>{{showMobileField ? 'Mobile Number' : 'Email Address'}}</span>
                    </div>
                    <form autocomplete="off" [formGroup]="loginForm" method="post" (ngSubmit)="login()" novalidate>
                        <div class="mb-4">
                            <input type="text" class="form-control text-input" id="orderId" aria-describedby="orderId"
                                formControlName="orderId"
                                [class.is-invalid]="f.get('orderId')?.dirty && f.get('orderId')?.invalid"
                                placeholder="Order ID">
                            <div *ngIf="f.get('orderId')?.errors">
                                <div class="invalid-feedback"
                                    [class.d-block]="f.get('orderId')?.dirty && f.get('orderId')?.errors?.['required']">
                                    Order ID is mandatory.
                                </div>
                            </div>
                        </div>
                        <div class="mb-4" *ngIf="!showMobileField">
                            <input type="email" class="form-control text-input" id="emailId" aria-describedby="emailId"
                                formControlName="emailId"
                                [class.is-invalid]="f.get('emailId')?.dirty && f.get('emailId')?.invalid"
                                placeholder="Email Address">
                            <div *ngIf="f.get('emailId')?.errors">
                                <div class="invalid-feedback"
                                    [class.d-block]="f.get('emailId')?.dirty && f.get('emailId')?.errors?.['required']">
                                    Email Address is mandatory.
                                </div>
                                <div class="invalid-feedback"
                                    [class.d-block]="f.get('emailId')?.dirty && f.get('emailId')?.errors?.['pattern']">
                                    Enter valid Email Address.
                                </div>
                            </div>
                        </div>
                        <div class="mb-4" *ngIf="showMobileField">
                            <ds-phone-number-input formControlNameCustom="mobileNumber" nameCustom="mobileNumber"
                                placeHolder="Mobile Number"
                                [customCss]="f.get('mobileNumber')?.touched && f.get('mobileNumber')?.dirty && f.get('mobileNumber')?.invalid ? 'is-invalid' : ''">
                            </ds-phone-number-input>
                            <small
                                *ngIf="f.get('mobileNumber')?.touched && f.get('mobileNumber')?.dirty && loginForm.controls?.['mobileNumber']?.errors?.['required']"
                                class="text-danger">Mobile Number is mandatory.</small>
                            <small
                                *ngIf="f.get('mobileNumber')?.touched && f.get('mobileNumber')?.dirty && loginForm.controls?.['mobileNumber']?.errors?.['validatePhoneNumber']"
                                class="text-danger">
                                Invalid Mobile Number.</small>
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn btn-ds-primary w-100 mt-1 my-md-3 login-btn"
                                [disabled]="loginProcessing || loginForm.invalid"
                                [captureEvent]="'click-login'">{{!loginProcessing ? 'Next' : ''}}
                                <span *ngIf="loginProcessing" class="spinner-border spinner-border-sm text-dark"
                                    role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="verify-otp-box" *ngIf="showOtpScreen">
                    <h3 class="text-center verification-header verification-title">2-Step Verification</h3>
                    <p *ngIf="loginMethod==='mobile'" class="verification-desc">An SMS with a Verification Code was just
                        sent to
                        <strong>{{f.get('mobileNumber')?.value.e164Number}}</strong>
                    </p>
                    <p *ngIf="loginMethod==='email'" class="verification-desc">An email with a Verification Code was
                        just sent to
                        <strong>{{f.get('emailId')?.value}}</strong>
                    </p>
                    <div class="text-center otp-container">
                        <ng-otp-input (onInputChange)="onOtpChange($event)"
                            [config]="{length:4, allowNumbersOnly: true, placeholder: ' ', inputClass: 'otp-inputbox ' + (otpFailed && 'otp-failed')}">
                        </ng-otp-input>
                    </div>
                    <p class="verification-desc mb-0 pt-4 verification-desc-info">Did not receive any code?</p>
                    <p class="verification-desc pt-2 verification-desc-info" *ngIf="resendSeconds > 0">Resend code in
                        <strong>{{resendSeconds}}s</strong>
                    </p>
                    <p class="verification-desc pt-2 resend-action" (click)="handleResendOtpClick()"
                        (keyup)="handleResendOtpClick()" [ngClass]="{'disabled': sendOtpProgressing}"
                        *ngIf="resendSeconds <= 0">RESEND
                    </p>
                    <div class="col-12 otp-submit">
                        <button type="submit" class="btn btn-ds-primary w-100 mt-1 my-md-4" (click)="verifyOtp()"
                            [disabled]="sendOtpProgressing || loginProcessing || otp?.length !== 4">{{!loginProcessing ?
                            'CONFIRM' :
                            ''}}
                            <span *ngIf="loginProcessing" class="spinner-border spinner-border-sm text-dark"
                                role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
            <a [href]="hostedReturnConfig?.components?.banner?.pageTitle" target="_blank"
                class="left-box col-12 col-md-8 p-0 d-block d-md-none"
                *ngIf="(screenSize > 769 && bannerImage) || (!showOtpScreen && bannerImage)">
                <div class="login-page-img" [ngStyle]="{'background-image': 'url(' + bannerImage + ')'}"></div>
            </a>
            <div class="footer-cls w-100 max-764px mx-auto" style="height: 85px;">
                <app-policy-footer [view]="'login'"></app-policy-footer>
            </div>
        </div>
    </div>
    <!-- ds cookie policy consent -->
    <ng-container *ngIf="brandExternalId">
        <ds-cookie-consent [brandExternalId]="brandExternalId"></ds-cookie-consent>
    </ng-container>
</div>