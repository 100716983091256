/**
 * generate contrast color given hex color value.
 * used to usually generate a constrasting color for given background so that fonts are clearly visible.
 * returns black or white hex color value;
 */

import {
  DARK_CONSTRAST_COLOR,
  LIGHT_CONSTRAST_COLOR,
} from '../constants/generic-constants';

interface RGB {
  b: number;
  g: number;
  r: number;
}

function rgbToYIQ({ r, g, b }: RGB): number {
  return (r * 299 + g * 587 + b * 114) / 1000;
}

function hexToRgb(hex: string): RGB | undefined {
  if (!hex) {
    return undefined;
  }

  const result: RegExpExecArray | null =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : undefined;
}

export function contrastColorOf(
  colorHex: string,
  threshold: number = 128
): string {
  let black = DARK_CONSTRAST_COLOR;
  let white = LIGHT_CONSTRAST_COLOR;

  if (colorHex === undefined) {
    return black;
  }

  const rgb: RGB | undefined = hexToRgb(colorHex);

  if (rgb === undefined) {
    return black;
  }

  return rgbToYIQ(rgb) >= threshold ? black : white;
}
