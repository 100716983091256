import { createReducer, on } from "@ngrx/store";
import { addConfig } from "../actions/config.action";
import { initialConfigState } from "../states/config.state";

const _configReducer = createReducer(
	initialConfigState,
	on(addConfig, (state, action) => {
		return {
			...state,
			config: { ...state.config, ...action.config }
		}
	}),
)

export function configReducer(state: any, action: any) {
	return _configReducer(state, action);
}