import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Item } from '@core/models/item';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getAllSelectedItems } from '@store/selectors/selected-item-list.selector';
import { sanitizeOrderId } from '@core/utils/sanitizeOrderId';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Swiper, SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnInit {
  itemOb!: Item;
  screenWidth!: any;
  quantity = '';
  @Input() showActionables = true; // this is to hide buttons or links

  // Since ItemComponent is common component to show item details across the pages
  // if you want to hide some fields purposely depending upon the UI requirement
  // use this input field
  @Input() hideKeys: any = {};
  @Input() itemHiddenFields: any = {
    previewImgModal: false
  };

  @Input() itemReturnedView = false;
  @Input() smallItemView = false;
  @Input() showLoader = false;
  @Input() showModal?: boolean = false;
  @Input() centeredTitle?: boolean = false;
  @Input() smallImage?: boolean = false;
  @Input() summaryView?: boolean = false;
  @Input() returnFee?: number;
  @Input() multiSkuEnabled?: boolean = false;
  @Input() isSelected?: boolean = false;
  @Input() isPreviousSelected?: boolean = false;

  @Input() set item(value: any) {
    this.itemOb = value;
    this.quantity = value.remainingQuantity;
  }

  @Input() bgWhite = false;
  @Input() urlEntity!: any;
  @Input() lastItem!: boolean;
  urlParamsLink = '';
  returnsUrl = '';
  returnDetailsUrl = '';
  selectedItemSubscription!: Subscription;
  selectedItemQuantity!: number;
  orderExternalId!: string;

  @Output()
  cancelButtonClick = new EventEmitter();

  @Output()
  returnButtonClick = new EventEmitter();

  @ViewChild('infoModalMobile', { read: ElementRef })
  public infoModalMobile!: ElementRef;
  @ViewChild(ModalComponent) public modalComponent!: ModalComponent;
  swiperDirective!: Swiper;
  swiperConfig: SwiperOptions = {
    enabled: true,
    pagination: {
      el: '.swiper-pagination',
      enabled: true,
      type: 'custom',
      renderCustom(swiper, current, total) {
        return current + ' of ' + total;
      },
    },
    autoHeight: true,
    allowTouchMove: true,
    slidesPerGroup: 1,
    slidesPerView: 1,
  };
  swiperPageIndex = 1;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private modalService: NgbModal,
    private renderer: Renderer2,
    private store: Store,
  ) { }

  setSwiperDirective() {
    if (!this.swiperDirective || this.swiperDirective.destroyed) {
      const swiperEl: any = document.getElementById('previewModalSwiper');
      if (!swiperEl) return;
      this.swiperDirective = swiperEl.swiper;
      this.swiperDirective.slideTo(this.swiperPageIndex - 1);
    }

    return this.swiperDirective;
  }

  closeModalComponent() {
    this.modalService.dismissAll()
  }

  openModalComponent(modal: TemplateRef<any>, index: number) {
    this.swiperPageIndex = index + 1;
    this.modalService.open(modal, {
      centered: true,
      keyboard: false
    }).shown.subscribe(() => {
      this.setSwiperDirective();
    })
  }

  paginatePreviewModal(direction: 'NEXT' | 'PREV') {
    if (!this.swiperDirective) return;

    switch (direction) {
      case 'NEXT':
        this.swiperDirective.slideNext();
        break;
      case 'PREV':
        this.swiperDirective.slidePrev();
        break;
    }
    this.swiperPageIndex = this.swiperDirective.activeIndex + 1;
  }

  openModal(modal: TemplateRef<any>, event?: Event) {
    if (!this.showModal) return;
    event?.stopPropagation();
    const isMobile = this.screenWidth < 768;
    if (!isMobile) {
      this.modalService.open(modal, {
        centered: true,
        keyboard: false,
      });
    } else {
      this.openMobileModal();
    }
  }

  openMobileModal() {
    const mobileModal = this.infoModalMobile.nativeElement;
    this.renderer.setStyle(mobileModal, 'display', 'block');
    const modal = mobileModal.getElementsByClassName('modal-mobile')[0];
    this.renderer.removeClass(modal, 'inactive');
    this.renderer.addClass(modal, 'active');
  }

  closeMobileModal(event?: Event) {
    event?.stopPropagation();
    const mobileModal = this.infoModalMobile.nativeElement;
    setTimeout(() => {
      this.renderer.setStyle(mobileModal, 'display', 'none');
    }, 500);
    const modal = mobileModal.getElementsByClassName('modal-mobile')[0];
    this.renderer.removeClass(modal, 'active');
    this.renderer.addClass(modal, 'inactive');
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    const orderId = encodeURIComponent(
      btoa(`${this.urlEntity?.orderExternalId}`)
    );
    this.orderExternalId = this.urlEntity?.orderExternalId;
    this.urlParamsLink = `/${this.urlEntity?.tenantId}/${this.urlEntity?.brandExternalId}/${orderId}`;
    if (this.itemOb) {
      const encodedOrderExternalId = encodeURIComponent(
        this.itemOb.orderExternalId!
      );
      this.returnsUrl = `${this.urlParamsLink}/main/returns/${this.itemOb?.__refId}/${encodedOrderExternalId}`;
      this.returnDetailsUrl = `${this.urlParamsLink}/main/return-details/${this.itemOb?.__refId}/${encodedOrderExternalId}`;
      this.setSelectedQuantityFromStore();
    }
  }

  onCancelButtonClick() {
    const unselectedItem = {
      selectedItemRefIds: [`${this.itemOb?.__refId}`],
      orderExternalId: this.orderExternalId
    };
    this.cancelButtonClick.emit(unselectedItem);
  }

  setSelectedQuantityFromStore() {
    this.selectedItemSubscription = this.store
      .select(getAllSelectedItems)
      .pipe()
      .subscribe(selectedItems => {
        const orderExternalId = this.orderExternalId;
        if (
          orderExternalId &&
          selectedItems[sanitizeOrderId(orderExternalId)]
        ) {
          const selectedItemFromStore = selectedItems[
            sanitizeOrderId(orderExternalId)
          ].find(item => item.__refId === this.itemOb.__refId);
          if (selectedItemFromStore) {
            this.selectedItemQuantity = selectedItemFromStore.quantity || 0;
          }
        }
      });
  }

  onReturnButtonClick() {
    const encodedOrderExternalId = encodeURIComponent(
      this.itemOb.orderExternalId!
    );
    const selectedItem = {
      returnsUrl: this.returnsUrl,
    };
    this.returnButtonClick.emit(selectedItem);
  }
}
