<div class="border-bottom content-box details-commomn-css"
	[ngClass]="{'pt-0': firstItem, 'border-bottom-0': lastItem, 'pb-0': lastItem}">
	<div class="title" *ngIf="heading">{{heading | translate}}</div>
	<div class="content" *ngIf="content">
		<div class="contentDiv mb-0" [innerHTML]="content">
		</div>
	</div>
	<div *ngIf="subHeading" class="sub-heading" [ngClass]="{'pb-10px': extraContent}">
		<div [ngClass]="{'pb-10px': feeConfig?.showFee}">{{subHeading | translate}}</div>
		<div class="fee-value" *ngIf="feeConfig?.showFee">
			{{ ((feeConfig.fee | typeof) === 'number' && feeConfig.fee !== 0) ? (feeConfig.fee / 100 | currency: 'USD'
			:
			'symbol':'1.2-2') : 'Free'}}
		</div>
	</div>
	<div class="extra-content">
		<ng-content></ng-content>
	</div>
</div>