<div class="returns-error-page">
	<div
		class="main-content-area main-content-area-error-page col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 py-2 p-md-3 mx-auto max-764px">
		<app-user-info></app-user-info>
		<div class="button-wrapper text-center d-flex justify-content-center align-items-center">
			<button type="button" class="btn btn-ds-primary max-203px" [routerLink]="[urlParamsLink+'/login']">Go Back
				To Login</button>
		</div>
	</div>
	<div class=" col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px">
		<app-policy-footer></app-policy-footer>
	</div>
</div>