import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { NearbyDropoffLocationComponent } from './nearby-dropoff-location/nearby-dropoff-location.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { DropOffLocationComponent } from './drop-off-location/drop-off-location.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreComponentsModule } from '@core/components/core-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DSNgSelectSearchboxComponent } from './ds-ngselect-searchbox/ds-ngselect-searchbox.component';
import { GoogleMapsService } from '@services/google-maps.service';
import { PageHeaderComponent } from './page-header/page-header.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TypeofPipe } from '@shared/pipes/typeof.pipe';
import {
  AddressAutoCompleteDirective,
  SwiperDirective,
} from '@shared/directive';
import { ReturnSmartWindowsComponent } from '@shared/components/return-smart-windows/return-smart-windows.component';
import { ReturnSmartWindowDetailsComponent } from '@shared/components/return-smart-window-details/return-smart-window-details.component';
import { ItemAttributeTypeComponent } from '@shared/components/item-attribute-type/item-attribute-type.component';
import { ModalComponent } from '@shared/components/modal/modal.component';

@NgModule({
  declarations: [
    CookieConsentComponent,
    UserInfoComponent,
    NearbyDropoffLocationComponent,
    AddressFormComponent,
    DropOffLocationComponent,
    ReturnSmartWindowsComponent,
    ReturnSmartWindowDetailsComponent,
    ItemAttributeTypeComponent,
    SwiperDirective,
    DSNgSelectSearchboxComponent,
    PageHeaderComponent,
    DropdownComponent,
    TypeofPipe,
    AddressAutoCompleteDirective,
    ModalComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    NgbModule,
    CoreComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgOptimizedImage,
  ],
  providers: [GoogleMapsService],
  exports: [
    CookieConsentComponent,
    UserInfoComponent,
    NearbyDropoffLocationComponent,
    AddressFormComponent,
    DropOffLocationComponent,
    ReturnSmartWindowsComponent,
    ReturnSmartWindowDetailsComponent,
    ItemAttributeTypeComponent,
    SwiperDirective,
    NgSelectModule,
    PageHeaderComponent,
    DropdownComponent,
    TypeofPipe,
    AddressAutoCompleteDirective,
    TranslateModule,
    ModalComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule { }
