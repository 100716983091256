<div id="return-summary-details-component">
    <div class="mx-auto max-764px">
        <app-page-header [showBackButtonIcon]="true" [title]="'Summary Details'" (backButtonClick)="navBack()"
            [alignment]="'center'"></app-page-header>

        <div class="spacing">
            <ng-container *ngIf="!placeHolderLoader; else loadingItem">
                <app-return-method-card *ngIf="returnMethodGroup" [returnSmartWindowSummaryView]="true"
                    [returnMethod]="returnMethodGroup" [lastItem]="true" [hideElement]="{ 
                        'printLabelsBtn':true,  
                        'instructionsBtn':true, 
                        'viewDetailsBtn':true, 
                        'changeAddressBtn':true, 
                        'changePickupWindowBtn':true, 
                        'returnLabelBox': true,
                        'itemList': true,
                    }"></app-return-method-card>
            </ng-container>
        </div>
        <div class="instruction-box"
            *ngIf="returnMethodGroup?.instructions || showPrintLabelButton || returnRequestDetails?.returnOrder?.scanCodeLink!">
            <div class="instruction-title" *ngIf="returnMethodGroup?.instructions">Return Instructions</div>
            <div class="white-box bg-white">
                <div *ngIf="returnMethodGroup?.instructions" class="instructions"
                    [innerHTML]="returnMethodGroup.instructions"></div>
                <swiper-container *ngIf="scanCodeLinks.length" appSwiper #swiper [pagination]="true"
                    [config]="swiperConfig" init="false">
                    <swiper-slide *ngFor="let scanCodeLink of scanCodeLinks">
                        <div class="swiper-wrapper">
                            <!-- Start Request Status -->
                            <app-request-status [orderId]="rmaId" [showPrintLabelOption]="showPrintLabelButton"
                                [urlParams]="urlEntities" [returnOrder]="returnRequestDetails.returnOrder"
                                [scanCodeLinkData]="scanCodeLink" [returnRequestDetails]="returnRequestDetails"
                                [showMessage]="false"></app-request-status>
                            <!-- End -->
                        </div>
                    </swiper-slide>
                </swiper-container>
                <!-- Start Request Status -->
                <app-request-status *ngIf="!scanCodeLinks.length" [orderId]="rmaId"
                    [showPrintLabelOption]="showPrintLabelButton" [urlParams]="urlEntities"
                    [returnOrder]="returnRequestDetails.returnOrder" [returnRequestDetails]="returnRequestDetails"
                    [showMessage]="false"></app-request-status>
                <!-- End -->
                <div class="align-items-center d-flex justify-content-center swiper-pagination-container"
                    [class.d-none]="scanCodeLinks.length<2">
                    <i-feather class="d-flex cursor-pointer swiper-pagination-btn" name="chevron-left"
                        (click)="paginateScanCodeLink('PREV')"></i-feather>
                    <div class="swiper-pagination"></div>
                    <i-feather class="d-flex cursor-pointer swiper-pagination-btn" name="chevron-right"
                        (click)="paginateScanCodeLink('NEXT')"></i-feather>
                </div>
            </div>
        </div>
        <ng-container *ngIf="returnMethodGroup?.itemList">
            <div class="border-bottom">
                <div *ngIf="isReturningItem" class="returning-title">
                    {{'RETURN_REQUEST.RETURNING' | translate}}
                </div>
                <ng-container *ngFor="let item of returnMethodGroup.itemList;let i = index; let lastItem = last">
                    <app-item *ngIf="!item.exchangedItem" [lastItem]="lastItem" [item]="item"
                        [hideKeys]="{'returnDetailsButton': true, 'returnButton': true, 'fee': true}"
                        [urlEntity]="urlEntities" [itemHiddenFields]="hiddenFields"></app-item>
                </ng-container>
            </div>
            <!-- Start Exchanging Item -->
            <ng-container *ngIf="this.inventoryMetaData.length">
                <div class="mt-3 pb-3 exchanging-item-info">
                    <app-exchanging-item-info [itemList]="returnMethodGroup.itemList!" [isExchangeSelected]="true"
                        [inventoryMetaData]="inventoryMetaData"></app-exchanging-item-info>
                </div>
            </ng-container>
            <!-- End Exchanging Item -->
        </ng-container>
        <div class="cancel-button-container">
            <app-return-cancel-button [hostedReturnConfig]="hostedReturnConfig" [rmaId]="returnRequestDetails?.rmaId!"
                [status]="returnRequestDetails?.status!" [returnOrder]="returnRequestDetails?.returnOrder ?? null"
                [urlEntities]="urlEntities" [summaryView]="true"></app-return-cancel-button>
        </div>
    </div>
</div>

<ng-template #loadingItem>
    <ng-container *ngIf="placeHolderLoader">
        <div class="row flex-column item-box p-3 gx-0 mb-md-3 placeholder-loading">
            <div class="d-flex">
                <div class="col-auto img-box linear-background"></div>
                <div class="item-details-box w-100 ps-2">
                    <div class="d-flex justify-content-between mb-2">
                        <div class="w-100 data-row  linear-background"></div>
                    </div>
                    <div class="mb-2 col-12 col-md-6">
                        <div class="w-100 data-row  linear-background"></div>
                    </div>
                    <div class="d-flex mb-2">
                        <div class="col-12 col-md-6 data-row linear-background"></div>
                        <div class="d-none d-md-block col-md-1"></div>
                        <div class="d-none d-md-block col-md-5 data-row linear-background"></div>
                    </div>
                    <div class="d-flex">
                        <div class="col-12 col-md-6 data-row linear-background"></div>
                        <div class="d-none d-md-block col-md-1"></div>
                        <div class="d-none d-md-block col-md-5 ms-md-0 ms-2 data-row linear-background"></div>
                    </div>

                </div>
            </div>
            <div class="options-row d-flex justify-content-between align-items-center mt-2 mt-md-3">
                <div class="col-6"></div>
                <div class="col-6 data-row data-row-ex linear-background"></div>
            </div>
        </div>
    </ng-container>
</ng-template>