<div class="d-flex flex-column justify-content-start align-items-start return-method-smart-window"
	*ngIf="returnSmartWindowFormated">
	<div class="d-flex justify-content-between w-100 return-method-smart-window-header">
		<div class="d-flex gap-2 justify-content-center align-items-center">
			<span class="ps-2">
				<i-feather class="d-flex justify-content-center align-items-center calendar-icon"
					name="calendar"></i-feather>
			</span>
			<div class="return-method-smart-window-title-text"
				[class.return-method-smart-window-title-text-summary]="isSummaryPage">{{"SMART_WINDOW.PICKUP_WINDOW"
				| translate}}
			</div>
		</div>
		<a href="javascript:void(0)" *ngIf="!hideElement.changePickupWindowBtn"
			class="text-center text-decoration-underline user-select-none return-method-smart-window-change-text"
			[class.return-method-smart-window-change-text-summary]="isSummaryPage"
			(click)="openReturnSmartWindows()">{{"GLOBAL.CHANGE" | translate}}</a>
	</div>
	<div class="d-flex flex-column w-100 return-method-smart-window-body">
		<div class="d-flex justify-content-between p-3 return-method-smart-window-slot">
			<div class="font-weight-500">
				<span class="text-black-50 return-method-smart-window-slot-weekday">
					{{returnSmartWindowFormated?.weekday}}
				</span>
				<span class="text-black return-method-smart-window-slot-month-date">
					{{returnSmartWindowFormated?.month}}
					{{returnSmartWindowFormated?.date}}
				</span>
			</div>
			<div class="font-weight-500 text-black return-method-smart-window-slot-timeslot">
				{{returnSmartWindowFormated?.timeSlot}}
			</div>
		</div>
	</div>
	<div *ngIf="pickupInstruction && !isSummaryPage" class="w-100 return-method-smart-window-pickup-instruction-group">
		<span class="font-weight-500 text-black return-method-smart-window-pickup-instruction-header">
			{{"SMART_WINDOW.YOUR_PICKUP_INSTRUCTIONS" | translate}}:
		</span>
		<span class="return-method-smart-window-pickup-instruction-body">
			{{pickupInstruction}}
		</span>
	</div>
</div>