import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { RedirectorComponent } from './core/components/redirector/redirector.component';
import { returnsConfingResolver } from './resolvers/returns-config.resolver';
import { ServiceUnavailableComponent } from './core/components/service-unavailable/service-unavailable.component';
import { LoginComponent } from './features/auth/pages/login/login.component';

const routes: Routes = [
  {
    path: ':tenantId/:brandExternalId/:orderId/main',
    loadChildren: () =>
      import('./features/returns/returns-root.module').then(
        m => m.ReturnsRootModule
      ),
  },
  {
    path: ':tenantId/:brandExternalId/login',
    loadChildren: () =>
      import('./features/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: ":tenantId/:brandExternalId/otp",
    component: LoginComponent,
    resolve: {
      configResp: returnsConfingResolver,
    },
    data: {
      page: 'otp',
    },
  },
  {
    path: 'service-unavailable',
    component: ServiceUnavailableComponent,
  },
  {
    path: ':tenantId/:brandExternalId',
    component: RedirectorComponent,
    resolve: {
      configResp: returnsConfingResolver,
    },
  },
  {
    path: '',
    component: RedirectorComponent,
    pathMatch: 'full',
    resolve: {
      configResp: returnsConfingResolver,
    },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
