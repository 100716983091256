import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getConfig } from '@store/selectors/config.selector';
import { AddressTypes } from '@core/constants/addressTypes-constants';
import { ReturnsConfigService } from '@core/services/returns-config.service';
import { BaseComponent } from '@shared/components/base.component';
import { Address } from '@core/models/address';
import { ReturnMethodGroup } from '@core/models/return-method-group';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { ExchangeMetadataAttribute } from '@core/models/return-request';

@Component({
  selector: 'app-return-method-view-details',
  templateUrl: './return-method-view-details.component.html',
  styleUrls: ['./return-method-view-details.component.scss'],
})
export class ReturnMethodViewDetailsComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @Input() address!: Address;
  @Input() returnMethod!: ReturnMethodGroup;
  @Input() returnFeeLoader = false;
  @Output() byClose = new EventEmitter();
  @Output() getAddress = new EventEmitter();
  @Output() byViewNearByStore = new EventEmitter();
  @Output() byChangeAddress = new EventEmitter();
  @Output() bySelectReturnLabelBox = new EventEmitter();
  @Output() bySetReturnSmartWindow = new EventEmitter();
  configSubscription!: Subscription;
  addressTypes = AddressTypes;
  changeAddressView = false;
  nearByStoreView = false;
  isReturningItem = false;
  public hiddenFields: { [key: string]: boolean } = {
    previewImgModal: true
  };
  entities: unknown = null;
  inventoryMetaData: ExchangeMetadataAttribute[] = [];

  constructor(
    private hostedReturnService: ReturnsConfigService,
    private store: Store
  ) {
    super({ hostedReturnService });
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    if (this.configSubscription) this.configSubscription.unsubscribe();
  }

  init() {
    this.entities = this.getTenantAndBrandExternalId();
    this.configSubscription = this.store
      .select(getConfig)
      .pipe()
      .subscribe(config => {
        if (config) {
          const hiddenFields = this.hostedReturnService.setHiddenFields(config);
          this.hiddenFields = { ...this.hiddenFields, ...hiddenFields }
        }
      });

    this.isReturningItem = !!this.returnMethod.itemList?.some(item => !item.exchangedItem);
    if (!NullEmptyChecker.isDeepNull(this.returnMethod, 'exchangeMetadata')) {
      this.inventoryMetaData = this.returnMethod.exchangeMetadata!.flatMap(metaData => metaData.attributes);
    }
  }

  selectReturnLabelBoxOption(selectedReturnLabelBox: unknown) {
    this.bySelectReturnLabelBox.emit(selectedReturnLabelBox);
  }

  setReturnSmartWindow(returnSmartWindow: unknown) {
    this.bySetReturnSmartWindow.emit(returnSmartWindow);
  }

  setAddress(e: { address: Address }) {
    this.address = e.address;
    this.getAddress.emit({ address: this.address });
    this.toggleChangeAddressView();
  }

  changeAddressNearByStore(changeAddress: string) {
    this.byViewNearByStore.emit(changeAddress);
  }

  toggleNearByStoreView() {
    this.nearByStoreView = !this.nearByStoreView;
    this.byViewNearByStore.emit('Nearby Stores');
  }

  toggleChangeAddressView() {
    this.changeAddressView = !this.changeAddressView;
    this.byChangeAddress.emit(
      this.changeAddressView ? 'Change Your Address' : 'View Details'
    );
  }

  closeViewDetail() {
    this.byClose.emit();
  }
}
