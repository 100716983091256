export interface SuccessState {
  showToast: boolean;
  successMsg: string;
  close?: boolean;
  data?: Record<string, any>;
  timeoutMs?: number;
}

export const initialSuccessState: SuccessState = {
  showToast: false,
  successMsg: '',
  close: false,
  timeoutMs: 5000, // 5 second
};
