import { SmartWindow } from '@core/models/return-smart-windows';

export interface SmartWindowState {
  [key: string]: SmartWindow[];
}

export interface ReturnSmartWindowsState {
  smartWindows: SmartWindowState | null;
}

export const initialReturnSmartWindows: ReturnSmartWindowsState = {
  smartWindows: null,
};
