import { createAction, props } from '@ngrx/store';
import { SmartWindow } from '@core/models/return-smart-windows';

export const RETURN_SMART_WINDOW_ACTION =
  '[Return Smart Window] set smart windows';

export const setReturnSmartWindows = createAction(
  RETURN_SMART_WINDOW_ACTION,
  props<{
    smartWindows: SmartWindow[];
    storeExternalId: string;
    returnMethodCode: string;
    date: number;
  }>()
);
