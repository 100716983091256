import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ErrorState } from '@store/states/error.state';

export const ERROR_STATE = 'error';

const getErrorState = createFeatureSelector<ErrorState>(ERROR_STATE);

export const getErrorResponse = createSelector(
  getErrorState,
  (state: ErrorState) => {
    return {
      showToast: state?.showToast,
      errorMsg: state?.errorMsg,
      close: state?.close,
      data: state?.data,
      timeoutMs: state?.timeoutMs,
    };
  }
);
