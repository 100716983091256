import { createAction, props } from '@ngrx/store';
import { SelectedItemDetails } from '@store/states/selected-item-list.state';

export const SelectedItemListActions = {
  GET_SELECTED_ITEMS: '[Item List Page] Get selected Items',
  ADD_SELECTED_ITEMS: '[Item List Page] Add selected Items',
  DELETE_SELECTED_ITEMS: '[Item List Page] Delete selected Items',
  UPDATE_SELECTED_ITEMS: '[Item List Page] Update selected Items',
  DELETE_ORDER_DETAILS: '[Item List Page] Delete all items for order',
};

export const getSelectedItems = createAction(
  SelectedItemListActions.GET_SELECTED_ITEMS
);

export const addSelectedItems = createAction(
  SelectedItemListActions.ADD_SELECTED_ITEMS,
  props<{
    selectedItem: SelectedItemDetails;
    orderExternalId: string;
  }>()
);

export const deleteSelectedItems = createAction(
  SelectedItemListActions.DELETE_SELECTED_ITEMS,
  props<{
    selectedItemRefIds: string[];
    orderExternalId: string;
  }>()
);

export const updateSelectedItems = createAction(
  SelectedItemListActions.UPDATE_SELECTED_ITEMS,
  props<{
    selectedItemRefId: string;
    selectedItem: SelectedItemDetails;
  }>()
);

export const deleteSelectedOrderDetails = createAction(
  SelectedItemListActions.DELETE_ORDER_DETAILS,
  props<{
    orderExternalId: string;
  }>()
);
