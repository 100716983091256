import { Directive, ElementRef, Output, EventEmitter } from '@angular/core';
import { GoogleMapsService } from '@core/services/google-maps.service';
import { ReturnsConfigService } from '@core/services/returns-config.service';
declare let google: any;

@Directive({
  selector: '[appGooglePlace]',
})
export class AddressAutoCompleteDirective {
  private element: HTMLInputElement;
  @Output() bySelect: EventEmitter<unknown> = new EventEmitter();

  private googleMapUrl: string;
  constructor(
    private elRef: ElementRef,
    private googleMapService: GoogleMapsService,
    private returnsConfigService: ReturnsConfigService
  ) {
    this.googleMapUrl = `https://maps.googleapis.com/maps/api/js?callback=_onGoogleMapLoaded&key=${this.returnsConfigService.hostedReturnsConfig.googleMapsApiKey}&libraries=drawing,geometry,places,visualization`;

    //elRef will get a reference to the element where
    //the directive is placed
    this.element = elRef.nativeElement;
    this.googleMapService.loadGoogleMapsAPI.then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.element);
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        //Emit the new address object for the updated place
        this.bySelect.emit(autocomplete.getPlace());
      });
    });
  }

  loadGoogleMaps() {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = this.googleMapUrl;
    script.id = 'googleScript';
    script.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(script);
  }
}
