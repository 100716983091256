import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { Store } from '@ngrx/store';
import { NgOtpInputComponent } from 'ng-otp-input';
import { Subscription, timer } from 'rxjs';
import { BaseComponent } from '@shared/components/base.component';
import { AuthService } from '@features/auth/services/auth.service';
import { ReturnsConfigService } from '@services/returns-config.service';
import { OtpService } from '@services/otp.service';
import { RedirectorService } from '@services/redirector.service';
import { StorageService } from '@services/storage.service';
import { STATUS_CODE } from '@constants/generic-constants';
import {
  OTP_CHANNEL_EMAIL,
  OTP_CHANNEL_SMS,
  OTP_EXPIRY_SECONDS,
  OTP_RESEND_SECONDS,
  SOURCE_RETURNS,
} from '@constants/otp';
import { getCurrentTimeStamp } from '@core/utils/date-utils';
import { getConfig } from '@store/selectors/config.selector';
import { LocalStorageOtpData } from '@core/models/auth';
import { AuthCreds } from '@features/auth/models/auth-creds';
import {
  BusinessLogo,
  HostedReturnsConfig,
} from '@core/models/returns-config.interface';
import { SendOtp } from '@core/models/otp';
import { UserPilotService } from "@core/services/user-pilot.service";
import { getErrorResponse } from '@store/selectors/error.selector';
import { ErrorState } from '@store/states/error.state';

const PAGE_TYPE = {
  login: 'login',
  otp: 'otp',
};

const LOGIN_METHODS = {
  mobile: 'mobile',
  email: 'email',
};

const MFA = 'mfa';

@Component({
  selector: 'app-ds-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(NgOtpInputComponent) ngOtpInput!: NgOtpInputComponent;
  loginForm!: UntypedFormGroup;
  submitted = false;
  hostedReturnConfig!: HostedReturnsConfig;
  screenSize!: number;
  tenantId = '';
  brandExternalId = '';
  bannerImage = '';
  showMobileField!: boolean;
  mfaEnabled!: boolean;
  loginMethod!: string;
  dataSubscription!: Subscription;
  configSubscription!: Subscription;
  errorSubscription!: Subscription;
  errorMessage = '';
  loginProcessing = false;
  headerLogo!: BusinessLogo | undefined;
  screenHeight!: string;
  showOtpScreen!: boolean;
  resendSeconds!: number;
  otp = '';
  otpFailed = false;
  sendOtpProgressing = false;
  currentPage: any;
  contactUrl = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private store: Store,
    private storageService: StorageService,
    override returnsConfigService: ReturnsConfigService,
    private redirectorService: RedirectorService,
    private otpService: OtpService,
    private location: Location,
    private userPilotIdentifier: UserPilotService,
  ) {
    super({ returnsConfigService });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenSize = window.innerWidth;
  }

  ngOnInit(): void {
    this.dataSubscription = this.activatedRoute.data.subscribe(data => {
      if (data['configResp'] === undefined) {
        this.router.navigate(['/service-unavailable'], {
          skipLocationChange: true,
        });
        return;
      }
      this.currentPage = data['page'];

      this.getConfig();
      this.screenSize = window.innerWidth;
      this.setWindowHeightToContainer();
      if(data['configResp']?.components?.needHelp?.active) {
        this.contactUrl = data['configResp']?.components?.needHelp?.helpUrl || '';
      }
    });

    let timeOutErrorToast: Subscription;
    this.errorSubscription = this.store
      .select(getErrorResponse)
      .pipe()
      .subscribe((error: ErrorState) => {
        if (!NullEmptyChecker.isNull(timeOutErrorToast)) {
          timeOutErrorToast.unsubscribe();
        }
        this.closeError()
        setTimeout(() => {
          this.errorMessage = error.errorMsg;
          timeOutErrorToast = timer(error.timeoutMs || 3000).subscribe(() => {
            this.closeError();
          });
        });
      });
  }

  getConfig() {
    this.configSubscription = this.store
      .select(getConfig)
      .pipe()
      .subscribe(config => {
        this.hostedReturnConfig = config;
        this.tenantId = config['tenantId'];
        this.brandExternalId = config['brandExternalId'];
        const { businessLogos } = config.branding;
        this.headerLogo =
          businessLogos &&
          businessLogos.find((logo: BusinessLogo) => {
            return logo.size === 'large';
          });
        const banner = config.components?.banner;
        if (banner?.active) {
          this.bannerImage = banner.url;
        }
        if (this.currentPage === PAGE_TYPE.login) {
          this.storageService.setNewKey(this.tenantId);
          this.storageService.clear(MFA);
        }
        this.initForm();
      });
  }

  get f() {
    return this.loginForm;
  }

  initForm() {
    const group: any = {
      orderId: ['', [Validators.required]],
    };
    const { loginMethod, mfaEnabled } = this.hostedReturnConfig;
    this.showMobileField = loginMethod === LOGIN_METHODS.mobile;
    this.mfaEnabled = mfaEnabled;
    this.loginMethod = loginMethod;
    if (this.showMobileField) {
      group['mobileNumber'] = ['', [Validators.required]];
    } else {
      group.emailId = [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ];
    }
    this.loginForm = this.formBuilder.group(group);
    this.goToOtpPage(null as unknown as LocalStorageOtpData);
  }

  checkIfCountryCodePresent(phone: string) {
    if (!phone || !phone.startsWith('+')) return;
    return phone;
  }

  getFormData() {
    if (this.f.invalid) {
      return;
    }

    this.loginProcessing = true;
    const email = this.f.get('emailId')?.value;
    const phone = this.f.get('mobileNumber')?.value.e164Number;

    let ob: AuthCreds = {
      tenantId: this.tenantId,
      brandExternalId: this.brandExternalId,
      orderId: this.f.get('orderId')?.value,
    };

    if (email) {
      ob = { ...ob, email };
    } else if (phone) {
      const mobile = this.checkIfCountryCodePresent(phone);
      ob = { ...ob, phone: mobile };
    }

    return ob;
  }

  login() {
    this.submitted = true;
    const ob = this.getFormData();

    if (!ob) {
      return;
    }

    this.loginProcessing = true;

    if (this.mfaEnabled) {
      let data: SendOtp = {
        brandExternalId: ob.brandExternalId,
        tenantId: ob.tenantId,
        orderId: ob.orderId,
        channel: this.getChannelType(),
        source: SOURCE_RETURNS,
      };

      if (this.loginMethod === LOGIN_METHODS.mobile) {
        data = { ...data, mobile: ob.phone };
      } else if (this.loginMethod === LOGIN_METHODS.email) {
        data = { ...data, email: ob.email };
      }

      this.sendOtp(data);
    } else {
      this.verifyUser(ob);
    }
  }

  verifyOtp() {
    const data = this.getOtpData();
    this.verifyUser(
      {
        phone: data.mobile,
        email: data.email,
        orderId: data.orderId,
        tenantId: this.tenantId,
        brandExternalId: this.brandExternalId,
      },
      {
        otp: this.otp,
        channel: this.getChannelType(),
      }
    );
  }

  getChannelType() {
    return this.loginMethod === LOGIN_METHODS.mobile
      ? OTP_CHANNEL_SMS
      : OTP_CHANNEL_EMAIL;
  }

  verifyUser(
    data: AuthCreds,
    queryParams: Record<string, string | number> = {}
  ) {
    this.loginProcessing = true;
    this.authService.login(data, queryParams).subscribe({
      next: resp => {
        this.loginProcessing = false;
        const { orderExternalId, customer } = resp?.invoiceInfo ?? {};
        this.userPilotIdentifier.setupUserPilotIdentifier(customer, this.tenantId, this.brandExternalId);
        this.storageService.setNewKey(data.tenantId);
        this.storageService.clear(MFA);
        if (!orderExternalId) {
          this.errorMessage =
            'Please check you login response.. orderExternald might be missing';
          setTimeout(() => {
            this.closeError();
          }, 3000);
          return;
        }
        this.storageService.setNewKey(orderExternalId);
        if (resp) {
          this.storageService.set('token', resp.token);
          this.storageService.set('authResp', resp);
        }

        const entities = this.getTenantAndBrandExternalId();
        this.redirectorService.redirectToPage(resp, entities);
      },
      error: error => {
        this.loginProcessing = false;
        this.otpFailed = true;
        if(this.contactUrl){
          this.errorMessage = `Something went wrong. Please try again or <a class='contact-us' target="_blank" href='${this.contactUrl}'>contact us</a>`;
        } else {
          this.errorMessage = `Something went wrong. Please try again or contact us`;
        }
        if (error.code === STATUS_CODE.notFound) {
          this.goToLogin();
        }
        setTimeout(() => {
          this.closeError();
        }, 3000);
      },
    });
  }
  closeError() {
    this.errorMessage = '';
  }

  setWindowHeightToContainer() {
    // for mobile screens
    if (this.screenSize < 768) {
      if (window.innerHeight < 600) {
        this.screenHeight = `700px`;
        return;
      }
      this.screenHeight = `${window.innerHeight}px`;
      return;
    }
    // for web
    this.screenHeight = '100vh';
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
    if (this.configSubscription) this.configSubscription.unsubscribe();
    if (this.errorSubscription) this.errorSubscription.unsubscribe();
    this.closeError();
  }

  onOtpChange($otp: string) {
    this.otpFailed = false;
    this.otp = $otp;
  }

  resendTimer() {
    setTimeout(() => {
      this.resendSeconds = this.resendSeconds - 1;
      if (this.resendSeconds > 0) {
        this.resendTimer();
      }
    }, 1000);
  }

  goToLogin() {
    this.storageService.setNewKey(this.tenantId);
    this.storageService.clear(MFA);
    this.showOtpScreen = false;
    this.loginProcessing = false;
    this.changeUrlState(PAGE_TYPE.login);
  }

  initializeOtpCounter(seconds: number) {
    if (seconds > 0) {
      this.resendSeconds = seconds;
      this.resendTimer();
    } else {
      this.resendSeconds = 0;
    }
  }

  storeOtpData(data: LocalStorageOtpData) {
    this.storageService.setNewKey(this.tenantId);
    this.storageService.set(MFA, JSON.stringify(data));
  }

  getOtpData(): LocalStorageOtpData {
    try {
      this.storageService.setNewKey(this.tenantId);
      const existingData = this.storageService.get(MFA);
      if (existingData) {
        return JSON.parse(existingData);
      }
    } catch (exception) {
      return {} as LocalStorageOtpData;
    }
    return {} as LocalStorageOtpData;
  }

  isValidOtpData(data: LocalStorageOtpData): boolean {
    if (
      !data ||
      (data && !NullEmptyChecker.isNonEmptyArray(Object.keys(data)))
    ) {
      return false;
    } else {
      if (
        data.brandExternalId === this.brandExternalId &&
        data.tenantId === this.tenantId &&
        OTP_EXPIRY_SECONDS - (getCurrentTimeStamp() - data.verifyTime) > 0
      ) {
        return true;
      }
    }
    return false;
  }

  changeUrlState(page: string) {
    const urlParts = window.location.pathname.split('/');
    urlParts.pop();
    urlParts.push(page);
    this.location.replaceState(urlParts.join('/'));
  }

  goToOtpPage(data: LocalStorageOtpData) {
    if (!this.mfaEnabled) {
      return;
    }
    this.loginProcessing = false;
    this.showOtpScreen = true;
    this.changeUrlState(PAGE_TYPE.otp);
    if (data) {
      this.storeOtpData(data);
      this.initializeOtpCounter(OTP_RESEND_SECONDS);
    } else {
      const otpData = this.getOtpData();
      if (this.isValidOtpData(otpData)) {
        this.initializeOtpCounter(
          OTP_RESEND_SECONDS - (getCurrentTimeStamp() - otpData.verifyTime)
        );
        if (this.loginMethod === LOGIN_METHODS.mobile) {
          this.loginForm.controls['mobileNumber'].setValue(otpData?.mobile);
        } else if (this.loginMethod === LOGIN_METHODS.email) {
          this.loginForm.controls['emailId'].setValue(otpData?.email);
        }
      } else {
        this.storageService.setNewKey(this.tenantId);
        this.storageService.clear(MFA);
        this.showOtpScreen = false;
        this.changeUrlState(PAGE_TYPE.login);
      }
    }
  }

  handleResendOtpClick() {
    this.sendOtpProgressing = true;
    this.otp = '';
    if (this.ngOtpInput) {
      this.ngOtpInput.setValue('');
      this.ngOtpInput.focusTo(this.ngOtpInput.getBoxId(0));
    }
    const loginOtpData = this.getOtpData();
    let data: SendOtp = {
      orderId: loginOtpData.orderId,
      tenantId: loginOtpData.tenantId,
      brandExternalId: loginOtpData.brandExternalId,
      source: SOURCE_RETURNS,
      channel: this.getChannelType(),
    };

    if (this.loginMethod === LOGIN_METHODS.mobile) {
      data = { ...data, mobile: loginOtpData.mobile };
    } else if (this.loginMethod === LOGIN_METHODS.email) {
      data = { ...data, email: loginOtpData.email };
    }

    this.sendOtp(data);
  }

  sendOtp(data: SendOtp) {
    this.otpService.send(data).subscribe({
      next: (resp: any) => {
        this.loginProcessing = false;
        this.sendOtpProgressing = false;

        if (resp?.message) {
          this.goToOtpPage({
            verifyTime: getCurrentTimeStamp(),
            type: SOURCE_RETURNS,
            ...data,
          });
        } else {
          this.showOtpScreen = this.showOtpScreen || false;
          this.changeUrlState(
            this.showOtpScreen ? PAGE_TYPE.otp : PAGE_TYPE.login
          );
        }
      },
      error: error => {
        this.loginProcessing = false;
        this.sendOtpProgressing = false;

        this.errorMessage = error;
        this.showOtpScreen = this.showOtpScreen || false;
        this.changeUrlState(
          this.showOtpScreen ? PAGE_TYPE.otp : PAGE_TYPE.login
        );
        setTimeout(() => {
          this.closeError();
        }, 3000);
      },
    });
  }
}
