import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from '@core/models/returns-config.interface';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  menuType = 'web';

  @Input() menuItems!: MenuItem[];
  @Input() set type(value: string) {
    if (value) this.menuType = value;
  }

  @Output() byCloseMenu = new EventEmitter();

  closeMenu() {
    this.byCloseMenu.emit();
  }
}
