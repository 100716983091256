import { createAction, props } from '@ngrx/store';

export const LOGIN_SUCCESS_ACTION = '[login page] login success';
export const LOGOUT_ACTION = '[logout] logout success';

export const loginSuccess = createAction(
  LOGIN_SUCCESS_ACTION,
  props<{ resp: any }>()
);
export const logout = createAction(LOGOUT_ACTION);
