import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ReturnWindow } from '@core/models/return-smart-windows';
import { generateHumanReadableTimeFormatFromEpoch } from '@core/utils/date-utils';
import { NullEmptyChecker } from '@deliverysolutions/utils';

@Component({
  selector: 'app-return-smart-window-details',
  templateUrl: './return-smart-window-details.component.html',
  styleUrls: ['./return-smart-window-details.component.scss'],
})
export class ReturnSmartWindowDetailsComponent implements OnInit, OnChanges {
  @Input() isSummaryPage = false;
  @Input() smartWindow!: ReturnWindow;
  @Input() pickupInstruction? = '';
  @Output() byOpenReturnSmartWindows = new EventEmitter();
  @Input() hideElement = {
    changePickupWindowBtn: false,
  };

  returnSmartWindowFormated?: {
    weekday: string;
    month: string;
    date: string;
    timeSlot: string;
  };

  ngOnInit(): void {
    if (!NullEmptyChecker.isDeepNull(this.smartWindow, 'pickupTime')) {
      this.getEpochToFormatedTime(this.smartWindow);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !NullEmptyChecker.isDeepNull(changes, 'smartWindow') ||
      !NullEmptyChecker.isDeepNull(changes, 'pickupInstruction')
    ) {
      if (!NullEmptyChecker.isDeepNull(this.smartWindow, 'pickupTime')) {
        this.getEpochToFormatedTime(this.smartWindow);
      }
    }
  }

  getEpochToFormatedTime(window: ReturnWindow) {
    const timeSlot = generateHumanReadableTimeFormatFromEpoch(
      window!.pickupTime!.startsAt,
      window!.pickupTime!.endsAt,
      (window.tz || window.timeZone)!
    );

    const dateTime = new Intl.DateTimeFormat('en-US', {
      timeZone: window.tz,
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    });

    const day = dateTime.format(
      new Date(window!.pickupTime!.startsAt).getTime()
    );
    const weekday = day.split(',')[0];
    const month = day.split(' ')[1];
    const date = day.split(' ')[2];

    this.returnSmartWindowFormated = {
      weekday,
      month,
      date,
      timeSlot,
    };
  }

  openReturnSmartWindows() {
    this.byOpenReturnSmartWindows.emit();
  }
}
