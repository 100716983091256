export type DateFragment = {
  day: string;
  date: number;
  epoch: number;
  month: number;
  monthStr: string;
  year: number;
  dateObj: Date;
};

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Gets a fragment of a date based on the current date and an index offset.
 * @param {Date} currentDate - The current date to calculate from.
 * @param {number} index - The number of days to add to the current date.
 * @returns {DateFragment} An object containing the day, date, epoch time, and month index.
 */
export function getDateFragment(
  currentDate: Date,
  index: number
): DateFragment {
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + index);

  const day = days[nextDate.getDay()];
  const date = nextDate.getDate();
  const epoch = nextDate.valueOf();
  const month = nextDate.getMonth();
  const monthStr = months[nextDate.getMonth()];
  const year = nextDate.getFullYear();

  return {
    day,
    date,
    epoch,
    month,
    monthStr,
    year,
    dateObj: nextDate,
  };
}

/**
 * Function to generate the next N dates starting from the current date.
 * @param maxNDate - Number of dates to generate
 * @returns Array of DateFragment objects representing the next N dates
 */
export function getNextNDates(maxNDate: number): DateFragment[] {
  const currentDate = new Date();
  const dates: DateFragment[] = [];

  for (let i = 0; i < maxNDate; i++) {
    dates.push(getDateFragment(currentDate, i));
  }

  return dates;
}

/**
 * Generates a formatted month title based on an array of DateFragment objects.
 * @param {DateFragment[]} nextNDates - The array of DateFragment to process.
 * @returns {string} A string representing the formatted month title.
 */
export function getMonthTitle(nextNDates: DateFragment[]) {
  if (nextNDates.length < 1) {
    const currentDate = new Date();
    return `${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
  }

  const i = nextNDates.length - 1;
  const firstDate: DateFragment = nextNDates[0];
  const lastDate: DateFragment = nextNDates[i];

  if (firstDate.year != lastDate.year) {
    return `${firstDate.monthStr} ${firstDate.year} - ${lastDate.monthStr} ${lastDate.year}`;
  }

  if (firstDate.monthStr != lastDate.monthStr) {
    return `${firstDate.monthStr} - ${lastDate.monthStr} ${lastDate.year}`;
  }

  return `${firstDate.monthStr} ${lastDate.year}`;
}

const generate12HourClockTime = (date: Date, timeZone: string): string => {
  const dateTime = new Intl.DateTimeFormat('en-US', {
    timeZone,
    hour: 'numeric',
    minute: 'numeric',
  });
  return dateTime.format(date.getTime());
};

/**
 * Function to generate a human-readable time format from two given epochs in the specified time zone.
 * @param epoch1 The first epoch time
 * @param epoch2 The second epoch time
 * @param timeZone The time zone for conversion
 * @returns A string representing the human-readable time format from the epochs
 */
export function generateHumanReadableTimeFormatFromEpoch(
  epoch1: number,
  epoch2: number,
  timeZone: string
): string {
  const time1 = generate12HourClockTime(new Date(epoch1), timeZone);
  const time2 = generate12HourClockTime(new Date(epoch2), timeZone);

  return `${time1} - ${time2}`;
}

export function addMinutesToDate(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000);
}

export function generateEstimatedDeliveryTime(date: Date): string {
  const datetime = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return datetime.format(date);
}

export function generateOrderDeliveryTime(timestamp: number, timeZone: string) {
  const datetime = new Intl.DateTimeFormat('en-US', {
    timeZone,
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return datetime.format(new Date(timestamp));
}

/**
 * Function to generate a formatted datetime string based on a timestamp and timezone for displaying on an order progress bar.
 * @param timestamp - The timestamp to format
 * @param timeZone - The timezone to use for formatting
 * @returns A formatted datetime string
 */
export function generateDatetimeToDisplayOnOrderProgressbar(
  timestamp: number,
  timeZone: string
) {
  const datetime = new Intl.DateTimeFormat('en-US', {
    timeZone,
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return datetime.format(new Date(timestamp));
}

/**
 * Function to generate a human-readable datetime format based on the time difference between two timestamps.
 * @param timestamp1 - The first timestamp.
 * @param timestamp2 - The second timestamp.
 * @returns A string representing the time difference in a human-readable format.
 */
export function generateHumanReadableDatetimeFormat(
  timestamp1: number,
  timestamp2: number
) {
  let delta = Math.abs(timestamp2 - timestamp1);
  delta = delta / 1000; // convert into seconds

  delta = Math.trunc(delta / 60); // convert into minutes

  if (delta < 60) {
    return `${delta} minutes`;
  }

  const hours = Math.trunc(delta / 60);
  const minutes = delta % 60;

  const hoursPart = hours > 1 ? `${hours} hours` : `${hours} hour`;

  if (minutes === 0) {
    return hoursPart;
  }

  return hoursPart + ', ' + minutes + ' minutes';
}

export function getCurrentTimeStamp() {
  return Math.floor(Date.now() / 1000);
}
