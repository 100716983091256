import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService extends BaseService {
  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  uploadFile(uploadFileData: FormData) {
    return this.httpClient
      .post(`${this.serverRootPathV2}returns/common/s3/upload-file`, uploadFileData)
      .pipe(
        map((val: any) => val),
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }
}
