<div class="d-flex flex-column justify-content-center align-items-center rounded w-100 h-100 ds-modal">
    <div class="ds-modal-header w-100 h-100">
        <ng-content select="[modal-header-content]"></ng-content>
    </div>
    <div class="ds-modal-body w-100 h-100">
        <ng-content select="[modal-body-content]"></ng-content>
    </div>
    <div class="ds-modal-footer w-100 h-100">
        <ng-content select="[modal-footer-content]"></ng-content>
    </div>
</div>