import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOptimizedImage } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

import { CoreComponentsModule } from '@core/components/core-components.module';
import { ItemComponent } from './components/item/item.component';
import { ExchangeItemComponent } from '@features/returns/components/exchange-item/exchange-item.component';
import { ItemListComponent } from './pages/item-list/item-list.component';
import { ReturnsRootRoutingModule } from './returns-root-routing.module';

import { RecommendationsComponent } from './components/recommendations/recommendations.component';
import { ReturnRequestStatusComponent } from './components/return-request-status/return-request-status.component';
import { SingleAdComponent } from './components/single-ad/single-ad.component';
import { ReturnDetailsComponent } from './pages/return-details/return-details.component';
import { ReturnRequestPlacedComponent } from './pages/return-request-placed/return-request-placed.component';
import { ReturnsComponent } from './pages/returns/returns.component';
import { ReturnsRootComponent } from './returns-root.component';
import { ContentBoxComponent } from './components/content-box/content-box.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { StatusLoadingComponent } from './pages/status-loading/status-loading.component';
import { ReturnPreviewComponent } from './pages/return-preview/return-preview.component';
import { ReturnMethodCardComponent } from './components/return-method-card/return-method-card.component';
import { ReturnMethodViewDetailsComponent } from './components/return-method-view-details/return-method-view-details.component';
import { ReturnSummaryComponent } from './pages/return-summary/return-summary.component';
import { ReturnSummaryDetailsComponent } from './components/return-summary-details/return-summary-details.component';
import { ReturnCancelButtonComponent } from '@features/returns/components/return-cancel-button/return-cancel-button.component';
import { ExchangingItemInfoComponent } from '@features/returns/components/exchanging-item-info/exchanging-item-info.component';
import { CommonErrorPageComponent } from '@pages/common-error-page/common-error-page.component';
import { UploadFileComponent } from '@features/returns/components/upload-file/upload-file.component';

@NgModule({
  declarations: [
    ReturnRequestStatusComponent,
    ReturnsRootComponent,
    ItemListComponent,
    ItemComponent,
    ExchangeItemComponent,
    ReturnsComponent,
    ReturnRequestPlacedComponent,
    ReturnSummaryComponent,
    ReturnDetailsComponent,
    ErrorPageComponent,
    SingleAdComponent,
    RecommendationsComponent,
    ContentBoxComponent,
    StatusLoadingComponent,
    ReturnPreviewComponent,
    ReturnMethodCardComponent,
    UploadFileComponent,
    ReturnMethodViewDetailsComponent,
    ReturnSummaryDetailsComponent,
    ReturnCancelButtonComponent,
    ExchangingItemInfoComponent,
    CommonErrorPageComponent,
  ],
  imports: [
    SharedModule,
    CoreComponentsModule,
    ReturnsRootRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    NgbModule,
    NgOptimizedImage,
  ],
  exports: [
    ExchangeItemComponent,
  ],
  providers: [{ provide: Window, useValue: window }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReturnsRootModule { }
