import { createReducer, on } from "@ngrx/store";
import { loginSuccess } from "../actions/auth.action";
import { initialAuthState } from "../states/auth.state";


const _authReducer = createReducer(
	initialAuthState,
	on(loginSuccess, (state, action) => {
		return {
			...state,
			authResp: { ...state.authResp, ...action.resp }
		}
	}),
)

export function authReducer(state: any, action: any) {
	return _authReducer(state, action);
}