import { createReducer, on } from '@ngrx/store';
import { setReturnSmartWindows } from '@store/actions/return-smart-windows.action';
import {
  ReturnSmartWindowsState,
  initialReturnSmartWindows,
} from '@store/states/return-smart-windows.state';

const _returnSmartWindowsReducer = createReducer(
  initialReturnSmartWindows,
  on(setReturnSmartWindows, (state, action): ReturnSmartWindowsState => {
    const key = `${action.returnMethodCode}-${action.storeExternalId}-${action.date}`;

    const smartWindowsState = { ...state.smartWindows };
    smartWindowsState[key] = action.smartWindows;

    return {
      ...state,
      smartWindows: smartWindowsState,
    };
  })
);

/**
 * This function is a reducer that handles actions related to return smart windows.
 * @param state The current state of the return smart windows.
 * @param action The action to be processed.
 * @returns The updated return smart windows state.
 */
export function returnSmartWindowsReducer(
  state: ReturnSmartWindowsState,
  action: any
) {
  return _returnSmartWindowsReducer(state, action);
}
