export function convertFileToBase64(file: Blob): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function convertBase64ToFile(base64String: string, fileName: string): File {
    const mimeType = base64String.match(/data:(.*);base64/)?.[1];

    if (!mimeType) {
        throw new Error("Invalid Base64 string");
    }

    const byteString = atob(base64String.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
}

export function validateImageFile(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => resolve(true); // Valid image
            img.onerror = () => resolve(false); // Invalid or corrupt image
        };
        reader.onerror = () => reject(false);
        reader.readAsDataURL(file);
    });
}