import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DropdownComponent,
    },
  ],
})
export class DropdownComponent implements ControlValueAccessor, Validator {
  @Input() isDisabled = false;
  @Input() selectedOption?: {
    title?: string | undefined;
    fee?: number | undefined;
    prefix?: string | undefined;
  };
  @Input() config!: {
    prefix: 'currency' | 'string';
    prefixDefault: string;
  };
  @Input() options: {
    title: string;
    fee?: number;
    data: unknown;
  }[] = [];

  @Input()
  defaultSelectedOption?: string;
  
  @Output() bySelectOption: EventEmitter<any> = new EventEmitter();

  touched = false;
  disabled = false;
  isOpen = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement: unknown) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.isOpen) {
      this.isOpen = false;
    }
  }

  onChange = (option: any) => {};

  onTouched = () => {};

  toggleDropdown(event?: Event): void {
    if (event) event.preventDefault();
    this.isOpen = !this.isOpen;
  }

  onSelect(option: any) {
    this.markAsTouched();
    if (!this.disabled) {
      this.toggleDropdown();
      this.bySelectOption.emit(option);
      this.onChange(option);
    }
  }

  writeValue(option: any) {
    this.selectedOption = option;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const option = control.value;
    return null;
  }
}
