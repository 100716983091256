<div class="d-flex justify-content-center align-items-center ds-button-container">
	<button class="ds-button user-select-none" [class.ds-button-hover]="!disabledHoverEffect"
		[class.ds-button-mobile]="enableMobileView" [class.ds-button-selected]="selected" [ngStyle]="buttonStyle"
		[ngClass]="buttonClass" (click)="onButtonClick()" [disabled]="disabled" [type]="buttonType">
		<ng-container *ngIf="!showLoader">
			<i-feather *ngIf="buttonIcon && buttonIconPosition === 'prefix'" [name]="buttonIcon"></i-feather>
			<ng-container *ngIf="!buttonText">
				<ng-content></ng-content>
			</ng-container>
			<ng-container *ngIf="buttonText">
				{{buttonText | translate }}
			</ng-container>
			<i-feather *ngIf="buttonIcon && buttonIconPosition === 'sufix'" [name]="buttonIcon"></i-feather>
		</ng-container>
		<app-bubble-loader *ngIf="showLoader"></app-bubble-loader>
	</button>
</div>