import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, AfterViewInit {
  defaultPosition = 'right';
  sidnavPosition!: string;
  sidenavWidth!: string;
  innerWidth: any;
  sidenavType: any;

  DEFAULT_WIDTH = '33%';
  FULL_WIDTH = '100%';
  DEFAULT_SIDENAV_TYPE = 'sidenav';
  MIDIUM_SCREEN_SIZE = 768;
  MENU_WIDTH = '80%';

  sidenavMobileScreenSize: any = {
    sidenav: '100%',
    menu: '100%',
  };

  @Input() set side(value: string) {
    this.sidnavPosition = value ? value : this.defaultPosition;
  }

  @Input() set type(value: string) {
    this.sidenavType = value ? value : this.DEFAULT_SIDENAV_TYPE;
  }

  @Input() headerTitle = ''; // header for all the other sidenav except mobile menu sidenav
  @Input() logo!: string; // This logo is for mobile menu

  @Output() bySidenavClosed = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.sidenavWidthCalc();
  }

  ngOnInit(): void {
    this.sidenavType = this.sidenavType
      ? this.sidenavType
      : this.DEFAULT_SIDENAV_TYPE;
    this.innerWidth = window.innerWidth;
    document.body.classList.add('overflow-hidden');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sidenavWidthCalc();
    });
  }

  close() {
    this.sidenavWidth = '0px';
    document.body.classList.remove('overflow-hidden');
    setTimeout(() => {
      this.bySidenavClosed.emit();
    }, 400);
  }

  sidenavWidthCalc() {
    this.sidenavWidth =
      this.innerWidth < this.MIDIUM_SCREEN_SIZE
        ? this.sidenavMobileScreenSize[this.sidenavType]
        : this.DEFAULT_WIDTH;
  }
}
