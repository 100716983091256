export interface ErrorState {
  showToast: boolean;
  errorMsg: string;
  close?: boolean;
  data?: Record<string, any>;
  timeoutMs?: number;
}

export const initialErrorState: ErrorState = {
  showToast: false,
  errorMsg: '',
  close: false,
  timeoutMs: 5000, // 5 second
};
