import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SidenavComponent } from '@core/components/sidenav/sidenav.component';
import {
  BusinessLogo,
  Header,
  HostedReturnsConfig,
} from '@core/models/returns-config.interface';
import { Store } from '@ngrx/store';
import { getConfig } from '@store/selectors/config.selector';
import { getOrderIdAndUser } from '@store/selectors/auth.selector';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy, OnChanges, AfterViewInit {
  @Input() header!: Header;
  @Input() businessLogos!: BusinessLogo[];
  @Input() isBackgroundWhite!: boolean;
  showMenu = false;
  currentRoute = '';
  routeSubscription!: Subscription;
  @ViewChild(SidenavComponent) sidenavInstance!: SidenavComponent;
  headerLogo!: BusinessLogo | undefined;
  menuItems: any;
  @ViewChild('headerDiv') elementView!: ElementRef;
  @Output() headerHeight = new EventEmitter();
  orderId?: string;
  hostedReturnConfig!: HostedReturnsConfig;
  detailsSubscription!: Subscription;

  constructor(
    private router: Router,
    public store: Store,
    private renderer: Renderer2
  ) {
    this.routeSubscription = router.events.subscribe((event: any) => {
      const orderId = event?.snapshot?.params?.['orderId'];
      if (event instanceof NavigationEnd) {
        const urlParts = event.url.split('/');
        const path = urlParts[urlParts.length - 2];
        this.currentRoute = path ? path : '';
      } else if (orderId) {
        this.hostedReturnConfig = event?.snapshot?.data?.['configResp'];
      }
      this.detailsSubscription = this.store
        .select(getOrderIdAndUser)
        .pipe()
        .subscribe(data => {
          this.orderId = data?.orderId ? data.orderId : '';
        });
      this.handleHeaderView();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.handleHeaderView();
  }

  ngOnChanges() {
    this.headerLogo =
      this.businessLogos &&
      this.businessLogos.find(logo => {
        return logo.size === 'large';
      });
    if (this.header?.navigationMenu?.menuItems?.length > 0) {
      this.menuItems = this.header.navigationMenu.menuItems;
    }
  }

  handleHeaderView() {
    if (this.router.url.includes('itemlist'))
      this.store
        .select(getConfig)
        .pipe()
        .subscribe(config => {
          this.hostedReturnConfig = config;
        });

    setTimeout(() => {
      this.headerHeight.emit({
        height: this.elementView.nativeElement.offsetHeight,
      });
    });
  }

  ngAfterViewInit(): void {
    this.handleHeaderView();
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
    if (this.detailsSubscription) this.detailsSubscription.unsubscribe();
  }

  openMenu() {
    this.showMenu = true;
  }

  closeMenu() {
    this.showMenu = false;
  }

  goBack() {
    history.back();
  }
}
