import { Injectable } from '@angular/core';

export type DspType = { dspName?: string; displayName?: string; name?: string };

@Injectable({
  providedIn: 'root',
})
export class DSPService {
  public readonly pathToAssets = 'assets/images';
  public availableDSPLogos: string[] = [
    'DHL',
    'Favor',
    'Lyft',
    'Ninja',
    'Onfleet',
    'Roadie',
    'DoorDash',
    'Instacart',
    'Skipcart',
    'Shipt',
    'FlyBuy',
    'FedEx EP',
    'FedEx SameDay City',
    'UPS',
    'UPS Shipping',
    'Uber Direct',
    'JoeyCo',
    'LaserShipV2',
    'Amazon MWS',
    'AxleHire',
    'Canada Post',
    'Direct post',
    'Dropoff',
    'Fastway',
    'GsoAccount',
    'LSO',
    'Newgistics',
    'Point Pickup',
    'TForce',
    'UspsAccount',
    'Ninja Delivery',
    'Self Delivery',
    'PickupNow',
    'Bungii',
    'Instacart Connect',
    'FedEx',
    'MileZero',
    'Swyft',
    'Tortoise',
    'Lalamove',
    'Drive Yello',
    'Stuart',
    'Gophr',
    'Walmart GoLocal',
    'Uber',
    'Nationex',
    'Brookfield Properties',
    'Nationex',
    'StoreShippers',
    'Bell & Howell',
    'Purolator',
    'Trexity',
    'Coyote',
    'Sherpa',
    'Obibox',
    'Dolly',
    'Grocery Runners',
    'Same Day Delivery',
    'TForce Logistics',
    'MNX',
    'Walmart GoLocal',
  ];

  getDSPName(dsp: DspType | string) {
    let dspName = '';
    if (typeof dsp === 'string') {
      dspName = dsp;
    } else {
      dspName = dsp.dspName || dsp.displayName || dsp.name || '';
    }

    return dspName;
  }

  getDSPLogo(dsp: DspType | string) {
    const dspName = this.getDSPName(dsp);

    if (dspName.toLowerCase().includes('lso')) {
      const tempDsp = 'Lso';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }

    if (dspName.toLowerCase().includes('gso')) {
      const tempDsp = 'Gso';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }

    if (dspName.toLowerCase().includes('ontrac')) {
      const tempDsp = 'OnTrac';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }

    if (dspName.toLowerCase().includes('couriersplease')) {
      const tempDsp = 'Couriers Please';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }

    if (dspName.toLowerCase().includes('instacart')) {
      const tempDsp = 'Instacart';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }

    if (dspName.toLowerCase().includes('fedex ep')) {
      const tempDsp = 'FedEx';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }

    if (dspName.toLowerCase().includes('canadapost')) {
      const tempDsp = 'CanadaPost';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }

    if (dspName.toLowerCase().includes('australia post')) {
      const tempDsp = 'AustraliaPost';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }

    const isLogoavailable = this.availableDSPLogos.includes(dspName);
    if (isLogoavailable) {
      return `${this.pathToAssets}/dsp/${dspName}.svg`;
    }
    if (dspName.includes('UPS')) {
      const tempDsp = 'UPS';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }
    if (dspName.includes('Self')) {
      const tempDsp = 'Self Delivery';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }
    if (dspName.includes('DHL')) {
      const tempDsp = 'DHL';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }
    if (dspName.includes('Shipt')) {
      const tempDsp = 'Shipt';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }
    if (dspName.includes('USPS')) {
      const tempDsp = 'USPS';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }
    if (dspName.includes('DoorDash')) {
      const tempDsp = 'DoorDash';
      return `${this.pathToAssets}/dsp/${tempDsp}.svg`;
    }
    return `${this.pathToAssets}/dsp/DSP-Default.svg`;
  }
}
