import { Directive, HostListener, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserPilotService } from "@services/user-pilot.service";

@Directive({
  selector: "[captureEvent]",
})
export class CaptureEventDirective {
  constructor(private userPilotService: UserPilotService, private route: ActivatedRoute) {}

  @Input() captureEvent = "";

  @HostListener("click", ["$event"]) onBtnClick() {
    const parentUrl = this.route.parent?.snapshot.routeConfig?.path || "";
    const url = this.route.snapshot.routeConfig?.path || "";
    let path;
    if (!parentUrl || !url) {
      path = `${parentUrl}/${url}`;
    }
    this.userPilotService.addEventToUserPilot(this.captureEvent, path);
  }
}
