<header class="d-flex justify-content-center flex-column fixed-top header"
	[ngClass]="{'bottom-border': isBackgroundWhite}" #headerDiv>
	<!-- LEFT -->
	<div *ngIf="header?.align === 'left'" class="d-flex align-items-center justify-content-center w-100 header-main">
		<div class="d-flex align-items-center justify-content-between col-12 col-md-10 px-3 max-764px header-logo">
			<div class="d-flex user-select-none">
				<a class="text-decoration-none" [href]="headerLogo?.externalLink"
					[captureEvent]="'click-hosted-return-header'" target="_blank">
					<img [src]="headerLogo?.url" alt="Logo" />
				</a>
			</div>
			<div class="w-100 d-md-flex d-none justify-content-end"
				[class.me-2]="header?.navigationMenu?.active && menuItems">
				<span class="order-id" *ngIf="orderId">Order ID: {{orderId}}</span>
			</div>
			<i-feather name="Menu" (click)="openMenu()" class="header-icon cursor-pointer d-block ms-2 menu-icon"
				*ngIf="currentRoute && currentRoute !== 'returns' && header?.navigationMenu?.active && menuItems"
				[captureEvent]="'click-open-menu'"></i-feather>
		</div>
	</div>
	<!-- LEFT END -->
	<!-- CENTER -->
	<div *ngIf="header?.align === 'center'" class="d-flex align-items-center justify-content-center w-100 header-main">
		<div class="d-flex justify-content-center user-select-none position-absolute w-100 header-logo">
			<a class="text-decoration-none" [href]="headerLogo?.externalLink"
				[captureEvent]="'click-hosted-return-header'" target="_blank">
				<img [src]="headerLogo?.url" alt="Logo" />
			</a>
		</div>
		<div
			class="d-flex align-items-center justify-content-end justify-content-md-between col-12 col-md-10 px-3 max-764px header-logo">
			<div class="w-100 d-md-flex d-none justify-content-end"
				[class.me-2]="header?.navigationMenu?.active && menuItems">
				<span class="order-id order-id-center" *ngIf="orderId">Order ID: {{orderId}}</span>
			</div>
			<i-feather name="Menu" (click)="openMenu()" class="header-icon cursor-pointer d-block ms-2 menu-icon"
				*ngIf="currentRoute && currentRoute !== 'returns' && header?.navigationMenu?.active && menuItems"
				[captureEvent]="'click-open-menu'"></i-feather>
		</div>
	</div>
	<!-- CENTER END -->
	<!-- RIGHT -->
	<div *ngIf="header?.align === 'right'" class="d-flex align-items-center justify-content-center w-100 header-main">
		<div
			class="d-flex align-items-center justify-content-end justify-content-md-between col-12 col-md-10 px-3 max-764px header-logo">
			<div class="w-100 d-md-flex d-none justify-content-end openMenu click-open-menu"
				[class.me-2]="header?.navigationMenu?.active && menuItems">
				<span class="order-id" *ngIf="orderId">Order ID: {{orderId}}</span>
			</div>
			<div class="d-flex user-select-none">
				<a class="text-decoration-none" [href]="headerLogo?.externalLink"
					[captureEvent]="'click-hosted-return-header'" target="_blank">
					<img [src]="headerLogo?.url" alt="Logo" />
				</a>
			</div>
			<i-feather name="Menu" (click)="openMenu()" class="header-icon cursor-pointer d-block ms-2 menu-icon"
				*ngIf="currentRoute && currentRoute !== 'returns' && header?.navigationMenu?.active && menuItems"
				[captureEvent]="'click-open-menu'"></i-feather>
		</div>
	</div>
	<!-- RIGHT END -->
	<div class="header-sub-wrapper">
		<div class="align-items-center d-flex d-md-none w-100 header-sub">
			<span class="order-id" *ngIf="orderId" #orderIdSpan>Order ID: {{orderId}}</span>
		</div>
	</div>
	<app-sidenav *ngIf="showMenu" [side]="'right'" [type]="'menu'" (bySidenavClosed)="closeMenu()"
		[logo]="headerLogo?.url!">
		<app-menu [type]="'mobile'" (byCloseMenu)="sidenavInstance.close()"
			*ngIf="header?.navigationMenu?.active && menuItems" [menuItems]="menuItems"></app-menu>
	</app-sidenav>
</header>