import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ds-button',
  templateUrl: './ds-button.component.html',
  styleUrls: ['./ds-button.component.scss'],
})
export class DsButtonComponent {
  @Input() enableMobileView = false;
  @Input() disabled = false;
  @Input() selected = false;
  @Input() disabledHoverEffect = false;
  @Input() showLoader = false;
  @Input() buttonStyle: { [key: string]: string } = {};
  @Input() buttonClass:
    | string
    | string[]
    | Set<string>
    | { [klass: string]: any } = [];
  @Input() buttonType = 'button';
  @Input() buttonText?: string;
  @Input() buttonIcon?: string;
  @Input() buttonIconPosition?: 'prefix' | 'sufix' = 'sufix';
  @Output() byButtonClick = new EventEmitter();

  onButtonClick() {
    this.byButtonClick.emit();
  }
}
