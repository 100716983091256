import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { environment } from '@environments/environment';
import { ReturnsConfigService } from '@core/services/returns-config.service';

export const returnsConfingResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot
) => {
  const hostedReturnsConfigService = inject(ReturnsConfigService);

  const brandExternalId =
    route.params && route.params['brandExternalId']
      ? route.params['brandExternalId']
      : undefined;
  if (
    environment.env !== 'local' &&
    !window.location.host.includes('localhost') &&
    !window.location.host.endsWith('deliverysolutions.co')
  ) {
    return hostedReturnsConfigService.fetchHostedReturnConfig(
      brandExternalId,
      window.location.host
    );
  } else {
    return hostedReturnsConfigService.fetchHostedReturnConfig(brandExternalId);
  }
};
