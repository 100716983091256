<div id="return-preview-component">
  <div class="col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px align-height">
    <app-page-header [showBackButtonIcon]="true" [title]="'Return Preview'" [alignment]="'center'"
      (backButtonClick)="navigateToItemList()"></app-page-header>
    <div class="spacing">
      <ng-container *ngIf="!masterReturnMethodLoader; else loadingItem">
        <app-return-method-card *ngFor="let returnMethod of returnMethods; index as i; let lastItem = last"
          [returnMethod]="returnMethod" [lastItem]="lastItem" [returnFeeLoader]="calculatedReturnFeeLoader"
          (bySelectReturnLabelBox)="getCalculatedReturnFee()"
          [hideElement]="{'printLabelsBtn':true, 'instructionsBtn':true, 'titleReturnFee':true}"
          [index]="i"></app-return-method-card>
      </ng-container>
    </div>
  </div>
  <div class="col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px pb-80">
    <app-policy-footer></app-policy-footer>
  </div>
  <app-multi-sku-footer class="user-select-none" *ngIf="!masterReturnMethodLoader"
    (primaryButtonClick)="submitReturnRequest()" [primaryButtonText]="'Submit Return'"
    [primaryButtonIcon]="'arrow-right'" [showLoader]="returnRequestLoader" [disabled]="returnRequestLoader"
    [mobileView]="true" [returnFeeLoader]="calculatedReturnFeeLoader"
    [estimatedReturnFee]="(estimatedReturnFee ? (estimatedReturnFee! / 100 | currency: 'USD' :'symbol':'1.2-2') : 'Free')!"
    [info]="'Estimated Return Fee: '" [subInfo]="'This fee will be deducted from your refund'" [subInfoIcon]="'info'">
  </app-multi-sku-footer>
</div>

<ng-template #loadingItem>
  <ng-container *ngIf="masterReturnMethodLoader">
    <div class="row flex-column item-box p-3 gx-0 mb-md-3 placeholder-loading" *ngFor="let loading of [1,2,3,4,5]">
      <div class="d-flex">
        <div class="col-auto img-box linear-background"></div>
        <div class="item-details-box w-100 ps-2">
          <div class="d-flex justify-content-between mb-2">
            <div class="w-100 data-row  linear-background"></div>
          </div>
          <div class="mb-2 col-12 col-md-6">
            <div class="w-100 data-row  linear-background"></div>
          </div>
          <div class="d-flex mb-2">
            <div class="col-12 col-md-6 data-row linear-background"></div>
            <div class="d-none d-md-block col-md-1"></div>
            <div class="d-none d-md-block col-md-5 data-row linear-background"></div>
          </div>
          <div class="d-flex">
            <div class="col-12 col-md-6 data-row linear-background"></div>
            <div class="d-none d-md-block col-md-1"></div>
            <div class="d-none d-md-block col-md-5 ms-md-0 ms-2 data-row linear-background"></div>
          </div>

        </div>
      </div>
      <div class="options-row d-flex justify-content-between align-items-center mt-2 mt-md-3">
        <div class="col-6"></div>
        <div class="col-6 data-row data-row-ex linear-background"></div>
      </div>
    </div>
  </ng-container>
</ng-template>
