import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage = localStorage;
  public orderExternalId!: string;

  setNewKey(orderId: string) {
    const newKey = orderId.replace(/ /g, '_');
    this.orderExternalId = newKey;
  }

  getNewKey(key: string) {
    try {
      return `${decodeURIComponent(this.orderExternalId)}-${key}`;
    } catch (Exception) {
      return `${this.orderExternalId}-${key}`;
    }
  }

  set(key: string, val: any): void {
    const newKey = this.getNewKey(key);
    const data = JSON.stringify(val);
    const encryption = btoa(btoa(encodeURIComponent(data)));
    this.storage.setItem(newKey, encryption);
  }

  get(key: string): string | null {
    if (!key) return null;
    const newKey = this.getNewKey(key);
    let data = this.storage.getItem(newKey);
    data =
      data !== null ? JSON.parse(decodeURIComponent(atob(atob(data)))) : null;
    return data;
  }

  clear(key?: string) {
    if (key) {
      const newKey = this.getNewKey(key);
      this.storage.removeItem(newKey);
    } else {
      this.storage.clear();
    }
  }
}
