import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeaderSpacerState } from '@store/states/header-spacer.state';

export const HEADER_SPACER_STATE = 'headerSpacer';

const selectHeaderSpacerStateFeature =
  createFeatureSelector<HeaderSpacerState>(HEADER_SPACER_STATE);

export const selectHeaderSpacerState = createSelector(
  selectHeaderSpacerStateFeature,
  (state: HeaderSpacerState) => {
    return { headerSpacer: state?.headerSpacer };
  }
);
