import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { ReturnOrder } from '@core/models/return-request';
import { HostedReturnsConfig } from '@core/models/returns-config.interface';
import { ReturnService } from '@features/returns/services/returns.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { showToastError } from '@store/actions/error.action';
import { showToastSuccess } from '@store/actions/success.action';

@Component({
  selector: 'app-return-cancel-button',
  templateUrl: './return-cancel-button.component.html',
  styleUrls: ['./return-cancel-button.component.scss'],
})
export class ReturnCancelButtonComponent {
  @Input() hostedReturnConfig!: HostedReturnsConfig;
  @Input() rmaId!: string;
  @Input() status!: string;
  @Input() returnOrder!: ReturnOrder | null;
  @Input() urlEntities!: any;
  @Input() summaryView!: boolean;
  @Input() multiSkuSummary!: boolean;
  @Input() isListingPage = false;
  @Output() renderListingPage = new EventEmitter();

  loader = false;
  cancelModalRef!: NgbModalRef;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private store: Store,
    private returnService: ReturnService,
  ) { }

  openCancelModal(content: TemplateRef<any>, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.cancelModalRef = this.modalService.open(content, {
      centered: true,
      keyboard: false,
      modalDialogClass: 'cancel-modal-width',
    });
  }

  closeCancelModal() {
    if (this.cancelModalRef) {
      this.cancelModalRef.close();
    }
  }

  redirectToItemList() {
    const orderId = encodeURIComponent(
      btoa(`${this.urlEntities?.orderExternalId}`)
    );
    const urlParamsLink = `/${this.urlEntities?.tenantId}/${this.urlEntities?.brandExternalId}/${orderId}`;
    this.router.navigate([`/${urlParamsLink}/main/itemlist`]);
  }

  cancelReturn() {
    this.loader = true;
    if (this.rmaId) {
      this.returnService.cancelReturnRequest(this.rmaId).subscribe({
        next: resp => {
          this.loader = false;
          this.store.dispatch(
            showToastSuccess({
              showToast: true,
              successMsg: 'Return has been cancelled',
              close: true,
              timeoutMs: 3200,
            })
          );
          this.closeCancelModal();
          if (this.isListingPage) {
            this.renderListingPage.emit(true);
          } else {
            this.redirectToItemList();
          }
        },
        error: error => {
          this.loader = false;
          this.store.dispatch(
            showToastError({
              showToast: true,
              errorMsg: 'Return cannot be cancelled',
              close: true,
              timeoutMs: 3200,
            })
          );
          this.closeCancelModal();
        },
      });
    }
  }
}
