import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReturnSmartWindowsState } from '@store/states/return-smart-windows.state';

export const RETURN_SMART_WINDOWS_STATE = 'returnSmartWindows';

const selectReturnSmartWindowsState =
  createFeatureSelector<ReturnSmartWindowsState>(RETURN_SMART_WINDOWS_STATE);

export const selectReturnSmartWindows = createSelector(
  selectReturnSmartWindowsState,
  (state: ReturnSmartWindowsState) => {
    return state?.smartWindows;
  }
);
