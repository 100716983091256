<div class="d-flex user-select-none flex-wrap item-attribute-type-group">
	<ng-container *ngFor="let option of attribute.options">
		<ng-container [ngSwitch]="attribute.type">
			<ng-container *ngSwitchCase="'text'">
				<div class="d-flex justify-content-center align-items-center item-attribute-type"
					(click)="selectAttributeType(option)" (keydown)="selectAttributeType(option)" tabindex="0"
					role="button" [ngClass]="{
						'item-attribute-type-selected': option.name === attribute.selectedOption,
						'item-attribute-type-unavailable': !option.available,
					}">
					<span class="align-content-center item-attribute-type-text">
						{{option.name | titlecase}}
					</span>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="'image'">
				<div class="d-flex justify-content-center align-items-center item-attribute-type item-attribute-type-image"
					(click)="selectAttributeType(option)" (keydown)="selectAttributeType(option)" tabindex="0"
					ngbTooltip="{{option.value | titlecase}}" placement="bottom"
					[tooltipClass]="'item-attribute-type-tooltip'" role="button" [ngClass]="{
						'item-attribute-type-selected': option.value === attribute.selectedOption,
						'item-attribute-type-unavailable': !option.available,
					}">
					<img [ngSrc]="option.name || '/assets/icons/package-item-placeholder.svg'"
						alt="{{attribute.name | titlecase}}" width="50" height="50" placeholder />
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
</div>