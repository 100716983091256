import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SuccessState } from '@store/states/success.state';

export const SUCCESS_STATE = 'success';

const getSuccessState = createFeatureSelector<SuccessState>(SUCCESS_STATE);

export const getSuccessResponse = createSelector(
  getSuccessState,
  (state: SuccessState) => {
    return {
      showToast: state?.showToast,
      successMsg: state?.successMsg,
      close: state?.close,
      data: state?.data,
      timeoutMs: state?.timeoutMs,
    };
  }
);
