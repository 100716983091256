<app-header [header]="hostedReturnConfig?.components?.header!"
	[businessLogos]="hostedReturnConfig?.branding?.businessLogos!" [isBackgroundWhite]="isNavBackgroundWhite"
	(headerHeight)="getHeightOfHeader($event)"></app-header>
<div class="ex-spacer" [ngStyle]="{'margin-bottom': spacer}"></div>
<div class="w-100" id="main-component">
	<div class="error-toast col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 p-3 mx-auto max-764px position-sticky"
		[ngStyle]="{'top':spacer}" *ngIf="errorData?.showToast && !errorData?.close">
		<div class="w-100 h-100 d-flex align-items-center">
			<span translate [translateParams]="errorData.data" class="w-100 error-text">{{errorData.errorMsg}}</span>
		</div>
	</div>

	<div #errorMessage
		class="d-flex align-items-center justify-content-center position-fixed w-100 col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 p-2 mx-auto  error-toast-container slide-in"
		[ngStyle]="{'top':spacer}" *ngIf="errorData?.showToast && errorData?.close">
		<div class="error-toast d-flex gap-2 w-100 h-100 align-items-center justify-content-between max-764px">
			<div class="d-flex gap-2 w-100 align-items-center text-center">
				<span translate [translateParams]="errorData.data"
					class="w-100 error-text">{{errorData.errorMsg}}</span>
			</div>
			<div>
				<i-feather (click)="closeToast()" name="x"
					class="cursor-pointer d-flex align-items-center justify-content-center icon"></i-feather>
			</div>
		</div>
	</div>

	<div #successMessage
		class="d-flex align-items-center justify-content-center position-fixed w-100 col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 p-2 mx-auto success-toast-container slide-in"
		[ngStyle]="{'top':spacer}" *ngIf="successData?.showToast && successData?.close">
		<div class="success-toast d-flex gap-2 w-100 h-100 align-items-center justify-content-between max-764px">
			<div class="d-flex gap-2 w-100 align-items-center text-center">
				<span translate [translateParams]="successData.data"
					class="w-100 success-text">{{successData.successMsg}}</span>
			</div>
			<div>
				<i-feather (click)="closeSuccessToast()" name="x"
					class="cursor-pointer d-flex align-items-center justify-content-center icon"></i-feather>
			</div>
		</div>
	</div>

	<!-- ds cookie policy consent -->
	<ng-container *ngIf="hostedReturnConfig?.brandExternalId">
		<ds-cookie-consent [brandExternalId]="hostedReturnConfig.brandExternalId"></ds-cookie-consent>
	</ng-container>
	<router-outlet></router-outlet>
</div>