import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { Subscription, timer } from 'rxjs';
import { BaseComponent } from '@shared/components/base.component';
import { ReturnsConfigService } from '@core/services/returns-config.service';
import { ItemListService } from '@core/services/itemlist.service';
import { StorageService } from '@core/services/storage.service';
import { getConfig } from '@store/selectors/config.selector';
import { Auth } from '@core/models/auth';
import { HostedReturnsConfig } from '@core/models/returns-config.interface';
import { Item } from '@core/models/item';
import { selectHeaderSpacerState } from '@store/selectors/header-spacer.selector';
import { HeaderSpacerState } from '@store/states/header-spacer.state';
import { getAllSelectedItems } from '@store/selectors/selected-item-list.selector';
import { SelectedItemDetails } from '@store/states/selected-item-list.state';
import {
  deleteSelectedItems,
  deleteSelectedOrderDetails,
} from '@store/actions/selected-item-list.action';
import { getSanitisedOrderId } from '@store/selectors/auth.selector';
import { ReturnRequest } from '@core/models/return-request';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  nonReturnableItemList: Item[] = [];
  returnableItemList: Item[] = [];
  returnedItemList: Item[] = [];
  returnRequestOfReturnedItems: ReturnRequest[] = [];
  hostedReturnConfig!: HostedReturnsConfig;
  public hiddenFields: { [key: string]: boolean } = {};
  configSubscription!: Subscription;
  spacerSubscription!: Subscription;
  dataSubscription!: Subscription;
  itemListSubscription!: Subscription;
  authResponseSubscription!: Subscription;
  selectedItemSubscription!: Subscription;
  placeholderData = [1, 2, 3, 4];
  @ViewChild('topBannerDiv') topBannerRef!: ElementRef;
  private resizeObserver!: ResizeObserver;
  spacer = '176px';
  headerSpacer = '65px';
  showPlaceholder = false;
  isCollapsedReturnableItemList = false;
  isCollapsedNonReturnableItemList = false;
  isCollapsedReturnedItemList = false;
  entities: any = null;
  selectedItemDetails!: Record<string, SelectedItemDetails[]>;
  selectedItemCount = 0;
  detailsSubscription!: Subscription;
  sanitizedOrderIdDetails!: { orderId: string; sanitizedOrderId: string };
  urlParamsLink = '';
  isInvalidPhone = false;
  invalidPhoneNumber = '';
  invoiceIds: string[] = [];
  applyShakeEffect = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private configService: ReturnsConfigService,
    public store: Store,
    private router: Router,
    private itemListService: ItemListService,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef,
    hostedReturnService: ReturnsConfigService,
  ) {
    super({ hostedReturnService });
  }

  ngOnInit(): void {
    this.dataSubscription = this.activatedRoute.data.subscribe(data => {
      if (data['configResp'] === undefined) {
        this.router.navigate(['/service-unavailable'], {
          skipLocationChange: true,
        });
        return;
      }
      this.init();
    });
  }

  ngAfterViewInit(): void {
    this.updateTopBannerView()
    this.resizeObserver = new ResizeObserver(_ => {
      const spacer = parseFloat(this.headerSpacer.split('px')[0]);
      this.spacer = spacer + this.topBannerRef.nativeElement.offsetHeight + 'px';
      this.cdr.detectChanges()
    });

    this.resizeObserver.observe(this.topBannerRef.nativeElement);
  }

  init() {
    this.entities = this.getTenantAndBrandExternalId();
    const orderId = encodeURIComponent(btoa(`${this.entities?.orderExternalId}`));
    this.urlParamsLink = `/${this.entities?.tenantId}/${this.entities?.brandExternalId}/${orderId}`;
    this.configSubscription = this.store
      .select(getConfig)
      .pipe()
      .subscribe(config => {
        this.hostedReturnConfig = config;
        if (this.hostedReturnConfig) {
          this.hiddenFields = this.configService.setHiddenFields(
            this.hostedReturnConfig
          );
        }
      });

    this.detailsSubscription = this.store
      .select(getSanitisedOrderId)
      .pipe()
      .subscribe(data => {
        this.sanitizedOrderIdDetails = data ? data : { orderId: '', sanitizedOrderId: '' };
      });

    this.selectedItemSubscription = this.store
      .select(getAllSelectedItems)
      .pipe()
      .subscribe(selectedItems => {
        this.selectedItemDetails = selectedItems;
      });

    this.fetchItemList();
  }

  updateTopBannerView() {
    if (this.spacerSubscription) {
      this.spacerSubscription.unsubscribe();
    }
    this.spacerSubscription = this.store
      .select(selectHeaderSpacerState)
      .pipe()
      .subscribe((data: HeaderSpacerState) => {
        const headerSpacer = data?.headerSpacer;
        if (headerSpacer) {
          this.headerSpacer = headerSpacer;
        }
      });
  }

  fetchItemList() {
    this.showPlaceholder = true;
    this.itemListSubscription = this.itemListService.getItemList().subscribe({
      next: resp => {
        this.storageService.set('authResp', resp);
        this.showPlaceholder = false;
        if (!resp) {
          this.router.navigate([
            `/${this.entities.tenantId}/${this.entities.brandExternalId}/login`,
          ]);
        }
        if (!this.hostedReturnConfig.componentVisibility?.multiSku?.enable) {
          this.store.dispatch(
            deleteSelectedOrderDetails({
              orderExternalId: this.sanitizedOrderIdDetails.sanitizedOrderId
            })
          );
        }
        this.setData(resp);

        if (NullEmptyChecker.isNonEmptyArray(resp.invalidPhoneNumbers)) {
          this.isInvalidPhone = true;
          this.invalidPhoneNumber = resp.invalidPhoneNumbers[0];
          this.invoiceIds = resp.invoiceIds;
        }
      },
      error: error => {
        this.showPlaceholder = false;
      },
    });
  }

  groupedItemsInSequence(itemList: Item[]) {
    const itemListSorted = itemList
      .map((item: Item) => {
        let sortIndex;
        if (item.returnEligibility) {
          sortIndex = 0;
        } else if (
          !item.returnEligibility &&
          NullEmptyChecker.isNonEmptyArray(item.returnRequest)
        ) {
          sortIndex = 1;
        } else if (
          !item.returnEligibility &&
          !NullEmptyChecker.isNonEmptyArray(item.returnRequest)
        ) {
          sortIndex = 2;
        }
        return { ...item, sortIndex };
      })
      .sort((a: Item, b: Item) => {
        return a.sortIndex! - b.sortIndex!;
      });

    const returnable = itemListSorted
      .filter(
        (item: Item) =>
          item.returnEligibility &&
          !NullEmptyChecker.isDeepNull(item, 'remainingQuantity') &&
          item.remainingQuantity! > 0
      )
      .map(item => {
        const itemQuantity = item?.remainingQuantity || 0;
        return { ...item, itemQuantity };
      });
    const returned = itemListSorted
      .filter((item: Item) => {
        const partialReturn =
          !NullEmptyChecker.isDeepNull(item, 'remainingQuantity') &&
          item.remainingQuantity! < item.quantity &&
          item?.returnRequest?.length;

        return (
          (!item.returnEligibility && item.returnRequest?.length) ||
          partialReturn
        );
      })
      .map(item => {
        const itemQuantity = item.quantity - (item?.remainingQuantity || 0);
        return { ...item, itemQuantity };
      });

    const nonReturnable = itemListSorted
      .filter(
        (item: Item) => !item.returnEligibility && item.remainingQuantity! > 0
      )
      .map(item => {
        const itemQuantity = item.quantity;
        return { ...item, itemQuantity };
      });

    return { returnable, returned, nonReturnable };
  }

  setData(resp: Auth) {
    const { itemList } = resp;
    const { returnable, returned, nonReturnable } =
      this.groupedItemsInSequence(itemList);

    if (!NullEmptyChecker.isNonEmptyArray(returnable)) {
      this.store.dispatch(
        deleteSelectedOrderDetails({
          orderExternalId: resp.invoiceInfo.orderExternalId,
        })
      );
    }

    if (
      NullEmptyChecker.isNonEmptyArray(
        this.selectedItemDetails[this.sanitizedOrderIdDetails.sanitizedOrderId]
      )
    ) {
      const selectedItemRefIds = this.selectedItemDetails[
        this.sanitizedOrderIdDetails.sanitizedOrderId
      ]?.map(item => item.__refId);

      for (let index = 0; index < returnable.length; index++) {
        if (selectedItemRefIds?.includes(`${returnable[index].__refId}`)) {
          returnable[index].isSelected = true;
        }
        if (index > 0) {
          returnable[index].isPreviousSelected = selectedItemRefIds?.includes(
            `${returnable[index - 1].__refId}`
          );
        } else if (index === 0) {
          returnable[index].isPreviousSelected = false;
        }
      }
      this.selectedItemCount =
        this.selectedItemDetails[
          this.sanitizedOrderIdDetails.sanitizedOrderId
        ].length;
    }
    this.returnableItemList = returnable;
    this.returnedItemList = returned;
    const returnRequestOfReturnedItems: ReturnRequest[] = [];
    for (const returnedItem of this.returnedItemList) {
      returnRequestOfReturnedItems.push(
        ...(returnedItem.returnRequest?.map(rr => {
          const obj = { ...rr };
          const date = moment(rr.createdAt);
          obj.dateToShow = date.format('MMMM DD, YYYY');
          return obj;
        }) ?? [])
      );
    }
    this.returnRequestOfReturnedItems = returnRequestOfReturnedItems.filter((req, index) => {
      return index === returnRequestOfReturnedItems.findIndex(rr => rr.rmaId === req.rmaId);
    }).sort(
      (req1, req2) =>
        new Date(req2.createdAt!).getTime() -
        new Date(req1.createdAt!).getTime()
    );
    this.nonReturnableItemList = nonReturnable;
  }

  goToDetailsPage(rmaId: string) {
    const returnRequest = this.returnRequestOfReturnedItems.find(
      request => request.rmaId == rmaId
    );
    if (
      !NullEmptyChecker.isNull(returnRequest) &&
      NullEmptyChecker.isNonEmptyArray(returnRequest?.itemList) &&
      returnRequest!.itemList!.length > 1
    ) {
      this.router.navigate([
        `/${this.urlParamsLink}/main/return-summary-details/${rmaId}`,
      ]);
    } else {
      this.router.navigate([
        `/${this.urlParamsLink}/main/request-placed/return-order-details/${rmaId}`,
      ]);
    }
  }

  deleteStoreData(unselectedItem: {
    selectedItemRefIds: string[];
    orderExternalId: string;
  }) {
    this.store.dispatch(deleteSelectedItems(unselectedItem));

    const unSelectedItemIndex = this.returnableItemList.findIndex(item =>
      unselectedItem.selectedItemRefIds.includes(`${item.__refId}`)
    );
    this.returnableItemList[unSelectedItemIndex].isSelected = false;
    if (this.returnableItemList.length > unSelectedItemIndex + 1) {
      this.returnableItemList[unSelectedItemIndex + 1].isPreviousSelected =
        false;
    }
    this.selectedItemCount =
      this.selectedItemDetails[
        this.sanitizedOrderIdDetails.sanitizedOrderId
      ].length;
  }

  navToReturnPreviewPage() {
    const urlEntities = this.getTenantAndBrandExternalId();
    const orderId = encodeURIComponent(btoa(`${urlEntities?.orderExternalId}`));
    const urlParamsLink = `/${urlEntities?.tenantId}/${urlEntities?.brandExternalId}/${orderId}`;

    this.router.navigate([`/${urlParamsLink}/main/return-preview`]);
  }

  initiateReturn(selectedItem: { returnsUrl: string }): void {
    if (this.isInvalidPhone) {
      this.applyShakeEffect = true;
      timer(700).subscribe(() => {
        this.applyShakeEffect = false;
      });
    } else {
      this.router.navigate([selectedItem.returnsUrl]);
    }
  }

  displayInvalidPhoneMessage(val: boolean): void {
    this.isInvalidPhone = val;
    return;
  }

  ngOnDestroy(): void {
    if (this.configSubscription) this.configSubscription.unsubscribe();
    if (this.spacerSubscription) this.spacerSubscription.unsubscribe();
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
    if (this.itemListSubscription) this.itemListSubscription.unsubscribe();
    if (this.authResponseSubscription)
      this.authResponseSubscription.unsubscribe();
    if (this.selectedItemSubscription)
      this.selectedItemSubscription.unsubscribe();
    if (this.detailsSubscription) this.detailsSubscription.unsubscribe();
  }
}
