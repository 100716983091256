<div class="multi-sku-footer position-fixed bg-white w-100">
    <div class="multi-sku-action-area row mx-auto max-764px justify-content-between align-items-center"
        [ngClass]="{'flex-md-row':mobileView,'flex-column':mobileView, 'multi-sku-action-area-mobile': mobileView}">
        <div class="col-6 px-0 d-flex align-items-center left-section"
            [ngClass]="{'justify-content-md-start':mobileView,'justify-content-center':mobileView, 'left-section-mobile': mobileView, 'justify-content-start':!mobileView}">
            <button class="cancel-button user-select-none" [routerLink]="[secondaryButtonLink]" *ngIf="secondaryButton"
                [captureEvent]="'click-cancel-button'">
                {{secondaryButtonText}}
            </button>
            <div class="info-wrapper">
                <div class="d-flex gap-1 info" [ngClass]="{'mb-6': subInfo}">
                    {{info}}
                    <div class="skeleton-wrapper" *ngIf="returnFeeLoader">
                        <app-skeleton-loader></app-skeleton-loader>
                    </div>
                    <ng-container *ngIf="!returnFeeLoader">
                        {{estimatedReturnFee}}
                    </ng-container>
                </div>
                <div class="sub-info-block d-flex align-items-center" *ngIf="subInfo">
                    <span>
                        <i-feather class="d-flex sub-info-icon" *ngIf="subInfoIcon" [name]="subInfoIcon"></i-feather>
                    </span>
                    <span class="sub-info">{{subInfo}}</span>
                </div>
            </div>
        </div>
        <div class="col-6 px-0 d-flex align-items-center right-section"
            [ngClass]="{'justify-content-md-end':mobileView,'justify-content-center':mobileView, 'right-section-mobile': mobileView, 'justify-content-end': !mobileView}">
            <button *ngIf="primaryButton" class="return-button user-select-none"
                [ngClass]="{'return-button-mobile':mobileView}" (click)="onPrimaryButtonClick()" [disabled]="disabled"
                [captureEvent]="'click-'+primaryButtonText+'-button'">
                <ng-container *ngIf="!showLoader">
                    {{primaryButtonText}}
                    <i-feather *ngIf="primaryButtonIcon" [name]="primaryButtonIcon"></i-feather>
                </ng-container>
                <app-bubble-loader *ngIf="showLoader"></app-bubble-loader>
            </button>
        </div>
    </div>
</div>