import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Item } from '@core/models/item';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { Store } from '@ngrx/store';
import { ReturnsConfigService } from '@services/returns-config.service';
import { ItemAttributeType, ItemAttributeTypeOption } from '@shared/components/item-attribute-type/item-attribute-type.component';
import { getConfig } from '@store/selectors/config.selector';
import { Subscription } from 'rxjs';


export type VariantOptions = {
  [key: string]: string[];
}

export type Attributes = {
  [key: string]: string;
}

@Component({
  selector: 'app-exchange-item',
  templateUrl: './exchange-item.component.html',
  styleUrls: ['./exchange-item.component.scss'],
})
export class ExchangeItemComponent implements OnInit, OnDestroy {
  @Input() exchangeItem!: Item
  @Input() attributes!: ItemAttributeType[];
  @Input() allAvailableAttributes: Attributes[] = [];
  @Input() allVariantOptions: VariantOptions = {};
  @Output() bySetAvailableAttribute = new EventEmitter();
  @Output() byClose = new EventEmitter();

  configSubscription!: Subscription;
  hiddenFields = {}
  selectedAttributes: Attributes = {}
  itemsToShow = 1;
  selectedExchangeAttribute = false;

  constructor(
    private hostedReturnService: ReturnsConfigService,
    private store: Store
  ) { }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    if (this.configSubscription) this.configSubscription.unsubscribe();
  }

  init() {
    this.validateSaveExchangeItem()

    for (const attribute of this.attributes) {
      if (attribute.selectedOption) {
        this.selectedAttributes[attribute.key] = attribute.selectedOption
      }
    }

    this.configSubscription = this.store
      .select(getConfig)
      .pipe()
      .subscribe(config => {
        if (config) {
          this.hiddenFields = this.hostedReturnService.setHiddenFields(config);
        }
      });

    if (!NullEmptyChecker.isDeepNull(this.exchangeItem, 'itemAttributes')) {
      const itemAttributes = this.exchangeItem.itemAttributes!;
      for (const key of Object.keys(itemAttributes)) {
        const attribute = this.attributes.find(attribute => attribute.key === key)!;
        attribute.selectedOption = this.selectedAttributes[key] ?? itemAttributes[key];
        this.selectAttributeType(attribute, false);
      }
    } else {
      this.toggleExchangeItemsToShow(true);
    }
  }

  markAvailability = (
    variantOptions: VariantOptions,
    availableAttribute: Attributes[],
    selectedAttributes: Attributes,
    selectedAttributeKey: string,
  ): ItemAttributeType[] => {
    const itemAttributeTypes: ItemAttributeType[] = [...this.attributes];
    for (const key in variantOptions) {
      const selectedAttributesDel = { ...selectedAttributes };
      delete selectedAttributesDel[key];

      let options: ItemAttributeTypeOption[] = variantOptions[key].map(
        (name: string) => {
          const currentOption = itemAttributeTypes.find(itemAttributeType => itemAttributeType.key === key)?.options.find(option => option.value === name);
          return {
            name: currentOption?.name ?? name,
            available: false,
            value: currentOption?.value ?? name
          }
        }
      );

      availableAttribute.forEach((item: Attributes) => {
        const matchesSelectedAttributes = Object.entries(
          selectedAttributes
        ).every(([attrKey, attrValue]) => item[attrKey] === attrValue);

        if (matchesSelectedAttributes) {
          const index = options.findIndex((option) => option.name === item[key] || option.value === item[key]);
          if (index !== -1) {
            options[index].available = true;
          }
        }
      });

      const isCurrentSelectedAttribute = selectedAttributeKey === key;

      if (isCurrentSelectedAttribute) {
        const currentOption = itemAttributeTypes.find(
          (attr) => attr.key === key
        )?.options;

        options = currentOption || options;
      }


      const selectedOption = options.find(option => (option.name === selectedAttributes[key] || option.value === selectedAttributes[key]) && option.available);

      for (const [index, value] of itemAttributeTypes.entries()) {
        if (value.key === key) {
          if (value.selectedOption) {
            options = options.map((option: ItemAttributeTypeOption) => ({
              ...option,
              available: false,
            }));

            availableAttribute.forEach((item: Attributes) => {
              const matchesSelectedAttributes = Object.entries(
                selectedAttributesDel
              ).every(([attrKey, attrValue]) => item[attrKey] === attrValue);

              if (matchesSelectedAttributes) {
                const index = options.findIndex((option) => option.name === item[key] || option.value === item[key]);
                if (index !== -1) {
                  options[index].available = true;
                }
              }
            });
          }

          itemAttributeTypes[index] = {
            key,
            name: value.name,
            type: value.type,
            options,
            selectedOption: selectedOption && selectedAttributes[key] || '',
            ...(value.mappedKey && { mappedKey: value.mappedKey })
          };
        }
      }
    }
    return itemAttributeTypes;
  };

  selectAttributeType(selectedAttribute: ItemAttributeType, selectOption = true) {
    const attributeIndex = this.attributes.findIndex(attribute => attribute.key === selectedAttribute.key);

    if (attributeIndex > -1) {
      this.attributes[attributeIndex] = selectedAttribute;
    }

    if (selectOption) {
      this.selectedAttributes[selectedAttribute.key] = selectedAttribute.selectedOption;
    }

    this.validateSaveExchangeItem();

    if (selectedAttribute.selectedOption || !selectOption) {
      this.attributes = this.markAvailability(this.allVariantOptions, this.allAvailableAttributes, this.selectedAttributes, selectedAttribute.key);
    }

  }

  toggleExchangeItemsToShow(showAllItem: boolean) {
    this.itemsToShow = showAllItem ? this.attributes.length : 1;

    this.validateSaveExchangeItem()
  }

  validateSaveExchangeItem() {
    if (this.itemsToShow > 1)
      this.selectedExchangeAttribute = this.attributes.every(attribute => this.selectedAttributes[attribute.key] && attribute.selectedOption);
    else
      this.selectedExchangeAttribute = this.attributes.some(attribute => this.selectedAttributes[attribute.key] && attribute.selectedOption);
  }

  saveExchangeItem() {
    this.byClose.emit({ attributes: this.attributes, selectedAttributes: this.selectedAttributes });
  }

  skipExchangeItem() {
    const attributes: ItemAttributeType[] = this.attributes.map(attribute => {
      return {
        ...attribute,
        selectedOption: '',
        options: attribute.options.map(option => ({ ...option, available: true }))
      };
    });

    this.byClose.emit({ attributes, selectedAttributes: {} });
  }
}
