import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Subscription, combineLatest, switchMap } from 'rxjs';
import { NullEmptyChecker } from '@deliverysolutions/utils';

import { Item } from '@core/models/item';
import { ReturnRequest } from '@core/models/return-request';
import { ReturnService } from '@features/returns/services/returns.service';
import { ReturnsConfigService } from '@services/returns-config.service';
import { BaseComponent } from '@shared/components/base.component';
import { getItemById, getOrderIdAndUser } from '@store/selectors/auth.selector';
import { getConfig } from '@store/selectors/config.selector';

@Component({
  selector: 'app-return-details',
  templateUrl: './return-details.component.html',
  styleUrls: ['./return-details.component.scss'],
})
export class ReturnDetailsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  itemOb!: Item;
  dataSubscription!: Subscription;
  itemSubscription!: Subscription;
  public hiddenFields: { [key: string]: boolean } = {};
  itemUniqueId = '';
  returnRequests!: ReturnRequest[];
  placeholderData = [1, 2, 3, 4];
  showPlaceHolderLoader = false;
  returnRequestsSubscription!: Subscription;
  urlEntities: any;
  urlParamsLink = '';
  itemOrderExternalId = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private configService: ReturnsConfigService,
    private returnService: ReturnService
  ) {
    super({ hostedReturnService: configService });
  }

  ngOnInit(): void {
    this.dataSubscription = combineLatest([
      this.activatedRoute.data,
      this.activatedRoute.paramMap,
    ]).subscribe(([data, params]) => {
      if (data['configResp'] === undefined) {
        this.router.navigate(['/service-unavailable'], {
          skipLocationChange: true,
        });
        return;
      }
      this.itemUniqueId = params.get('id') ? params.get('id') + '' : '';
      this.itemOrderExternalId = params.get('orderExternalId') || '';
      this.init();
    });
  }

  init() {
    this.urlEntities = this.getTenantAndBrandExternalId();
    const orderId = encodeURIComponent(
      btoa(`${this.urlEntities?.orderExternalId}`)
    );
    this.urlParamsLink = `/${this.urlEntities?.tenantId}/${this.urlEntities?.brandExternalId}/${orderId}`;
    this.store
      .select(getConfig)
      .pipe()
      .subscribe(config => {
        if (config) {
          this.hiddenFields = this.configService.setHiddenFields(config);
        }
      });
    if (this.itemUniqueId) this.getItem();
    this.fetchReturnRequsts();
  }

  getItem() {
    this.itemSubscription = this.store
      .select(getItemById(this.itemUniqueId, this.itemOrderExternalId))
      .pipe()
      .subscribe((item: Item) => {
        this.itemOb = JSON.parse(JSON.stringify(item));
        this.itemOb.itemQuantity =
          item.quantity - (item?.remainingQuantity || 0);
      });
  }

  goToDetailsPage(rmaId: string) {
    const returnRequest = this.returnRequests.find(
      request => request.rmaId == rmaId
    );
    if (
      !NullEmptyChecker.isNull(returnRequest) &&
      NullEmptyChecker.isNonEmptyArray(returnRequest?.itemList) &&
      returnRequest!.itemList!.length > 1
    ) {
      this.router.navigate([
        `/${this.urlParamsLink}/main/return-summary-details/${rmaId}`,
      ]);
    } else {
      this.router.navigate([
        `/${this.urlParamsLink}/main/request-placed/return-order-details/${rmaId}`,
      ]);
    }
  }

  fetchReturnRequsts() {
    this.showPlaceHolderLoader = true;
    const returnRequestObservable = this.store.select(getOrderIdAndUser).pipe(
      switchMap(data => {
        const req: any = {
          orderBeingReturned: this.itemOb?.orderExternalId || data?.orderId,
        };
        if (this.itemUniqueId) req.sku = this.itemUniqueId;
        return this.returnService.getReturnRequestList(req);
      })
    );
    this.returnRequestsSubscription = returnRequestObservable.subscribe({
      next: (resp: any) => {
        this.showPlaceHolderLoader = false;
        this.returnRequests = resp.data;
        for (const rr of this.returnRequests) {
          const date = moment(rr.createdAt);
          rr.dateToShow = date.format('on MMMM DD, YYYY');
        }
      },
      error: () => {
        this.showPlaceHolderLoader = false;
      },
    });
  }

  goToItemlist() {
    this.router.navigate([`/${this.urlParamsLink}/main/itemlist`]);
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
    if (this.itemSubscription) this.itemSubscription.unsubscribe();
    if (this.returnRequestsSubscription)
      this.returnRequestsSubscription.unsubscribe();
  }
}
