import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuard } from '@core/guards/auth.guard';
import { returnsConfingResolver } from '@app/resolvers/returns-config.resolver';

import { ReturnsRootComponent } from './returns-root.component';
import { ItemListComponent } from './pages/item-list/item-list.component';
import { ReturnsComponent } from './pages/returns/returns.component';
import { ReturnDetailsComponent } from './pages/return-details/return-details.component';
import { ReturnRequestPlacedComponent } from './pages/return-request-placed/return-request-placed.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { StatusLoadingComponent } from './pages/status-loading/status-loading.component';
import { ReturnPreviewComponent } from './pages/return-preview/return-preview.component';
import { ReturnSummaryComponent } from './pages/return-summary/return-summary.component';
import { ReturnSummaryDetailsComponent } from './components/return-summary-details/return-summary-details.component';
import { CommonErrorPageComponent } from '@pages/common-error-page/common-error-page.component';

const routes: Routes = [
  {
    path: 'common-error-page',
    component: ReturnsRootComponent,
    resolve: {
      configResp: returnsConfingResolver,
    },
    children: [
      {
        path: '',
        component: CommonErrorPageComponent,
      },
    ]
  },
  {
    path: '',
    component: ReturnsRootComponent,
    resolve: {
      configResp: returnsConfingResolver,
    },
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'itemlist',
        pathMatch: 'full',
      },
      {
        path: 'itemlist',
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivateChild: [authGuard],
        component: ItemListComponent,
      },
      {
        path: 'request-status/:rmaId',
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivateChild: [authGuard],
        component: StatusLoadingComponent,
      },
      {
        path: 'request-status',
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivateChild: [authGuard],
        component: StatusLoadingComponent,
      },
      {
        path: 'returns/:id/:orderExternalId',
        component: ReturnsComponent,
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivate: [authGuard],
      },
      {
        path: 'returns',
        component: ReturnsComponent,
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivate: [authGuard],
      },
      {
        path: 'return-preview',
        component: ReturnPreviewComponent,
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivate: [authGuard],
      },
      {
        path: 'return-summary',
        component: ReturnSummaryComponent,
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivate: [authGuard],
      },
      {
        path: 'return-summary-details/:rmaId',
        component: ReturnSummaryDetailsComponent,
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivate: [authGuard],
      },
      {
        path: 'return-details/:id/:orderExternalId',
        component: ReturnDetailsComponent,
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivate: [authGuard],
      },
      {
        path: 'return-details',
        component: ReturnDetailsComponent,
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivate: [authGuard],
      },
      {
        path: 'request-placed/:status/:rmaId',
        component: ReturnRequestPlacedComponent,
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivate: [authGuard],
      },
      {
        path: 'request-placed/:status',
        component: ReturnRequestPlacedComponent,
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivate: [authGuard],
      },
      {
        path: 'error-page/:errorCode',
        component: ErrorPageComponent,
        resolve: {
          configResp: returnsConfingResolver,
        },
        canActivate: [authGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReturnsRootRoutingModule {}
