import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getOrderIdAndUser } from '@store/selectors/auth.selector';

@Component({
  selector: 'app-sidenav-menu-header',
  templateUrl: './sidenav-menu-header.component.html',
  styleUrls: ['./sidenav-menu-header.component.scss'],
})
export class SidenavMenuHeaderComponent implements OnInit, OnDestroy {
  @Input() logo!: string;
  @Input() width!: string;

  @Output() byClose = new EventEmitter();

  detailsSubscription!: Subscription;
  details!: any;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.detailsSubscription = this.store
      .select(getOrderIdAndUser)
      .pipe()
      .subscribe(data => {
        this.details = data;
      });
  }

  closeMenu() {
    const ob: any = {};
    this.byClose.emit(ob);
  }

  ngOnDestroy(): void {
    if (this.detailsSubscription) this.detailsSubscription.unsubscribe();
  }
}
