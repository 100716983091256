<div class="dropdown user-select-none" [class.dropdown-on-top]="isOpen">
    <button (click)="!isDisabled ? toggleDropdown($event) : false"
        class="w-100 d-flex align-items-center justify-content-between rounded border ps-0"
        [class.dropdown-disabled]="isDisabled">
        <div class="align-items-center d-flex justify-content-between dropdown-select">
            <div class="d-flex align-items-center justify-content-center rounded dropdown-prefix"
                *ngIf="config?.prefix && !isDisabled">
                <span *ngIf="config?.prefix === 'string'">{{selectedOption?.prefix || config.prefixDefault}}</span>
                <span *ngIf="config?.prefix === 'currency'">
                    {{
                    selectedOption?.fee ? (selectedOption?.fee! / 100 | currency: 'USD' :'symbol':'1.2-2') :
                    selectedOption?.fee === 0 ? 'Free' : config.prefixDefault
                    }}
                </span>
            </div>
            <span class=" ms-2 me-1 text-start dropdown-title">
                {{selectedOption?.title || defaultSelectedOption || 'Select'}}
            </span>
        </div>
        <div class="d-flex justify-content-end align-items-center position-relative">
            <!-- HostListener method onPageClick is not returning  
                clickedInside true for <i-feature> hence added overlay -->
            <div class="dropdown-icon-overlay position-absolute w-100">
            </div>
            <i-feather class="cursor-pointer dropdown-icon" [name]="isOpen ? 'chevron-up' : 'chevron-down'"></i-feather>
        </div>
    </button>
    <div class="w-100 rounded border dropdown-content" [class.d-block]="isOpen" *ngIf="!isDisabled">
        <ul>
            <li class="d-flex flex-column" *ngFor="let option of options" (click)="onSelect(option.data)">
                <span class="fw-normal">
                    {{option.title}}
                </span>
                <span *ngIf=" (option.fee | typeof)==='number'" class="font-weight-500">
                    {{option.fee !== 0 ? 'Fee: ' + (option.fee! / 100 | currency: 'USD' :'symbol':'1.2-2') : 'Free'}}
                </span>
            </li>
        </ul>
    </div>
</div>