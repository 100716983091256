import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpClientModule,
} from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { metaReducers, reducers } from '@store/reducers/index';
import { AppRoutingModule } from './app-routing.module';
import { CoreComponentsModule } from './core/components/core-components.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HeadersInterceptor } from './core/interceptors/http-headers.interceptor';
import { AuthModule } from './features/auth/auth.module';
import { ReturnsRootModule } from './features/returns/returns-root.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AuthModule,
    ReturnsRootModule,
    CoreComponentsModule,
    SharedModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    HttpClientModule,
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

/**
 * A function that creates a TranslateHttpLoader instance for loading translation files.
 * @param {HttpClient} http - The HttpClient instance used for loading translation files.
 * @returns {TranslateHttpLoader} A new instance of TranslateHttpLoader.
 */
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  // cache busting value = year-month-date--number - increment number if multiple changes on same date
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?version=2024-04-05--01'
  );
}
