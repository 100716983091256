import { Component, Input } from '@angular/core';
import { Recommendations } from '@core/models/returns-config.interface';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'ds-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
})
export class RecommendationsComponent {
  @Input() recommendations!: Recommendations[] | [];
  swiperConfig: SwiperOptions = {
    pagination: { clickable: true },
    autoHeight: true,
    injectStyles: [
      `
        .swiper-pagination {
          position: unset !important;
          margin-top: 6px !important;
        }

        .swiper-pagination-bullet-active {
          background: #666 !important;
        }
      `,
    ],
    allowTouchMove: true,
    slidesPerGroup: 1,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
      1010: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
      990: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
      320: {
        slidesPerView: 2,
        slidesPerGroup: 1,
      },
      319: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },
    autoplay: true,
    loop: true,
    rewind: true,
    spaceBetween: 30,
  };
}
