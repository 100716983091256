<div class="menu-header-container">
	<div class="menu-top-bar p-2 d-flex justify-content-between">
		<div><img *ngIf="logo" [src]="logo ? logo : 'assets/images/logo.svg'" /></div>
		<div class="menu-top-bar d-flex align-items-center">
			<i-feather class="close-icon" name="x" (click)="closeMenu()"
				[captureEvent]="'click-close-menu'"></i-feather>
		</div>
	</div>
	<div class="menu-header d-flex" *ngIf="details?.userData">
		<div class="user-box">
			<div class="name">{{details?.userData?.name}}</div>
			<div class="email">{{details?.userData?.email || details?.userData?.phone}}</div>
		</div>
		<!-- <div class="d-flex align-items-center justify-content-end menu-top-bar w-100">
			<i-feather class="close-icon" name="x" (click)="closeMenu()"></i-feather>
		</div> -->
	</div>
</div>