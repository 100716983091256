import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { SharedComponentsModule } from './components/shared-components.module';
import { AutoWidthDirective, ImgFallbackDirective } from './directive';

import { register } from 'swiper/element/bundle';

register();

@NgModule({
  imports: [HttpClientModule, CommonModule, SharedComponentsModule],
  declarations: [AutoWidthDirective, ImgFallbackDirective],
  exports: [
    AutoWidthDirective,
    ImgFallbackDirective,
    CommonModule,
    SharedComponentsModule,
  ],
})
export class SharedModule { }
