import { isDevMode } from '@angular/core';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { configReducer } from './config.reducer';
import { authReducer } from './auth.reducer';
import { errorReducer } from './error.reducer';
import { orderExternalIdReducer } from './order-id.reducer';
import { headerSpacerReducer } from './header-spacer.reducer';
import { CONFIG_STATE } from '@store/selectors/config.selector';
import { AUTH_STATE } from '@store/selectors/auth.selector';
import { ERROR_STATE } from '@store/selectors/error.selector';
import { ORDER_EXTERNAL_ID_STATE } from '@store/selectors/order-id.selector';
import { HEADER_SPACER_STATE } from '@store/selectors/header-spacer.selector';
import { LOGOUT_ACTION } from '@store/actions/auth.action';
import { SELECTED_ITEM_LIST_STATE } from '@store/selectors/selected-item-list.selector';
import { itemListReducer } from './selected-item-list.reducers';
import { localStorageSync } from 'ngrx-store-localstorage';
import { SelectedItemListState } from '@store/states/selected-item-list.state';
import { SUCCESS_STATE } from '@store/selectors/success.selector';
import { successReducer } from '@store/reducers/success.reducer';
import { RETURN_SMART_WINDOWS_STATE } from '@store/selectors/return-smart-windows.selector';
import { returnSmartWindowsReducer } from '@store/reducers/return-smart-windows.reducer';

export interface State { }

export const reducers: ActionReducerMap<State> = {
  [CONFIG_STATE]: configReducer,
  [AUTH_STATE]: authReducer,
  [ERROR_STATE]: errorReducer,
  [SUCCESS_STATE]: successReducer,
  [ORDER_EXTERNAL_ID_STATE]: orderExternalIdReducer,
  [HEADER_SPACER_STATE]: headerSpacerReducer,
  [SELECTED_ITEM_LIST_STATE]: itemListReducer,
  [RETURN_SMART_WINDOWS_STATE]: returnSmartWindowsReducer,
};

// export const metaReducers: MetaReducer<State>[] = isDevMode() ? [] : [];
export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === LOGOUT_ACTION) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export function localStorageSyncReducer(
  reducer: ActionReducer<SelectedItemListState, Action>
): ActionReducer<SelectedItemListState, Action> {
  return localStorageSync({
    keys: [SELECTED_ITEM_LIST_STATE],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: MetaReducer[] = [localStorageSyncReducer];
