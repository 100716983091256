<div id="return-summary-component">

	<div class="main-content-area content-area col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px">

		<ng-container *ngIf="pageStatus === 'error'">
			<div class="summary-error">
				<app-request-status *ngIf="!placeHolderLoader" [status]="pageStatus" [urlParams]="urlEntities"
					[returnRequestDetails]="returnRequestDetails">
				</app-request-status>
			</div>
		</ng-container>

		<ng-container *ngIf="pageStatus !== 'error' && placeHolderLoader">
			<div class="p-3 mb-2 mb-md-3 placeholder-loading bg-white" *ngFor="let arr of placeholderLoaderArr">
				<div class="col-8 col-md-4 data-row mb-1 linear-background"></div>
				<div class="col-6 col-md-3 data-row mb-1 linear-background"></div>
				<div class="col-6 col-md-3 data-row linear-background"></div>
			</div>
		</ng-container>

		<ng-container *ngIf="pageStatus !== 'error' && !placeHolderLoader">

			<app-page-header [showBackButtonIcon]="false" [title]="'Return Summary'"
				[alignment]="'center'"></app-page-header>

			<div class="spacing">
				<ng-container *ngIf="!placeHolderLoader; else loadingItem">
					<app-return-method-card *ngFor="let returnMethodGroup of returnMethodsGroup; index as i"
						[returnMethod]="returnMethodGroup"
						[lastItem]="returnMethodsGroup?.length && returnMethodsGroup!.length-1 === i || false"
						[returnSmartWindowSummaryView]="true" (byPrintLabels)="printLabels($event)"
						(byNavToInstructions)="navToReturnSummaryDetails($event)" (byNavToItemList)="navToItemList()"
						[hideElement]="{'viewDetailsBtn':true, 'titleReturnFee':true, 'changeAddressBtn':true, 'returnLabelBox': true, 'changePickupWindowBtn':true}"
						[index]="i" [returnStatus]="returnMethodGroup.status!"
						[returnOrder]="returnMethodGroup.returnOrder ?? null"
						[urlEntities]="urlEntities"></app-return-method-card>
				</ng-container>
			</div>

			<div class="return-another-item-wrapper d-flex justify-content-center" *ngIf="showReturnAnotherItemLink">
				<app-request-status [status]="pageStatus" [urlParams]="urlEntities"
					[returnRequestDetails]="returnRequestDetails" [returnAnotherItemOption]="showReturnAnotherItemLink"
					[showMessage]="false">
				</app-request-status>
			</div>

		</ng-container>

		<div>
			<div class="mb-2 mb-md-3" *ngIf="primaryAds && primaryAds.active && primaryAds.url">
				<div class="col p-0">
					<app-single-ad [adConfig]="primaryAds" [eventName]="'primary-ads'"></app-single-ad>
				</div>
			</div>

			<ds-recommendations [recommendations]="recommendations" *ngIf="recommendations"></ds-recommendations>

			<div *ngIf="secondaryAdsOne && secondaryAdsOne.active && secondaryAdsOne.url">
				<div class="col p-0">
					<app-single-ad [adConfig]="secondaryAdsOne" [eventName]="'secondary-ads-one'"></app-single-ad>
				</div>
			</div>
			<div class="mb-3" *ngIf="secondaryAdsOne && secondaryAdsTwo"></div>
			<div *ngIf="secondaryAdsTwo && secondaryAdsTwo.active && secondaryAdsTwo.url">
				<div class="col p-0">
					<app-single-ad [adConfig]="secondaryAdsTwo" [eventName]="'secondary-ads-two'"></app-single-ad>
				</div>
			</div>
		</div>

	</div>

	<div class=" col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px">
		<app-policy-footer></app-policy-footer>
	</div>
	<app-sidenav *ngIf="showSidenav" (bySidenavClosed)="closeDropOffLocations()" [headerTitle]="'Nearby Stores'">
		<app-nearby-dropoff-location (byClose)="sidenavInstance.close()"></app-nearby-dropoff-location>
	</app-sidenav>
</div>


<ng-template #loadingItem>
	<ng-container *ngIf="placeHolderLoader">
		<div class="row flex-column item-box p-3 gx-0 mb-md-3 placeholder-loading"
			*ngFor="let loading of placeholderLoaderArr">
			<div class="d-flex">
				<div class="col-auto img-box linear-background"></div>
				<div class="item-details-box w-100 ps-2">
					<div class="d-flex justify-content-between mb-2">
						<div class="w-100 data-row  linear-background"></div>
					</div>
					<div class="mb-2 col-12 col-md-6">
						<div class="w-100 data-row  linear-background"></div>
					</div>
					<div class="d-flex mb-2">
						<div class="col-12 col-md-6 data-row linear-background"></div>
						<div class="d-none d-md-block col-md-1"></div>
						<div class="d-none d-md-block col-md-5 data-row linear-background"></div>
					</div>
					<div class="d-flex">
						<div class="col-12 col-md-6 data-row linear-background"></div>
						<div class="d-none d-md-block col-md-1"></div>
						<div class="d-none d-md-block col-md-5 ms-md-0 ms-2 data-row linear-background"></div>
					</div>

				</div>
			</div>
			<div class="options-row d-flex justify-content-between align-items-center mt-2 mt-md-3">
				<div class="col-6"></div>
				<div class="col-6 data-row data-row-ex linear-background"></div>
			</div>
		</div>
	</ng-container>
</ng-template>