import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { Auth } from '@core/models/auth';
import { ReturnsConfigService } from '@services/returns-config.service';
import { BaseComponent } from '@shared/components/base.component';
import { showToastError } from '@store/actions/error.action';
import { getAuthResponse } from '@store/selectors/auth.selector';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  routeParamsSubscription!: Subscription;
  dataSubscription!: Subscription;
  authResponseSubscription!: Subscription;
  urlParamsLink = '';

  errorMap: { [key: string]: boolean } = {
    E001: true,
  };

  constructor(
    private hostedReturnService: ReturnsConfigService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {
    super({ hostedReturnService });
  }

  ngOnInit(): void {
    this.dataSubscription = this.activatedRoute.data.subscribe(data => {
      if (data['configResp'] === undefined) {
        this.router.navigate(['/service-unavailable'], {
          skipLocationChange: true,
        });
        return;
      }
      const urlEntities = this.getTenantAndBrandExternalId();
      this.urlParamsLink = `/${urlEntities?.tenantId}/${urlEntities?.brandExternalId}}`;
      this.routeParamsSubscription = this.activatedRoute.params.subscribe(
        params => {
          const errorCode = atob(atob(decodeURIComponent(params['errorCode'])));
          if (!this.errorMap[errorCode]) {
            this.store.dispatch(
              showToastError({
                showToast: true,
                errorMsg:
                  'Oops! you are trying something wrong. Please make a valid request.',
              })
            );
            return;
          }
          if (errorCode === 'E001') {
            this.authResponseSubscription = this.store
              .select(getAuthResponse)
              .pipe()
              .subscribe((authResponse: Auth) => {
                this.store.dispatch(
                  showToastError({
                    showToast: true,
                    errorMsg:
                      authResponse.returnIlleligibleReason! ||
                      'This order is not eligible for return.',
                  })
                );
              });
          }
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(showToastError({ showToast: false, errorMsg: '' }));
    if (this.routeParamsSubscription)
      this.routeParamsSubscription.unsubscribe();
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
    if (this.authResponseSubscription)
      this.authResponseSubscription.unsubscribe();
  }
}
