import { createReducer, on } from '@ngrx/store';
import { showToastSuccess } from '@store/actions/success.action';
import { SuccessState, initialSuccessState } from '@store/states/success.state';

const _successReducer = createReducer(
  initialSuccessState,
  on(showToastSuccess, (state, action): SuccessState => {
    return {
      ...state,
      showToast: action.showToast,
      successMsg: action.successMsg,
      close: action.close,
      data: action.data,
      timeoutMs: action.timeoutMs,
    };
  })
);

/**
 * Reduces the given state with the provided action for showing success toast
 * @param {SuccessState} state The current success state object.
 * @param {any} action The action object containing the payload and type.
 * @returns {SuccessState} The updated state after applying the success-related action.
 */
export function successReducer(state: SuccessState, action: any) {
  return _successReducer(state, action);
}
