<div class="exchange-item-container">
    <div class="d-flex flex-column overflow-hidden exchange-item-wrapper">
        <div class="overflow-auto exchange-item-content">
            <div class="exchange-item">
                <app-item [item]="exchangeItem" [showActionables]="false" [smallItemView]="true"
                    [itemHiddenFields]="hiddenFields"></app-item>
            </div>
            <div class="d-flex flex-column gap-3 p-3 exchange-item-attribute-wrapper">
                <div class="d-flex flex-column gap-3 exchange-item-attribute-content" [class.d-none]="i >= itemsToShow"
                    *ngFor="let attribute of attributes; index as i">
                    <span class="exchange-item-attribute-title">
                        {{ "GLOBAL.SELECT" | translate }} {{attribute.name | titlecase}}
                    </span>
                    <app-item-attribute-type [attribute]="attribute"
                        (bySelectAttributeType)="selectAttributeType($event)"></app-item-attribute-type>
                </div>
                <div class="d-flex flex-column gap-3 w-100 bg-white exchange-item-button-wrapper"
                    *ngIf="this.exchangeItem.itemAttributes">
                    <a *ngIf="attributes.length > itemsToShow" class="exchange-item-button-content"
                        (click)="toggleExchangeItemsToShow(true)" (keydown)="toggleExchangeItemsToShow(true)"
                        role="button" tabindex="0">
                        {{ "GLOBAL.SHOW_MORE" | translate }}
                    </a>
                    <a *ngIf="attributes.length === itemsToShow" class="exchange-item-button-content"
                        (click)="toggleExchangeItemsToShow(false)" (keydown)="toggleExchangeItemsToShow(false)"
                        role="button" tabindex="0">
                        {{ "GLOBAL.SHOW_LESS" | translate }}
                    </a>
                </div>
            </div>
        </div>
        <div class="p-3 w-100 exchange-item-footer-wrapper">
            <div class="d-flex justify-content-center align-items-center gap-2 exchange-item-footer-content">
                <app-ds-button class="w-100 exchange-item-footer-skip-button" [disabledHoverEffect]="true"
                    [buttonText]="'GLOBAL.SKIP'"
                    [buttonStyle]="{width: '100%', border: 'none', background: '#39485F1A'}"
                    (byButtonClick)="skipExchangeItem()"></app-ds-button>
                <app-ds-button class="w-100 exchange-item-footer-save-button" [disabled]="!selectedExchangeAttribute"
                    [buttonText]="'GLOBAL.SAVE'" [buttonStyle]="{width: '100%'}" [selected]="true"
                    (byButtonClick)="saveExchangeItem()"></app-ds-button>
            </div>
        </div>
    </div>
</div>