import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropoff-location',
  templateUrl: './drop-off-location.component.html',
  styleUrls: ['./drop-off-location.component.scss'],
})
export class DropOffLocationComponent {
  cardTitle!: string;
  locationOb!: any;
  @Input() showOtherLink!: boolean;
  @Input() set title(value: string) {
    if (value) {
      this.cardTitle = value;
    }
  }
  @Input() set location(value: object) {
    if (value) {
      this.locationOb = value;
    }
  }
  @Input() linkName!: string;

  @Output() byLinkClicked = new EventEmitter();
  @Input() customCss = "";

  otherLinkClicked() {
    this.byLinkClicked.emit();
  }

  openGoogleMap(location: any) {
    const googleMapurl = `https://maps.google.com/?q=${location.latitude},${location.longitude}`;
    window.open(googleMapurl, '_blank');
  }
}
