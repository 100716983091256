<div id="common-error-page-component">

    <div class="main-content-area content-area col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px">

        <div class="error-container row align-items-center justify-content-center">
            <div class="img-state error">
                <i-feather class="alert-triangle" name="alert-triangle"></i-feather>
                <div class="img-title">Oops! We can’t find the page you’re looking for.</div>
                <div class="try-again-message">
                    Please reach out to our
                    <!-- Conditional rendering for clickable link -->
                    <ng-container *ngIf="contactUrl; else plainText">
                        <a [href]="contactUrl" target="_blank" class="contact-us-link">customer support</a>
                    </ng-container>
                    <!-- Plain text fallback -->
                    <ng-template #plainText><b>customer support</b></ng-template>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px">
    <app-policy-footer></app-policy-footer>
</div>