import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatherModule } from 'angular-feather';
import {
  Camera,
  Heart,
  Github,
  X,
  XCircle,
  Search,
  Menu,
  ArrowLeft,
  HelpCircle,
  RotateCw,
  MapPin,
  AlertTriangle,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  Slash,
  Plus,
  Minus,
  ArrowRight,
  Info,
  Printer,
  Calendar,
  Check,
  Box,
  Phone,
  AlertCircle,
} from 'angular-feather/icons';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from '@deliverysolutions/ngx-intl-tel-input';
import { ReactiveFormsModule } from '@angular/forms';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HeaderComponent } from './header/header.component';
import { SidenavHeaderComponent } from './sidenav-header/sidenav-header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SidenavMenuHeaderComponent } from './sidenav-menu-header/sidenav-menu-header.component';
import { MenuComponent } from './menu/menu.component';
import { PolicyFooterComponent } from './policy-footer/policy-footer.component';
import { RedirectorComponent } from './redirector/redirector.component';
import { ServiceUnavailableComponent } from './service-unavailable/service-unavailable.component';
import { BroadcastMessageComponent } from './broadcast-message/broadcast-message.component';
import { MultiSkuFooterComponent } from './multi-sku-footer/multi-sku-footer.component';
import { BubbleLoaderComponent } from './bubble-loader/bubble-loader.component';
import { CaptureEventDirective } from '@app/directives/userpilot-service.directive';
import { DsButtonComponent } from '@core/components/ds-button/ds-button.component';
import { SkeletonLoaderComponent } from '@core/components/skeleton-loader/skeleton-loader.component';
import { InvalidPhoneFormatComponent } from '@core/components/invalid-phone-format/invalid-phone-format.component';
import { PhoneNumberUpdateComponent } from '@core/components/phone-number-update/phone-number-update.component';
import { DsPhoneNumberInputComponent } from '@core/components/ds-phone-number-input/ds-phone-number-input.component';

const icons = {
  Camera,
  Heart,
  Github,
  X,
  XCircle,
  Search,
  Menu,
  ArrowLeft,
  HelpCircle,
  RotateCw,
  MapPin,
  AlertTriangle,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  Slash,
  Plus,
  Minus,
  ArrowRight,
  Info,
  Printer,
  Calendar,
  Check,
  Box,
  Phone,
  AlertCircle,
};

@NgModule({
  declarations: [
    PageNotFoundComponent,
    HeaderComponent,
    MenuComponent,
    DsButtonComponent,
    SidenavComponent,
    SidenavHeaderComponent,
    SidenavMenuHeaderComponent,
    PolicyFooterComponent,
    RedirectorComponent,
    ServiceUnavailableComponent,
    BroadcastMessageComponent,
    MultiSkuFooterComponent,
    BubbleLoaderComponent,
    SkeletonLoaderComponent,
    CaptureEventDirective,
    InvalidPhoneFormatComponent,
    PhoneNumberUpdateComponent,
    DsPhoneNumberInputComponent,
  ],
  imports: [
    CommonModule,
    FeatherModule.pick(icons),
    RouterModule,
    TranslateModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
  ],
  exports: [
    HeaderComponent,
    PolicyFooterComponent,
    SidenavComponent,
    DsButtonComponent,
    RedirectorComponent,
    ServiceUnavailableComponent,
    FeatherModule,
    BroadcastMessageComponent,
    MultiSkuFooterComponent,
    BubbleLoaderComponent,
    SkeletonLoaderComponent,
    CaptureEventDirective,
    TranslateModule,
    InvalidPhoneFormatComponent,
    PhoneNumberUpdateComponent,
    DsPhoneNumberInputComponent,
  ],
})
export class CoreComponentsModule {}
