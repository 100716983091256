/* eslint-disable @angular-eslint/component-selector */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ItemListService } from '@services/itemlist.service';
import { Store } from '@ngrx/store';
import { showToastError } from '@store/actions/error.action';
import { showToastSuccess } from '@store/actions/success.action';
import { Subscription } from 'rxjs';
import { getAuthResponse } from '@store/selectors/auth.selector';
import { Auth } from '@core/models/auth';
import { loginSuccess } from '@store/actions/auth.action';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'ds-phone-number-update',
  templateUrl: './phone-number-update.component.html',
  styleUrls: ['./phone-number-update.component.scss'],
})
export class PhoneNumberUpdateComponent implements OnInit {
  @Input() invalidPhoneNumber = '';
  @Input() invoiceIds: string[] = [];

  @Output() displayUpdatePhoneModal: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() displayInvalidPhoneMessage: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  phoneForm!: UntypedFormGroup;
  showLoader = false;

  isPhoneNumberInvalid = false;
  isPhoneNumberEmpty = false;
  authResponseSubscription!: Subscription;
  authResponse!: Auth;

  constructor(
    private fb: FormBuilder,
    private itemListService: ItemListService,
    private store: Store,
    private elementRef: ElementRef,
    private storageServie: StorageService,
  ) {}

  ngOnInit(): void {
    this.phoneForm = this.fb.group({
      phoneNumber: ['', [Validators.required]],
    });
    this.phoneForm.controls['phoneNumber'].setValue(this.invalidPhoneNumber);

    // Subscribe to form changes to update the validation states
    this.phoneForm.valueChanges.subscribe(() => {
      this.updatePhoneValidationStates();
    });

    // Initial validation check but without displaying errors
    this.updatePhoneValidationStates(false);

    // Add event listener for clicks on the component's root element
    this.elementRef.nativeElement.addEventListener(
      'click',
      this.triggerValidationOnClick.bind(this)
    );

    this.authResponseSubscription = this.store
      .select(getAuthResponse)
      .pipe()
      .subscribe(resp => {
        this.authResponse = JSON.parse(JSON.stringify(resp));
      });
  }

  private triggerValidationOnClick(): void {
    this.updatePhoneValidationStates(true);
  }

  private updatePhoneValidationStates(showErrors = true): void {
    const phoneNumberControl = this.phoneForm.controls?.['phoneNumber'];
    const shouldShowErrors =
      showErrors && phoneNumberControl?.touched && phoneNumberControl?.dirty;

    this.isPhoneNumberInvalid =
      shouldShowErrors && !!phoneNumberControl?.errors?.['validatePhoneNumber'];

    this.isPhoneNumberEmpty =
      shouldShowErrors && !!phoneNumberControl?.errors?.['required'];
  }

  updatePhoneNumber(): void {
    if (this.phoneForm.valid) {
      this.showLoader = true;
      const phoneNumber = this.phoneForm.value.phoneNumber.e164Number;
      this.itemListService
        .updatePhoneNumber(this.invoiceIds, phoneNumber)
        .subscribe({
          next: () => {
            const customer = this.authResponse.invoiceInfo.customer;
            customer.phone = phoneNumber;
            this.storageServie.set('authResp', this.authResponse);
            this.store.dispatch(loginSuccess({ resp: this.authResponse }));

            this.store.dispatch(
              showToastSuccess({
                showToast: true,
                successMsg: 'Phone Number Updated.',
                close: true,
                timeoutMs: 3200,
              })
            );
            this.showLoader = false;
            this.closeUpdatePhoneModal();
            this.displayInvalidPhoneMessage.emit(false);
          },
          error: () => {
            this.store.dispatch(
              showToastError({
                showToast: true,
                errorMsg: 'Error! Failed to update phone number format.',
                close: true,
                timeoutMs: 3200,
              })
            );
            this.showLoader = false;
            this.closeUpdatePhoneModal();
          },
        });
    }
  }

  closeUpdatePhoneModal(): void {
    this.displayUpdatePhoneModal.emit(false);
  }

  ngOnDestroy(): void {
    if (this.authResponseSubscription) this.authResponseSubscription.unsubscribe();
  }
}
