import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '@core/services/base.service';
import { FeeCalculateRequest } from '@core/models/fee-calculate';

@Injectable({
  providedIn: 'root',
})
export class FeeService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getCalculatedReturnFee(feeCalculateBody: FeeCalculateRequest) {
    return this.httpClient
      .post(`${this.serverRootPathV2}returns/fee/calculate`, feeCalculateBody)
      .pipe(
        map((val: any) => val),
        catchError(e => {
          let errorMsg: string;
          console.error('error in fetching calculated return fee', e);
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }
}
