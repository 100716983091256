import { ReturnsConfigService } from '@services/returns-config.service';

export class BaseComponent {
  public returnsConfigService!: ReturnsConfigService;

  constructor({ hostedReturnService }: any) {
    this.returnsConfigService = hostedReturnService;
  }

  public getTenantAndBrandExternalId() {
    return this.returnsConfigService.getUrlEntities();
  }
}
