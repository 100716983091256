<div class="item-list-box p-3 mb-2 mb-md-3 drop-off-location {{customCss}}" *ngIf="locationOb">
	<div class="title mb-2" *ngIf="cardTitle">{{cardTitle}}</div>
	<div class="d-flex location-row gap-4">
		<div class="col">
			<span class="details line1">{{locationOb?.address}} - </span>
			<span class="details miles">{{locationOb?.distance}}</span>
		</div>
		<div class="col-auto cursor-pointer" (click)="openGoogleMap(locationOb)">
			<img src="assets/icons/drop-off-location/location.svg" [captureEvent]="'click-google-map-location'" />
		</div>
	</div>
	<div class="d-flex justify-content-between">
		<div class="location-row">
			<span class="details phone">{{locationOb?.phone}}</span>
		</div>
	</div>
	<div class="d-flex other-location-option mt-2" *ngIf="linkName">
		<a href="javascript:void(0)" class="content-common-color" (click)="otherLinkClicked()">{{linkName}}
			<i-feather class="item-box-icon" name="chevron-right"></i-feather></a>
	</div>
</div>