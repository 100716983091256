<div class="input-group input-group-merge">
  <div class="input-group-prepend">
    <span class="input-group-text">
      <i-feather name="Search"></i-feather>
    </span>
  </div>
  <input class="form-control" type="text" placeholder="Find" (input)="onInputChange($event)" #inputSelect
    style="border-top-left-radius: inherit; border-bottom-left-radius: inherit;"
    [style.border-top-right-radius]="inputSelect.value.length > 0 ? 'inherit' : null"
    [style.border-bottom-right-radius]="inputSelect.value.length > 0 ? 'inherit' : null" />
  <div class="input-group-append" *ngIf="inputSelect.value.length>0">
    <button class="btn btn-outline-secondary btn-color-onhover-clear" type="button" (click)="onClear(inputSelect)"
      style="display: flex;" [captureEvent]="'click-clear-selected-inputs'">
      <i class="fe fe-x-circle" style="color: black;"></i>
    </button>
  </div>
</div>