import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-sidenav-header',
  templateUrl: './sidenav-header.component.html',
  styleUrls: ['./sidenav-header.component.scss'],
})
export class SidenavHeaderComponent {
  @Input() heading = '';
  @Input() width!: string;
  @Output() byClose = new EventEmitter();

  closeBox() {
    const ob: any = {};
    this.byClose.emit(ob);
  }
}
