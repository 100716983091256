<div id="return-method-view-details-component">
    <div *ngIf="!changeAddressView && !nearByStoreView">
        <app-return-method-card [returnMethod]="returnMethod" (byChangeAddress)="toggleChangeAddressView()"
            (byViewNearByStore)="toggleNearByStoreView()" (bySelectReturnLabelBox)="selectReturnLabelBoxOption($event)"
            (bySetReturnSmartWindow)="setReturnSmartWindow($event)" [useSideDrawerForAction]="false"
            [returnFeeLoader]="returnFeeLoader"
            [hideElement]="{'printLabelsBtn':true, 'instructionsBtn':true, 'viewDetailsBtn':true, 'keepTheItem':true, 'itemList': true}"></app-return-method-card>
        <div class="w-100 flex-column justify-content-center align-items-center d-inline-flex return-method-instructions-group"
            *ngIf="returnMethod?.instructions">
            <div
                class="align-self-stretch justify-content-start align-items-start d-inline-flex return-method-instructions-text">
                Return Instructions
            </div>
            <div
                class="align-self-stretch flex-column justify-content-start align-items-center d-flex return-method-instructions-content">
                <p [innerHTML]="returnMethod?.instructions"></p>
            </div>
        </div>
        <ng-container *ngIf="returnMethod?.itemList">
            <div *ngIf="isReturningItem" class="return-method-view-details-returning-title">
                {{'RETURN_REQUEST.RETURNING' | translate}}
            </div>
            <ng-container *ngFor="let item of returnMethod.itemList;let firstItem = first">
                <ng-container *ngIf="!item.exchangedItem">
                    <app-item [item]="item"
                        [hideKeys]="{'returnDetailsButton': true, 'returnButton': true, 'fee': true}"
                        [urlEntity]="entities" [showModal]="false" [itemHiddenFields]="hiddenFields"></app-item>
                </ng-container>
            </ng-container>
            <!-- Start Exchanging Item -->
            <ng-container *ngIf="inventoryMetaData.length">
                <app-exchanging-item-info [itemList]="returnMethod.itemList!" [isExchangeSelected]="true"
                    [inventoryMetaData]="inventoryMetaData" [isVerticalView]="true"
                    [hiddenFields]="hiddenFields"></app-exchanging-item-info>
                <!-- End Exchanging Item -->
            </ng-container>
        </ng-container>
    </div>

    <div *ngIf="changeAddressView && address && !nearByStoreView">
        <app-address-form [changeAddressForm]="true" [address]="address" (getAddress)="setAddress($event)"
            [addressType]="addressTypes.CUSTOMER_ADDRESS"></app-address-form>
    </div>
    <div *ngIf="nearByStoreView && !changeAddressView">
        <app-nearby-dropoff-location (byChangeAddress)="changeAddressNearByStore($event)"></app-nearby-dropoff-location>
    </div>
</div>