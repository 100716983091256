import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigState } from '@store/states/config.state';

export const CONFIG_STATE = 'config';

const getConfigState = createFeatureSelector<ConfigState>(CONFIG_STATE);

export const getConfig = createSelector(
  getConfigState,
  (state: ConfigState) => {
    return state?.config;
  }
);
