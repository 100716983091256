<div id="ds-overlay" (click)="close()"></div>
<div id="sidenav-component" class="sidenav" [ngStyle]="{
		'right': sidnavPosition === 'right' ? '0': null, 
		'left': sidnavPosition === 'left' ? '0': null,
		'width': sidenavWidth
	}" [ngClass]="{'ptop-57': headerTitle}">
	<app-sidenav-header (byClose)="close()" [heading]="headerTitle" [width]="sidenavWidth"
		*ngIf="headerTitle"></app-sidenav-header>
	<app-sidenav-menu-header *ngIf="!headerTitle && sidenavType === 'menu'" [width]="sidenavWidth" [logo]="logo"
		(byClose)="close()"></app-sidenav-menu-header>
	<ng-content></ng-content>

</div>