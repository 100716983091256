<div id="status-loading-component">

    <div class="main-content-area content-area col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px">

        <div class="state-container row align-items-center justify-content-center">
            <div class="img-state loading" *ngIf="state === RETURN_STATES.LOADING">
                <img src="assets/images/request_process.gif" alt="Request Process Loader" />
                <div class="img-title">Your return request is being processed.</div>
            </div>
            <div class="img-state success" *ngIf="state === RETURN_STATES.SUCCESS">
                <div class="success-indicator d-flex align-items-center justify-content-center">
                    <i-feather name="Check" class="check-icon"></i-feather>
                </div>
                <div class="img-title">Your return request has been placed.</div>
                <div *ngIf="rmaId" class="sub-info">RMA ID # {{rmaId}}</div>
            </div>
            <div class="img-state error" *ngIf="state === RETURN_STATES.ERROR">
                <img src="assets/images/request-error.svg" alt="Request Error" />
                <div class="img-title">Your return request could not be <br>processed.</div>
            </div>
        </div>

    </div>
</div>