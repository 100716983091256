import { Component } from '@angular/core';

@Component({
  selector: 'app-bubble-loader',
  templateUrl: './bubble-loader.component.html',
  styleUrls: ['./bubble-loader.component.scss']
})
export class BubbleLoaderComponent {

}
