import { Component, Input, OnInit } from "@angular/core";
import { trigger, state, style, animate, transition } from "@angular/animations";

@Component({
	selector: "ds-cookie-consent",
	templateUrl: "./cookie-consent.component.html",
	styleUrls: ["./cookie-consent.component.scss"],
	animations: [
		trigger("toastAnimation", [
			state("in", style({ transform: "translateX(0)" })),
			transition(":enter", [style({ transform: "translateX(100%)" }), animate("0.5s ease-in-out")]),
			transition(":leave", [animate("0.5s ease-in-out", style({ transform: "translateX(100%)" }))]),
		]),
	],
})
export class CookieConsentComponent implements OnInit {
	@Input() brandExternalId: string | undefined;
	dismissed = false;

	constructor() {}

	ngOnInit(): void {
		const storageName = `${this.brandExternalId}-cookie-dismissed`;

		const exists = window.localStorage.getItem(storageName);
		if (exists === "1") {
			this.dismissed = true;
		}
	}

	dismissNotice() {
		const storageName = `${this.brandExternalId}-cookie-dismissed`;
		window.localStorage.setItem(storageName, "1");
		this.dismissed = true;
	}
}
