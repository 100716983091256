import { Action, createReducer, on } from '@ngrx/store';
import {
  SelectedItemDetails,
  SelectedItemListState,
  initialSelectedItemListState,
} from '@store/states/selected-item-list.state';
import {
  addSelectedItems,
  deleteSelectedItems,
  deleteSelectedOrderDetails,
  getSelectedItems,
  updateSelectedItems,
} from '@store/actions/selected-item-list.action';
import { sanitizeOrderId } from '@core/utils/sanitizeOrderId';

const _itemListReducer = createReducer(
  initialSelectedItemListState,
  on(getSelectedItems, state => {
    return {
      ...state,
    };
  }),
  on(addSelectedItems, (state, { selectedItem, orderExternalId }) => {
    const { selectedItemsDetail } = JSON.parse(JSON.stringify(state));
    const sanitizedOrderId = sanitizeOrderId(orderExternalId);
    if (selectedItemsDetail[sanitizedOrderId]) {
      const oldItemIndex = selectedItemsDetail[sanitizedOrderId].findIndex(
        (item: SelectedItemDetails) => item.__refId === selectedItem.__refId
      );
      if (oldItemIndex > -1) {
        selectedItemsDetail[sanitizedOrderId].splice(oldItemIndex, 1);
      }
      selectedItemsDetail[sanitizedOrderId] = [
        ...selectedItemsDetail[sanitizedOrderId],
        selectedItem,
      ];
    } else {
      selectedItemsDetail[sanitizedOrderId] = [selectedItem];
    }
    return { ...state, selectedItemsDetail };
  }),
  on(deleteSelectedItems, (state, { selectedItemRefIds, orderExternalId }) => {
    const { selectedItemsDetail } = JSON.parse(JSON.stringify(state));
    selectedItemsDetail[sanitizeOrderId(orderExternalId)] =
      state.selectedItemsDetail[sanitizeOrderId(orderExternalId)].filter(
        item => !selectedItemRefIds.includes(item.__refId)
      );
    return { ...state, selectedItemsDetail };
  }),
  on(updateSelectedItems, (state, { selectedItemRefId, selectedItem }) => {
    const index = state.selectedItemsDetail[
      selectedItem.orderExternalId
    ].findIndex(item => {
      return item.__refId === selectedItemRefId;
    });
    state.selectedItemsDetail[selectedItem.orderExternalId][index] =
      selectedItem;
    return state;
  }),
  on(deleteSelectedOrderDetails, (state, { orderExternalId }) => {
    const { selectedItemsDetail } = JSON.parse(JSON.stringify(state));
    delete selectedItemsDetail[sanitizeOrderId(orderExternalId)];
    return { ...state, selectedItemsDetail };
  })
);

export function itemListReducer(state: SelectedItemListState, action: Action) {
  return _itemListReducer(state, action);
}
