import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from '@environments/environment';
import {
  DARK,
  DARK_CONSTRAST_COLOR,
  LIGHT,
  TRACK_CONSTRAST_COLOR,
} from '@core/constants/generic-constants';
import { contrastColorOf } from '@core/utils/generate-contrast-color';
import { addConfig } from '@store/actions/config.action';
import {
  HostedReturnsConfig,
  Recommendations,
} from '@core/models/returns-config.interface';
import { BaseService } from '@core/services/base.service';
import { StorageService } from './storage.service';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class ReturnsConfigService extends BaseService {
  PRIMARY_COLOR = '#FF5A00';
  public hostedReturnsConfig!: HostedReturnsConfig;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private store: Store
  ) {
    super();
  }

  setHiddenFields(config: any) {
    const orderHiddenFields: { [key: string]: boolean } = {};
    const orderItemFields =
      config?.componentVisibility?.orderDetails?.orderItems?.hideFields;

    orderItemFields?.map((field: string) => {
      orderHiddenFields[field] = true;
    });

    return orderHiddenFields;
  }

  getSampleConfig() {
    return {
      active: false,
      branding: {
        color: {
          primary: '',
          background: '#FFFFFF',
        },
        fonts: {
          heading:
            'https://qa.files.deliverysolutions.co/zebra/tenantassets/lowes%20foods/font/font/1662017553906.woff2',
          //"content": "https://qa.files.deliverysolutions.co/zebra/tenantassets/zebra/font/font/1646310800596.woff2",
          content: '',
          navigation:
            'https://qa.files.deliverysolutions.co/zebra/tenantassets/zebra/font/font/1646310800596.woff2',
        },
        businessLogos: [
          {
            size: 'large',
            url: 'assets/images/logo.svg',
            externalLink:
              'https://dev.portal.deliverysolutions.co/#/returns-configuration',
          },
        ],
        favicon: {
          active: true,
          url: '6343b6217144e845ab121a53',
          pageTitle: 'Union',
        },
      },
      loginMethod: 'email',
      components: {
        broadcast: {
          active: true,
          header: 'COVID-19 Safety',
          body: "We're taking necessary precautions and following CDC guidelines to ensure our packages to you are sanitised and safe. Our other protocols with our delivery partners and staff will also impact your fulfilment experience. Please cooperate with our staff and partners. We are trying our best to provide our same great",
        },
        recommendations: {
          active: false,
          jsonFileId: [
            {
              imageUrl: 'assets/images/p1.svg',
              productName: 'Organic Vintage T-Shirt test',
              productUrl: 'https://www.google.com',
            },
            {
              imageUrl: 'assets/images/p2.svg',
              productName: 'Pointelle Button-Front T-Shirt',
              productUrl: 'https://www.google.com',
            },
            {
              imageUrl: 'assets/images/p3.svg',
              productName: 'Fleece Sweatshirt Dress',
              productUrl: 'https://www.google.com',
            },
            {
              imageUrl: 'assets/images/p1.svg',
              productName: 'Organic Vintage T-Shirt',
              productUrl: 'https://www.google.com',
            },
            {
              imageUrl: 'assets/images/p2.svg',
              productName: 'Pointelle Button-Front T-Shirt',
              productUrl: 'https://www.google.com',
            },
            {
              imageUrl: 'assets/images/p3.svg',
              productName: 'Fleece Sweatshirt Dress',
              productUrl: 'https://www.google.com',
            },
          ],
          url: '',
        },
        needHelp: {
          active: true,
          helpUrl:
            'https://dev.portal.deliverysolutions.co/#/returns-configuration',
          displayText: 'Need Help',
        },
        returnPolicy: {
          active: true,
          url: 'https://dev.portal.deliverysolutions.co/#/returns-configuration',
          displayText: 'Read Policy',
        },
        header: {
          align: 'left',
          navigationMenu: {
            active: true,
            menuItems: [
              {
                displayText: 'Shop Departments',
                url: 'https://dev.portal.deliverysolutions.co/#/returns-configuration',
              },
              {
                displayText: 'Weekly Deals',
                url: 'https://dev.portal.deliverysolutions.co/#/returns-configuration',
              },
              {
                displayText: 'Inspiration',
                url: 'https://dev.portal.deliverysolutions.co/#/returns-configuration',
              },
              {
                displayText: 'New Arrivals',
                url: 'https://dev.portal.deliverysolutions.co/#/returns-configuration',
              },
              {
                displayText: 'Rewards',
                url: 'https://dev.portal.deliverysolutions.co/#/returns-configuration',
              },
              {
                displayText: 'Store Finder',
                url: 'https://dev.portal.deliverysolutions.co/#/returns-configuration',
              },
            ],
          },
          fontSize: null,
          fontColor: '',
          backgroundColor: '#FFF',
        },
        banner: {
          active: true,
          url: 'assets/images/login-page-bg.png',
          pageTitle:
            'https://dev.portal.deliverysolutions.co/#/returns-configuration',
        },
        ads: [
          {
            type: 'primary',
            url: 'assets/images/banner1.svg',
            clickthroughLink:
              'https://dev.portal.deliverysolutions.co/#/returns-configuration',
            active: true,
          },
          {
            type: 'secondaryOne',
            url: 'assets/images/banner2.svg',
            clickthroughLink:
              'https://dev.portal.deliverysolutions.co/#/returns-configuration',
            active: true,
          },
          {
            type: 'secondaryTwo',
            url: 'assets/images/banner2.svg',
            clickthroughLink:
              'https://dev.portal.deliverysolutions.co/#/returns-configuration',
            active: true,
          },
        ],
      },
      componentVisibility: {
        orderDetails: {
          orderItems: {
            hideFields: ['price', 'salesPrice'],
          },
        },
        makeReturnReasonCommentsMandatory: true,
        displayRefundMethods: true,
      },
      customDomain: 'testhere.dl-s.co',
      trackingTools: [
        {
          id: 'google-analytics',
          value: 'GA1234333',
        },
        {
          id: 'clevertap',
          value: 'CT232323',
        },
      ],
      brandName: "_Bhushan's Account_",
      brandExternalId: 'Nupur DSP Redesign',
      brandLogo: null,
      tenantId: 'nupur_dsp_redesign',
    };
  }

  public fetchHostedReturnConfig(
    brandExternalId: string | undefined = undefined,
    customDomain?: string
  ) {
    if (this.hostedReturnsConfig) {
      return of(this.hostedReturnsConfig);
    }

    const httpparams: any = {
      a: true,
    };

    const headers: any = {};

    if (brandExternalId) {
      httpparams.brandExternalId = brandExternalId;
      headers.brandExternalId = brandExternalId;
    }

    if (customDomain) {
      httpparams.customDomain = customDomain;
    }
    // this.hostedReturnsConfig = this.getSampleConfig();
    // this.store.dispatch(addConfig({ config: this.hostedReturnsConfig }));
    // return of(this.hostedReturnsConfig);

    return this.httpClient
      .get(`${this.serverRootPathV2}hosted-returns/config`, {
        params: httpparams,
      })
      .pipe(
        tap((val: any) => {
          if (environment.env === 'local') {
            val.googleMapsApiKey = environment.googleMapsAPIKey;
          } else if (val.googleMapsApiKey) {
            const decryptedMapsApiKey = this.decryptMapsApiKey(
              val.googleMapsApiKey
            );
            val.googleMapsApiKey = decryptedMapsApiKey;
          }
          this.hostedReturnsConfig = val;
          this.store.dispatch(addConfig({ config: this.hostedReturnsConfig }));
        }),
        catchError(e => {
          console.log('error in fetching config', e);
          return of(undefined);
        })
      );
  }

  public setFontVariables(config: HostedReturnsConfig) {
    let content = '';
    let heading = '';
    let nav = '';

    if (config.branding.fonts?.content) {
      const contentFontType = config.branding.fonts.content.split('.').pop();

      content = `@font-face {
        font-family: 'content_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${config.branding.fonts.content}) format('${contentFontType}');
      }`;
    }

    if (config.branding.fonts?.heading) {
      const headingFontType = config.branding.fonts.heading.split('.').pop();

      heading = `@font-face {
        font-family: 'heading_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${config.branding.fonts.heading}) format('${headingFontType}');
      }`;
    }

    if (config.branding.fonts?.navigation) {
      const navFontType = config.branding.fonts.navigation.split('.').pop();

      nav = `@font-face {
        font-family: 'nav_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${config.branding.fonts.navigation}) format('${navFontType}');
      }`;
    }

    const style = document.createElement('style');
    style.setAttribute('id', 'returns-custom-font');
    style.innerHTML = `
      ${content}
      ${heading}
      ${nav}
    `;

    document.head.appendChild(style);
  }

  // setup page theme. these css variables will be used cross page for proper brand experience
  public setupTheme(config: HostedReturnsConfig) {
    if (config.branding?.color?.primary) {
      const contrastColor = contrastColorOf(config.branding.color.primary);
      if (contrastColor === DARK_CONSTRAST_COLOR) {
        document.body.setAttribute(TRACK_CONSTRAST_COLOR, DARK);
      } else {
        document.body.setAttribute(TRACK_CONSTRAST_COLOR, LIGHT);
      }

      // primary color for site
      document.documentElement.style.setProperty(
        '--primary',
        config.branding.color.primary
      );
      // when you use primary as background use this as font color
      document.documentElement.style.setProperty(
        '--primary-contrast',
        contrastColor
      ); // contrasting black or white
    } else {
      // default primary color for site, when no primary color is given
      document.documentElement.style.setProperty(
        '--primary',
        this.PRIMARY_COLOR
      );
      // when you use primary as background use this as font color
      document.documentElement.style.setProperty('--primary-contrast', '#fff'); // contrasting black or white
    }

    // for site nav
    if (config.components.header?.fontColor) {
      document.documentElement.style.setProperty(
        '--nav-primary',
        config.components.header.fontColor
      );
    }
    if (config.components.header?.fontSize) {
      document.documentElement.style.setProperty(
        '--nav-size',
        config.components.header.fontSize + 'px'
      );
    }
    if (config.components.header?.backgroundColor) {
      document.documentElement.style.setProperty(
        '--nav-bg',
        config.components.header.backgroundColor
      );

      const navBgContrast = contrastColorOf(
        config.components.header.backgroundColor
      );

      document.documentElement.style.setProperty(
        '--nav-bg-contrast',
        navBgContrast
      );
    }
  }

  public setPageTitleAndFavicon(config: HostedReturnsConfig) {
    if (
      config &&
      config.branding.favicon?.active &&
      config.branding.favicon.pageTitle
    ) {
      document.title = config.branding.favicon.pageTitle;
    } else if (config) {
      document.title = config.brandName;
    } else {
      document.title = 'Page not found';
    }

    if (
      config &&
      config.branding.favicon?.active &&
      config.branding.favicon.url
    ) {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.type = 'image/png';
      link.href = config.branding.favicon.url;
      document.head.appendChild(link);
    }
  }

  getUrlEntities() {
    if (!this.hostedReturnsConfig) {
      return null;
    }

    return {
      tenantId: this.hostedReturnsConfig.tenantId,
      brandExternalId: this.hostedReturnsConfig.brandExternalId,
      orderExternalId: this.storageService.orderExternalId,
    };
  }

  public fetchExternalRecommendations(
    url: string,
    orderId: string
  ): Observable<Recommendations[] | [] | undefined> {
    if (
      (url.includes('https') || url.includes('http')) &&
      !url.includes('deliverysolutions.co')
    ) {
      return this.httpClient
        .get<Recommendations[] | [] | undefined>(
          `${this.serverRootPathV2}hosted-returns/external-recommendations`,
          { params: { url, orderId } }
        )
        .pipe(
          catchError(e => {
            console.log('error in fetching recommendations', e);
            return of(undefined);
          })
        );
    } else {
      return of([]);
    }
  }

  setupDSGoogleAnalytics() {
    // register DS google tag manager
    if (environment.googleTagmanagerID) {
      const gaScript3 = document.createElement('script');
      gaScript3.setAttribute('nonce', 'gaScript3');
      gaScript3.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${environment.googleTagmanagerID}');`;
      document.head.appendChild(gaScript3);
    }
  }

  decryptMapsApiKey(googleMapsApiKey: string): string {
    return CryptoJS.AES.decrypt(
      googleMapsApiKey,
      environment.googleMapsApiKeyDecryptionPassphrase
    ).toString(CryptoJS.enc.Utf8);
  }
}
