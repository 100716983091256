<div id="return-details-component">
	<div class="main-content-area content-area col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px bg-white">

		<app-page-header [showBackButtonIcon]="true" [title]="'Return Details'" [alignment]="'center'"
			(backButtonClick)="goToItemlist()"></app-page-header>
		<div class="top-spacing">
			<app-item *ngIf="itemOb" [item]="itemOb" [showActionables]="false"
				[hideKeys]="{'fee': true, 'returnButton': true}" [showModal]="true"
				[itemHiddenFields]="hiddenFields"></app-item>
		</div>
		<ng-container *ngIf="showPlaceHolderLoader">
			<div class="item-box p-3 mb-2 mb-md-3 return-options" *ngFor="let data of placeholderData">
				<div class="item-details-box return-details-info col-auto placeholder-loading">
					<div class="data-row linear-background col-6 col-md-4 mb-2"></div>
					<div class="data-row linear-background col-8 col-md-6"></div>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="!showPlaceHolderLoader && returnRequests && returnRequests.length>0">
			<div class="d-flex justify-content-between item-box return-options cursor-pointer"
				(click)="goToDetailsPage(req.rmaId)" *ngFor="let req of returnRequests">
				<div class="item-details-box return-details-info col-auto">
					<span class="key">{{req?.dateToShow}}</span>
					<div class="pt-2">
						<span class="key">RMA ID:</span>
						<span class="val">&nbsp;{{req?.rmaId}}</span>
					</div>
				</div>
				<span class="col-auto">
					<i-feather class="item-box-icon" name="chevron-right"></i-feather>
				</span>
			</div>
		</ng-container>
	</div>

	<div class=" col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px">
		<app-policy-footer></app-policy-footer>
	</div>

</div>