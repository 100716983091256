import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ds-invalid-phone-format',
  templateUrl: './invalid-phone-format.component.html',
  styleUrls: ['./invalid-phone-format.component.scss'],
})
export class InvalidPhoneFormatComponent implements OnInit {
  @Input() invalidPhoneNumber = '';
  @Input() invoiceIds: string[] = [];
  @Output() displayInvalidPhoneMessage: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  validatePhoneMessage = '';
  showPhoneNumberUpdate = false;
  updateModalRef!: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.validatePhoneMessage = 'Your phone number format is invalid';
  }

  updatePhoneNumber(): void {
    this.showPhoneNumberUpdate = true;
  }

  openUpdateModal(content: TemplateRef<any>, event: Event) {
    this.showPhoneNumberUpdate = true;
    event.preventDefault();
    event.stopPropagation();
    this.updateModalRef = this.modalService.open(content, {
      centered: true,
      keyboard: false,
    });
  }

  displayUpdatePhoneModal(value:boolean): void {
    this.showPhoneNumberUpdate = value;
    this.updateModalRef.close();
  }

  showInvalidPhoneMessage(val: boolean): void {
    this.displayInvalidPhoneMessage.emit(val);
  }
}
