import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Item } from '@core/models/item';
import { AuthState } from '@store/states/auth.state';
import { sanitizeOrderId } from '@core/utils/sanitizeOrderId';

export const AUTH_STATE = 'auth';

const getAuthState = createFeatureSelector<AuthState>(AUTH_STATE);

export const getAuthResponse = createSelector(
  getAuthState,
  (state: AuthState) => {
    return state.authResp;
  }
);

export const getItemList = createSelector(getAuthState, (state: AuthState) => {
  const itemList = state.authResp?.itemList;
  return itemList;
});

export const getItemById = (id: string, orderExternalId: string) =>
  createSelector(getAuthState, (state: AuthState) => {
    const itemList = state.authResp?.itemList;
    const item = itemList.find(
      (item: Item) =>
        item.__refId === id
    );
    return item;
  });

export const getOrderIdAndUser = createSelector(
  getAuthState,
  (state: AuthState) => {
    if (!state?.authResp) return null;

    const { invoiceInfo } = state.authResp;
    const userData = invoiceInfo.customer;
    const orderId = invoiceInfo.groupId || invoiceInfo.orderExternalId;
    return { orderId, userData };
  }
);

export const getSanitisedOrderId = createSelector(
  getAuthState,
  (state: AuthState) => {
    if (!state?.authResp) return null;

    const { invoiceInfo } = state.authResp;
    const orderId = invoiceInfo.orderExternalId;
    const sanitizedOrderId = sanitizeOrderId(orderId);
    return { orderId, sanitizedOrderId };
  }
);
