import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { BaseService } from './base.service';
import { showToastError } from '@store/actions/error.action';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class NearbyStoresService extends BaseService {
  constructor(private httpClient: HttpClient, private store: Store) {
    super();
  }

  fetchNearbyStores(payload: any) {
    const ob: any = payload;
    const deliveryAddress = `${ob.address?.street},
		${ob.address?.city},
		${ob.address?.state},
		${ob.address?.zipcode},
		${ob.address?.country},
		${ob.address?.latitude},
		${ob.address?.longitude}
	`;
    return this.httpClient
      .get(
        `${this.serverRootPathV2}returns/nearbystores?page=${ob.page}&limit=${ob.limit}&deliveryAddress=${deliveryAddress}`
      )
      .pipe(
        tap(() => {
          // console.log('nearbystores =>', val);
        }),
        catchError(e => {
          let errorMsg: string;
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }
}
