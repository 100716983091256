import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, timer } from 'rxjs';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { setOrderExternalId } from '@store/actions/order-id.action';
import { getConfig } from '@store/selectors/config.selector';
import { getErrorResponse } from '@store/selectors/error.selector';
import { ErrorState } from '@store/states/error.state';
import { setHeaderSpacer } from '@store/actions/header-spacer.action';
import { showToastError } from '@store/actions/error.action';
import {
  HostedReturnsConfig,
  NeedHelp,
} from '@core/models/returns-config.interface';
import { getSuccessResponse } from '@store/selectors/success.selector';
import { SuccessState } from '@store/states/success.state';
import { showToastSuccess } from '@store/actions/success.action';

@Component({
  selector: 'app-returns-root',
  templateUrl: './returns-root.component.html',
  styleUrls: ['./returns-root.component.scss'],
})
export class ReturnsRootComponent implements OnInit, OnDestroy {
  configSubscription!: Subscription;
  needHelpWidget!: NeedHelp;
  hostedReturnConfig!: HostedReturnsConfig;
  isNavBackgroundWhite = false;
  dataSubscription!: Subscription;
  spacer: any;
  isMultiSkuEnabled = false;
  errorData!: ErrorState;
  successData!: SuccessState;
  errorSubscription!: Subscription;
  successSubscription!: Subscription;
  @ViewChild('errorMessage', { read: ElementRef })
  public errorMessage!: ElementRef;
  @ViewChild('successMessage', { read: ElementRef })
  public successMessage!: ElementRef;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    let timeOutErrorToast: Subscription;
    this.errorSubscription = this.store
      .select(getErrorResponse)
      .pipe()
      .subscribe((error: ErrorState) => {
        if (!NullEmptyChecker.isNull(timeOutErrorToast)) {
          timeOutErrorToast.unsubscribe();
        }
        this.errorData = {
          showToast: false,
          errorMsg: '',
          close: false,
          timeoutMs: 0,
        }
        setTimeout(() => {
          this.errorData = error;
          if (this.errorData?.timeoutMs && this.errorData?.showToast) {
            timeOutErrorToast = timer(this.errorData.timeoutMs).subscribe(() => {
              this.closeToast();
            });
          }
        });
      });
    let timeOutSuccessToast: Subscription;
    this.successSubscription = this.store
      .select(getSuccessResponse)
      .pipe()
      .subscribe((success: SuccessState) => {
        if (!NullEmptyChecker.isNull(timeOutSuccessToast)) {
          timeOutSuccessToast.unsubscribe();
        }
        this.successData = {
          showToast: false,
          successMsg: '',
          close: false,
          timeoutMs: 0,
        }
        setTimeout(() => {
          this.successData = success;
          if (this.successData?.timeoutMs && this.successData?.showToast) {
            timeOutSuccessToast = timer(this.successData.timeoutMs).subscribe(() => {
              this.closeSuccessToast();
            });
          }
        });
      });

    this.dataSubscription = this.activatedRoute.data.subscribe(data => {
      if (data['configResp'] === undefined) {
        this.router.navigate(['/service-unavailable'], {
          skipLocationChange: true,
        });
        return;
      }
      const snapshot = this.activatedRoute.snapshot;
      this.store.dispatch(
        setOrderExternalId({
          orderId: atob(decodeURIComponent(snapshot.params['orderId'])),
        })
      );
      this.setHeaderByConfig();
    });
  }

  setHeaderByConfig() {
    this.configSubscription = this.store
      .select(getConfig)
      .pipe()
      .subscribe(config => {
        if (config) {
          this.hostedReturnConfig = config;
          this.needHelpWidget = config.components?.needHelp;
          const navBg =
            this.hostedReturnConfig.components.header.backgroundColor;
          this.isMultiSkuEnabled =
            !!this.hostedReturnConfig.componentVisibility?.multiSku?.enable;
          const whiteColors = ['#fff', '#FFF', '#ffffff', '#FFFFFF', 'white'];
          this.isNavBackgroundWhite = whiteColors.includes(navBg);
        }
      });
  }

  getHeightOfHeader(e: any) {
    this.spacer = e.height + 'px';
    this.store.dispatch(
      setHeaderSpacer({
        headerSpacer: this.spacer,
      })
    );
  }

  closeToast() {
    if (this.errorData.close) {
      const errorMessage = this.errorMessage.nativeElement;
      this.renderer.removeClass(errorMessage, 'slide-in');
      this.renderer.addClass(errorMessage, 'slide-out');
    }

    setTimeout(() => {
      this.store.dispatch(
        showToastError({
          showToast: false,
          errorMsg: '',
          close: false,
          timeoutMs: 0,
        })
      );
    }, 500);
  }

  closeSuccessToast() {
    if (this.successData.close) {
      const successMessage = this.successMessage.nativeElement;
      this.renderer.removeClass(successMessage, 'slide-in');
      this.renderer.addClass(successMessage, 'slide-out');
    }

    setTimeout(() => {
      this.store.dispatch(
        showToastSuccess({
          showToast: false,
          successMsg: '',
          close: false,
          timeoutMs: 0,
        })
      );
    }, 500);
  }

  ngOnDestroy(): void {
    if (this.configSubscription) this.configSubscription.unsubscribe();
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
    if (this.errorSubscription) this.errorSubscription.unsubscribe();
    if (this.successSubscription) this.successSubscription.unsubscribe();
    this.store.dispatch(setOrderExternalId({ orderId: '' }));
  }
}
