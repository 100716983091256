<div class="item-list-box list-box mb-0">
	<div
		class="d-flex flex-column flex-md-row justify-content-start align-items-start justify-content-md-between align-items-md-center invalid-phone-message-container border-radius-5px p-3">
		<div class="message-box">
			<i-feather class="phone-icon" name="Phone"></i-feather>
			<span class="error-message">{{ "PHONE_NUMBER_UPDATE.INVALID_FORMAT" | translate }}</span>
		</div>
		<a href="javascript:void(0)" class="update-button" (click)="openUpdateModal(updateConfirmModal, $event)">Update to
			Continue</a>
	</div>

	<ng-template #updateConfirmModal let-modal>
		<ds-phone-number-update [invoiceIds]="invoiceIds" [invalidPhoneNumber]="invalidPhoneNumber"
			*ngIf="showPhoneNumberUpdate" (displayInvalidPhoneMessage)="showInvalidPhoneMessage($event)"
			(displayUpdatePhoneModal)="displayUpdatePhoneModal($event)"></ds-phone-number-update>
	</ng-template>
</div>