import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import { MethodsCategory } from '@constants/method-category';
import { SidenavComponent } from '@core/components/sidenav/sidenav.component';
import { Auth } from '@core/models/auth';
import { ReturnMethod } from '@core/models/return-method';
import {
  AdConfig,
  HostedReturnsConfig,
  Recommendations,
} from '@core/models/returns-config.interface';
import { ReturnService } from '@features/returns/services/returns.service';
import { NearbyStoresService } from '@services/nearby-store-locations.service';
import { ReturnsConfigService } from '@services/returns-config.service';
import { BaseComponent } from '@shared/components/base.component';
import {
  getAuthResponse,
  getItemList,
  getSanitisedOrderId,
} from '@store/selectors/auth.selector';
import { Item } from '@core/models/item';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { ReturnMethodGroup } from '@core/models/return-method-group';
import { Address } from '@core/models/address';
import { ReturnOrder, ReturnRequest } from '@core/models/return-request';
import { deleteSelectedItems } from '@store/actions/selected-item-list.action';
import { getAllSelectedItems } from '@store/selectors/selected-item-list.selector';
import { SelectedItemDetails } from '@store/states/selected-item-list.state';
import { DropoffTime, PickupTime } from '@core/models/return-smart-windows';
import { DspType } from '@services/dsp.service';

@Component({
  selector: 'app-return-summary',
  templateUrl: './return-summary.component.html',
  styleUrls: ['./return-summary.component.scss'],
})
export class ReturnSummaryComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  recommendations!: Recommendations[] | undefined;
  hostedReturnsConfig!: HostedReturnsConfig;
  rmaIds: string[] = [];
  methodsType = MethodsCategory;
  intervalId!: ReturnType<typeof setInterval>;
  requestDetailsSubscription!: Subscription;
  authResponseSubscription!: Subscription;
  showReturnAnotherItemLink = false;
  authResponse!: Auth | null;

  pageStatus = '';
  showSidenav = false;
  routeDataSubscription!: Subscription;
  @ViewChild(SidenavComponent) sidenavInstance!: SidenavComponent;
  public primaryAds: AdConfig | undefined;
  public secondaryAdsOne: AdConfig | undefined;
  public secondaryAdsTwo: AdConfig | undefined;
  placeholderLoaderArr = [1, 2, 3];
  placeHolderLoader = false;
  urlEntities!: {
    orderExternalId?: string;
    tenantId?: string;
    brandExternalId?: string;
  } | null;
  detailsSubscription!: Subscription;
  itemListSubscription!: Subscription;
  recommendationsSubscription!: Subscription;
  pageParam?: string | null;
  selectedItems: SelectedItemDetails[] = [];
  deleteAllselectedItems = false;
  returnRequestDetails: ReturnRequest[] = [];
  providerInfo?: DspType;
  returnMethod!: ReturnMethod;
  returnMethodsGroup?: ReturnMethodGroup[] = [];
  masterReturnMethods: ReturnMethodGroup[] = [];
  itemList: Item[] = [];
  sanitizedOrderIdDetails!: { orderId: string; sanitizedOrderId: string };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private returnService: ReturnService,
    private store: Store,
    public configService: ReturnsConfigService,
    private nearbyStoreService: NearbyStoresService
  ) {
    super({ hostedReturnService: configService });
  }

  ngOnInit(): void {
    this.routeDataSubscription = combineLatest([
      this.route.data,
      this.route.paramMap,
      this.route.queryParamMap,
    ]).subscribe(([data, params, queryParam]) => {
      if (data['configResp'] === undefined) {
        this.router.navigate(['/service-unavailable'], {
          skipLocationChange: true,
        });
        return;
      }
      this.hostedReturnsConfig = data['configResp'];
      this.pageParam = params.get('status');
      const rmaIds = queryParam.get('rId') || '';
      this.rmaIds = rmaIds.split(',');
      this.init();
    });
  }

  private getReturnMethodGroup(
    itemList: Item[],
    masterMethod: ReturnMethodGroup,
    rmaId: number,
    returnMethodError: boolean,
    returnMethodPolling: boolean,
    status: string,
    returnOrder: ReturnOrder | null,
  ) {
    const item = itemList[0];
    const returnMethodGroup = {
      rmaId,
      returnFee: item?.returnFee,
      labelBoxReturnFee: item?.returnFee,
      error: returnMethodError,
      isPolling: returnMethodPolling,
      address: item?.address,
      status,
      pickupTime: item?.pickupTime,
      dropoffTime: item?.dropoffTime,
      pickupInstructions: item?.pickupInstructions,
      timeZone: item?.timeZone,
      providerInfo: item?.providerInfo,
      itemList,
      returnOrder,
    };
    return { ...masterMethod, ...returnMethodGroup };
  }

  init() {
    this.deleteAllselectedItems = true;
    this.urlEntities = this.getTenantAndBrandExternalId();
    this.setUpAdsConfig(this.hostedReturnsConfig);
    this.setupRecommendations(this.hostedReturnsConfig);
    this.authResponseSubscription = this.store
      .select(getAuthResponse)
      .pipe()
      .subscribe(resp => {
        this.authResponse = resp;
        this.showReturnAnotherItemLink = resp.itemList?.length > 0;
      });
    this.detailsSubscription = this.store
      .select(getSanitisedOrderId)
      .pipe()
      .subscribe(data => {
        this.sanitizedOrderIdDetails = data
          ? data
          : { orderId: '', sanitizedOrderId: '' };
      });

    this.getSelectedItems();
    const multiSkuEnable =
      this.hostedReturnsConfig.componentVisibility.multiSku?.enable;

    if (multiSkuEnable) {
      this.fetchBulkRequestDetailsByRmaId(this.rmaIds, true);
    }
  }

  getItemList() {
    this.itemListSubscription = this.store
      .select(getItemList)
      .pipe()
      .subscribe(resp => {
        this.itemList = resp;
        this.prepareReturnMethod();
      });
  }

  getMasterReturnMethods() {
    if (!NullEmptyChecker.isNonEmptyArray(this.returnRequestDetails)) {
      return;
    }
    const codes: string[] = <string[]>[
      ...new Set(
        this.returnRequestDetails
          .map(item => item?.returnMethod?.code)
          .filter(item => item)
      ),
    ];

    this.placeHolderLoader = true;
    this.returnService.getMasterReturnMethods(codes).subscribe({
      next: methods => {
        this.placeHolderLoader = false;
        methods = methods.filter((method: ReturnMethod) => method.active);
        if (NullEmptyChecker.isNonEmptyArray(methods)) {
          this.masterReturnMethods = methods;
          this.getItemList();
        }
      },
      error: error => {
        this.placeHolderLoader = false;
        console.error('Error while fetching master return methods=>', error);
      },
    });
  }

  prepareReturnMethod() {
    const uniqueLocationIds = <string[]>[
      ...new Set(
        this.returnRequestDetails
          .map(request => request.locationExternalId)
          .filter(id => id)
      ),
    ];
    const locationIdToRmaIdMap: Record<string, number> = {};
    const locationIdToErrorMap: Record<
      string,
      {
        returnMethodPolling: boolean;
        returnMethodError: boolean;
        status: string;
        returnOrder: ReturnOrder | null;
      }
    > = {};
    for (const locationId of uniqueLocationIds) {
      locationIdToRmaIdMap[locationId] = 0;
      locationIdToErrorMap[locationId] = {
        returnMethodPolling: false,
        returnMethodError: false,
        status: '',
        returnOrder: null,
      };
    }

    const returnMethods: ReturnMethodGroup[] = [];
    for (const masterMethod of this.masterReturnMethods) {
      let returnMethodError = false;
      let returnMethodPolling = false;
      let status = '';
      let returnOrder: ReturnOrder | null = null;
      let rmaId = 0;
      const itemList: Item[] = this.returnRequestDetails
        .filter(item => item?.returnMethod?.code === masterMethod.code)
        .map(selectedItem => {
          const address = selectedItem.customerAddress;
          const returnFee = selectedItem.fee;
          const pickupTime = selectedItem.pickupTime;
          const dropoffTime = selectedItem.dropoffTime;
          const pickupInstructions = selectedItem.pickupInstructions;
          const timeZone = selectedItem.timeZone;
          const providerInfo = selectedItem?.returnOrder?.providerInfo;
          if (selectedItem.locationExternalId) {
            locationIdToRmaIdMap[selectedItem.locationExternalId] =
              Number(selectedItem.rmaId) || 0;
            locationIdToErrorMap[selectedItem.locationExternalId] = {
              returnMethodError: selectedItem.status === 'FAILED',
              returnMethodPolling: selectedItem.status === 'POLLING',
              status: selectedItem.originalStatus ?? '',
              returnOrder: selectedItem.returnOrder ?? null,
            };
          } else {
            rmaId = Number(selectedItem.rmaId) || 0;
            returnMethodError = selectedItem.status === 'FAILED';
            returnMethodPolling = selectedItem.status === 'POLLING';
            status = selectedItem.originalStatus ?? '';
            returnOrder = selectedItem.returnOrder ?? null;
          }
          return selectedItem.itemList!.map((childItem: Item) => {
            const returnMethodItem = { ...childItem };

            const item = this.itemList.find(item => item.sku === childItem.sku);
            returnMethodItem.returnFee = returnFee;
            returnMethodItem.address = address;
            returnMethodItem.pickupTime = pickupTime;
            returnMethodItem.dropoffTime = dropoffTime;
            returnMethodItem.pickupInstructions = pickupInstructions;
            returnMethodItem.timeZone = timeZone;
            returnMethodItem.providerInfo = providerInfo;
            returnMethodItem.itemQuantity = returnMethodItem.quantity;
            returnMethodItem.remainingQuantity = item?.remainingQuantity;
            returnMethodItem.image = item?.image;
            returnMethodItem.locationExternalId =
              selectedItem?.locationExternalId;

            return returnMethodItem;
          });
        })
        .flat();
      if (NullEmptyChecker.isNonEmptyArray(itemList)) {
        if (
          NullEmptyChecker.isNonEmptyArray(uniqueLocationIds) &&
          masterMethod.type !== MethodsCategory.KEEP_ITEM &&
          masterMethod.type !== MethodsCategory.RETURN_TO_STORE
        ) {
          // grouping logic based on locationExternalId
          for (const locationId of uniqueLocationIds) {
            const itemsBasedOnLocation = itemList.filter(
              item => item.locationExternalId === locationId
            );
            const locationRmaId = !NullEmptyChecker.isDeepNull(
              locationIdToRmaIdMap,
              locationId
            )
              ? locationIdToRmaIdMap[locationId]
              : 0;
            const locationMethodError = !NullEmptyChecker.isDeepNull(
              locationIdToErrorMap,
              locationId
            )
              ? locationIdToErrorMap[locationId].returnMethodError
              : false;
            const locationMethodPolling = !NullEmptyChecker.isDeepNull(
              locationIdToErrorMap,
              locationId
            )
              ? locationIdToErrorMap[locationId].returnMethodPolling
              : false;
            const locationStatus = !NullEmptyChecker.isDeepNull(locationIdToErrorMap, locationId)
              ? locationIdToErrorMap[locationId].status
              : '';
            const locationReturnOrder = !NullEmptyChecker.isDeepNull(locationIdToErrorMap, locationId)
              ? locationIdToErrorMap[locationId].returnOrder
              : null;
            const group = this.getReturnMethodGroup(
              itemsBasedOnLocation,
              masterMethod,
              locationRmaId,
              locationMethodError,
              locationMethodPolling,
              locationStatus,
              locationReturnOrder,
            );
            if (NullEmptyChecker.isNonEmptyArray(group.itemList))
              returnMethods.push(group);
          }
          // group items with no locationExternalId present together
          const itemsWithoutLocation = itemList.filter(
            item => !item.locationExternalId
          );
          if (NullEmptyChecker.isNonEmptyArray(itemsWithoutLocation)) {
            const group = this.getReturnMethodGroup(
              itemsWithoutLocation,
              masterMethod,
              rmaId,
              returnMethodError,
              returnMethodPolling,
              status,
              returnOrder,
            );
            if (NullEmptyChecker.isNonEmptyArray(group.itemList)) 
              returnMethods.push(group);
          }
        } else {
          // grouping logic based on return method type
          const group = this.getReturnMethodGroup(
            itemList,
            masterMethod,
            rmaId,
            returnMethodError,
            returnMethodPolling,
            status,
            returnOrder,
          );
          if (NullEmptyChecker.isNonEmptyArray(group.itemList))
            returnMethods.push(group);
        }
      }
    }
    this.returnMethodsGroup = returnMethods
      .filter(returnMethod => returnMethod.itemList)
      .sort((methodA, methodB) =>
        methodA.error === methodB.error ? 0 : methodA.error ? 1 : -1
      );
  }

  printLabels(returnMethodCode: string) {
    const resp = this.returnRequestDetails.find(
      resp => resp.returnMethod.code === returnMethodCode
    );
    const labelLink = resp?.returnOrder?.labelLink;
    if (labelLink) window.open(labelLink, '_blank');
  }

  navToReturnSummaryDetails(rmaId: string) {
    this.deleteAllselectedItems = false;
    const orderId = encodeURIComponent(
      btoa(`${this.urlEntities?.orderExternalId}`)
    );
    const urlParamsLink = `/${this.urlEntities?.tenantId}/${this.urlEntities?.brandExternalId}/${orderId}`;
    const queryParams = { rId: this.rmaIds.join(',') };
    this.router.navigate(
      [`/${urlParamsLink}/main/return-summary-details/${rmaId}`],
      { queryParams }
    );
  }

  navToItemList() {
    this.deleteAllselectedItems = false;
    const orderExternalId = this.urlEntities?.orderExternalId;
    const orderId = encodeURIComponent(btoa(`${orderExternalId}`));
    const urlParamsLink = `/${this.urlEntities?.tenantId}/${this.urlEntities?.brandExternalId}/${orderId}`;
    this.router.navigate([`/${urlParamsLink}/main/itemlist`]);
  }

  private setUpAdsConfig(config: HostedReturnsConfig) {
    this.secondaryAdsOne = config.components.ads.find(ads => {
      return ads.active && ads.type === 'secondaryOne';
    });

    this.secondaryAdsTwo = config.components.ads.find(ads => {
      return ads.active && ads.type === 'secondaryTwo';
    });

    this.primaryAds = config.components.ads.find(ads => {
      return ads.active && ads.type === 'primary';
    });
  }

  public setupRecommendations(hostedReturnsConfig: HostedReturnsConfig) {
    const recommendations = hostedReturnsConfig.components?.recommendations;
    const orderId = this.authResponse
      ? this.authResponse.invoiceInfo.groupId ??
      this.authResponse.invoiceInfo.brandExternalId
      : '';

    if (recommendations && recommendations.active) {
      if (recommendations.url) {
        this.recommendationsSubscription = this.configService
          .fetchExternalRecommendations(recommendations.url, orderId)
          .subscribe((result?: Recommendations[]) => {
            if (Array.isArray(result) && result.length) {
              this.recommendations = result;
            }
          });
      } else {
        this.recommendations =
          hostedReturnsConfig.components?.recommendations?.jsonFileId;
      }
    }
  }

  getSelectedItems() {
    this.store
      .select(getAllSelectedItems)
      .pipe()
      .subscribe(selectedItems => {
        if (!NullEmptyChecker.isNull(this.sanitizedOrderIdDetails.orderId))
          this.selectedItems =
            selectedItems[this.sanitizedOrderIdDetails.sanitizedOrderId];
      });
  }

  fetchBulkRequestDetailsByRmaId(
    rmaIds: string[],
    checkIsPollingNeeded?: boolean
  ) {
    this.requestDetailsSubscription = this.returnService
      .getBulkRequestDetailsById(rmaIds)
      .subscribe({
        next: (respDatas: ReturnRequest[]) => {
          this.returnRequestDetails = respDatas;

          if (checkIsPollingNeeded) {
            this.getMasterReturnMethods();
            this.handleFailedReturnRequest();
          }

          const pollReturnRequestDetails = respDatas.filter(
            returnRequest =>
              this.isPollingNeeded(returnRequest) &&
              returnRequest.status !== 'FAILED'
          );

          this.returnRequestDetails = respDatas.map(returnRequest => {
            returnRequest.originalStatus = returnRequest.status;
            const pollRequest = !!pollReturnRequestDetails.find(pollRequest => {
              return (
                pollRequest.rmaId === returnRequest.rmaId &&
                returnRequest.status !== 'FAILED'
              );
            });

            if (pollRequest) {
              returnRequest.status = 'POLLING';
            }

            return returnRequest;
          });

          const shouldCallInternval = !!pollReturnRequestDetails.length;

          if (!shouldCallInternval && this.intervalId) {
            clearInterval(this.intervalId);
            this.prepareReturnMethod();
          }

          if (checkIsPollingNeeded && shouldCallInternval) {
            this.callInterval();
            return;
          }

          this.pageStatus = shouldCallInternval ? 'processing' : 'success';
        },
        error: err => {
          if (this.intervalId) clearInterval(this.intervalId);
          console.error('error while fetching request=>', err);
        },
      });
  }

  handleFailedReturnRequest() {
    const failedReturnRequests = this.returnRequestDetails.filter(
      returnRequestDetail => returnRequestDetail.status === 'FAILED'
    );

    if (NullEmptyChecker.isNonEmptyArray(this.selectedItems)) {
      for (const selectedItem of this.selectedItems) {
        const failedReturnRequest = !!failedReturnRequests.find(
          failedReturn =>
            failedReturn.returnMethod.code === selectedItem.returnMethod?.code
        );

        if (!failedReturnRequest && this.urlEntities?.orderExternalId) {
          this.store.dispatch(
            deleteSelectedItems({
              selectedItemRefIds: [selectedItem.__refId],
              orderExternalId: this.sanitizedOrderIdDetails?.sanitizedOrderId,
            })
          );
        }
      }
    }
  }

  isPollingNeeded(resp: ReturnRequest) {
    const returnOrder = resp.returnOrder;
    const returnMethod = resp.returnMethod;
    let isPollingNeededFlag = false;
    if (
      returnMethod.type === this.methodsType.SHIP_IT_BACK ||
      returnMethod.type === this.methodsType.HOME_PICKUP
    ) {
      if (!returnOrder) {
        isPollingNeededFlag = true;
      } else if (returnOrder && !returnOrder.labelLink) {
        isPollingNeededFlag = true;
      }
    } else if (this.isQRCodeMethodType(returnMethod.type)) {
      if (!returnOrder) {
        isPollingNeededFlag = true;
      } else if (returnOrder && !returnOrder?.scanCodeLink) {
        isPollingNeededFlag = true;
      }
    }

    return isPollingNeededFlag;
  }

  callInterval() {
    this.pageStatus = 'processing';
    this.intervalId = setInterval(() => {
      this.fetchBulkRequestDetailsByRmaId(this.rmaIds, false);
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.routeDataSubscription) this.routeDataSubscription.unsubscribe();
    if (this.itemListSubscription) this.itemListSubscription.unsubscribe();
    if (this.requestDetailsSubscription)
      this.requestDetailsSubscription.unsubscribe();
    if (this.authResponseSubscription)
      this.authResponseSubscription.unsubscribe();
    if (this.recommendationsSubscription)
      this.recommendationsSubscription.unsubscribe();
    if (this.intervalId) clearInterval(this.intervalId);

    if (this.urlEntities?.orderExternalId && this.deleteAllselectedItems)
      this.store.dispatch(
        deleteSelectedItems({
          selectedItemRefIds: this.selectedItems.map(item => item.__refId),
          orderExternalId: this.urlEntities?.orderExternalId,
        })
      );
    if (this.detailsSubscription) this.detailsSubscription.unsubscribe();
  }

  openDropOffLocations() {
    this.showSidenav = true;
  }

  closeDropOffLocations() {
    this.showSidenav = false;
  }

  isQRCodeMethodType(returnMethod?: string): boolean {
    if (
      returnMethod &&
      [
        this.methodsType.HOME_PICKUP_QR_CODE.toString(),
        this.methodsType.HOME_PICKUP_QR_CODE_BOX.toString(),
        this.methodsType.SHIP_IT_BACK_QR_CODE.toString(),
        this.methodsType.SHIP_IT_BACK_QR_CODE_BOX.toString(),
      ].includes(returnMethod)
    ) {
      return true;
    }

    return false;
  }
}
