import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-content-box',
  templateUrl: './content-box.component.html',
  styleUrls: ['./content-box.component.scss'],
})
export class ContentBoxComponent {
  @Input() heading = '';
  @Input() subHeading = '';
  @Input() content = '';
  @Input() extraContent = '';
  @Input() lastItem = false;
  @Input() firstItem = false;

  /**
   * This "feeConfig" input property is define here because
   * we want to show the fee along with the method name.
   * treat it as a configuration of fee
   * { showFee } will decide that fee should be visible or not
   * { fee } the actual value of fee
   */
  @Input() feeConfig!: { showFee: boolean; fee: any };
}
