<div class="item-list-box return-options">
	<div class="col-12 mx-auto text-center">
		<div *ngIf="status === 'error'" class="status-img-wrapper">
			<img *ngIf="status === 'error'" [src]="'assets/images/request-error.svg'" class="error-img" />
		</div>
		<div>
			<div class="request-status content-common-color" [innerHTML]="status === 'error' ? errorMsg : ''"
				*ngIf="showMessage"></div>
			<div class="order-id-detail">
				<div class="qr-code d-flex justify-content-center align-items-center mx-auto"
					*ngIf="showQrCode && scanCodeLink">
					<img [src]="scanCodeLink ? scanCodeLink : 'assets/images/qr-code.svg'" />
				</div>
			</div>
		</div>
		<div class="mb-3 pickup-window-section" *ngIf="showPickupWindow">
			<div class="pickup-window-heading">Pickup Window</div>
			<div class="date-and-timing py-1">
				{{timeStr}}
			</div>
		</div>
		<div class="print-label-icon pt-0 user-select-none" *ngIf="showPrintLabelOption">
			<img src="assets/icons/printer.svg" alt="Print label Icon" />
		</div>
		<button type="button" class="btn btn-ds-primary solid print-label-btn user-select-none"
			[ngClass]="{'mb-3': returnAnotherItemOption}" *ngIf="showPrintLabelOption" (click)="printLabel()"
			[captureEvent]="'click-print-label'">
			Print Label
		</button>
		<a type="button"
			class="btn btn-ds-primary solid d-flex align-items-center justify-content-center user-select-none"
			*ngIf="showQrCode && scanCodeLink" download (click)="downloadScanCodeLink()"
			[captureEvent]="'click-download-scan-code'">
			Download
		</a>
		<div class="try-again-message" *ngIf="status === 'error' && !errorCode">
			Please <a class="try-again-contact-us-link" [captureEvent]="'click-try-again-return'" [routerLink]="[returnsUrl]">{{"ERROR_MESSAGE.TRY_AGAIN" | translate}}</a> or
			<!-- Conditional rendering for clickable link -->
			<ng-container *ngIf="isContactLink; else plainText">
				<a [href]="contactUrl" target="_blank" class="try-again-contact-us-link">{{"ERROR_MESSAGE.CONTACT_US" | translate}}</a>
			</ng-container>
			<!-- Plain text fallback -->
			<ng-template #plainText>
				<span class="contact-us-no-link">{{"ERROR_MESSAGE.CONTACT_US" | translate}}</span>
			</ng-template>
		</div>
		<div class="order-id-detail another-item d-flex justify-content-center">
			<a type="button" *ngIf="returnAnotherItemOption || errorCode" href="javascript:void(0)"
				class="btn btn-ds-primary grey-shade d-flex justify-content-center align-items-center text-decoration-none user-select-none"
				[routerLink]="[urlParamsLink+'/main/itemlist']" [captureEvent]="'click-return-another-item'">
				<span>
					Return Another Item
				</span>
			</a>

		</div>
	</div>
</div>