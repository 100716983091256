import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NullEmptyChecker } from '@deliverysolutions/utils';

@Injectable({
  providedIn: 'root',
})
export class RedirectorService {
  constructor(private router: Router) {}

  redirectToPage(resp: any, entities: any) {
    const orderId = encodeURIComponent(btoa(`${entities?.orderExternalId}`));
    const preUrl = `/${entities?.tenantId}/${entities?.brandExternalId}/${orderId}`;
    // checking if returnEligibility is true & itemlist is empty
    if (
      resp &&
      !NullEmptyChecker.isNonEmptyArray(resp.itemList) &&
      resp.returnEligibility
    ) {
      this.router.navigate([`${preUrl}/main/returns`]);
    } else if (resp && NullEmptyChecker.isNonEmptyArray(resp.itemList)) {
      // if itemlist is not empty
      this.router.navigate([`${preUrl}/main/itemlist`]);
    } else if (
      resp &&
      !NullEmptyChecker.isNonEmptyArray(resp.itemList) &&
      !resp.returnEligibility &&
      NullEmptyChecker.isNonEmptyArray(resp.returnRequests)
    ) {
      // if itemlist is empty, return eligibility is false and return request is placed
      const returnRequestDefaultValue = 1;
      if (resp.returnRequests.length > returnRequestDefaultValue) {
        this.router.navigate([`${preUrl}/main/return-details`]);
      } else {
        const rmaId = resp.returnRequests[0].rmaId;
        this.router.navigate([
          `${preUrl}/main/request-placed/return-order-details/${rmaId}`,
        ]);
      }
    } else if (
      resp &&
      !NullEmptyChecker.isNonEmptyArray(resp.itemList) &&
      !resp.returnEligibility &&
      !NullEmptyChecker.isNonEmptyArray(resp.returnRequests)
    ) {
      // if itemlist is empty, return eligibility is false and no return request is placed
      // show common error page
      const errorCode = encodeURIComponent(btoa(btoa('E001')));
      this.router.navigate([`${preUrl}/main/error-page/${errorCode}`]);
    }
  }
}
