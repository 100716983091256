
/**
 * @method:- getPlaceInfo(passing place info as object) ==
 * utlity that helps to process the placeinfo and filter out city, state,postal_code,street from placeinfo.
 */
export class AddressAutoCompletion {
  static getPlaceInfo(event: any) {
    const placesInfo = {
      postal_code: -1,
      city: -1,
      street_number: -1,
      street: -1,
      state: -1,
      country: -1,
      lat: -1,
      lng: -1,
    };

    const addressLen = event.address_components && Array.isArray(event.address_components)
      ? event.address_components.length : 0;

    for (let index = 0; index < addressLen; index++) {
      if (event.address_components[index].types.includes('postal_code')) {
        placesInfo.postal_code = index;
      }
      if (event.address_components[index].types[0] === 'locality') {
        placesInfo.city = index;
      } else if (event.address_components[index].types.includes('sublocality')) {
        placesInfo.city = index;
      } else if (event.address_components[index].types.includes('postal_town')) {
        placesInfo.city = index;
      }
      if (event.address_components[index].types[0] === 'street_number') {
        placesInfo.street_number = index;
      }
      if (event.address_components[index].types[0] === 'route' || event.address_components[index].types[0] === 'premise') {
        placesInfo.street = index;
      }
      if (event.address_components[index].types[0] === 'administrative_area_level_1') {
        placesInfo.state = index;
      }
      if (event.address_components[index].types[0] === 'country') {
        placesInfo.country = index;
      }
    }
    if (event.geometry && event.geometry.location) {
      placesInfo.lat = 1;
      placesInfo.lng = 1;
    }
    return placesInfo;
  }

  static prepareAddressJson(event: any, placesInfo: any) {

    const postalCode = placesInfo.postal_code >= 0 ? event.address_components[placesInfo.postal_code].long_name : '';
    const cityName = placesInfo.city >= 0 ? event.address_components[placesInfo.city].long_name : '';
    const streetNumber = placesInfo.street_number >= 0 ? event.address_components[placesInfo.street_number].long_name : '';
    const street = placesInfo.street >= 0 ? event.address_components[placesInfo.street].long_name : '';
    const stateName = placesInfo.state >= 0 ? event.address_components[placesInfo.state].long_name : '';
    const countryName = placesInfo.country >= 0 ? event.address_components[placesInfo.country].long_name : '';
    const latitude = placesInfo.lat >= 0 ? typeof event.geometry.location.lat === "function" ? event.geometry.location.lat() : event.geometry.location.lat : 0;
    const longitude = placesInfo.lng >= 0 ? typeof event.geometry.location.lng === "function" ? event.geometry.location.lng() : event.geometry.location.lng : 0;

    return { 'postalCode': postalCode, 'cityName': cityName, 'streetNumber': streetNumber, 'street': street, 'stateName': stateName, 'countryName': countryName, 'latitude': latitude, 'longitude': longitude };
  }

  static prepareAddressJsonForGeocode(event: any, placesInfo: any) {

    const postalCode = placesInfo.postal_code >= 0 ? event.address_components[placesInfo.postal_code].long_name : '';
    const cityName = placesInfo.city >= 0 ? event.address_components[placesInfo.city].long_name : '';
    const streetNumber = placesInfo.street_number >= 0 ? event.address_components[placesInfo.street_number].long_name : '';
    const street = placesInfo.street >= 0 ? event.address_components[placesInfo.street].long_name : '';
    const stateName = placesInfo.state >= 0 ? event.address_components[placesInfo.state].long_name : '';
    const countryName = placesInfo.country >= 0 ? event.address_components[placesInfo.country].long_name : '';
    const latitude = placesInfo.lat >= 0 ? event.geometry.location.lat : 0;
    const longitude = placesInfo.lng >= 0 ? event.geometry.location.lng : 0;

    return { 'postalCode': postalCode, 'cityName': cityName, 'streetNumber': streetNumber, 'street': street, 'stateName': stateName, 'countryName': countryName, 'latitude': latitude, 'longitude': longitude };
  }

}

