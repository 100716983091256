import { createAction, props } from '@ngrx/store';

export const TOAST_SUCCESS_ACTION = '[success toast] show success toast';

export const showToastSuccess = createAction(
  TOAST_SUCCESS_ACTION,
  props<{
    showToast: boolean;
    successMsg: string;
    close?: boolean;
    data?: Record<string, any>;
    timeoutMs?: number;
  }>()
);
