import { Component, Input } from '@angular/core';
/**
 * Adds a searchbox to ng-select dropdown, written to work within ng-select component
 */
@Component({
  selector: 'ds-ngselect-searchbox',
  templateUrl: './ds-ngselect-searchbox.component.html',
})
export class DSNgSelectSearchboxComponent {
  @Input() selectElement: any;

  constructor() {}

  onClear(inputSelect: any) {
    this.selectElement.filter('');
    inputSelect.value = '';
  }

  onInputChange(e: any) {
    const input = e.target as HTMLInputElement;
    this.selectElement.filter(input.value);
  }
}
