<div class="container-fluid item-box p-0"
	[ngClass]="{'border-0': lastItem, 'border-bottom': !lastItem,'is-selected-item': isSelected ,'is-previous-item-selected': isPreviousSelected}"
	*ngIf="!showLoader && !itemReturnedView; else loadingItem">
	<div class="row item-row g-0" [class.item-row-small]="smallItemView">
		<div class="col col-auto pe-0">
			<div class="container user-select-none ps-0">
				<img [src]="itemOb.image || '/assets/icons/package-item-placeholder.svg'" alt="item placeholder"
					class="img-fluid" [class.item-img]="!smallImage" [class.item-img-small]="smallImage" />
			</div>
		</div>

		<div class="col w-50" [class.d-flex]="centeredTitle" [class.align-items-center]="centeredTitle"
			[class.p-0]="centeredTitle">
			<div class="row" [class.mb-0]="centeredTitle" [class.mb-2]="!centeredTitle">
				<a href="javascript:void(0)" *ngIf="showModal" (click)="openModal(infoModal)"
					class="return-details-button" [captureEvent]="'click-preview-item-details'">{{itemOb.title}}</a>
				<span *ngIf="!showModal" class="item-name">{{itemOb.title}}</span>
			</div>
			<div class="row mb-2" *ngIf="itemOb.itemQuantity && !hideKeys['quantity'] && !itemHiddenFields['quantity']">
				<div class="col" *ngIf="!summaryView">
					<div class="item-details item-qauntity mt-2">
						<span class="item-details-title">Qty:</span>
						{{itemOb.itemQuantity}}
					</div>
					<div class="item-details selected-item-qauntity mt-2">
						Returning {{selectedItemQuantity}} of {{itemOb.itemQuantity}}</div>
				</div>
				<div class="col d-flex justify-content-between item-details" *ngIf="summaryView">
					<span class="item-details-title">Quantity:</span>
					{{itemOb.itemQuantity}}
				</div>
			</div>
			<div class="row mb-2">
				<div class="col item-details">
					<div class="row gap-2">
						<div *ngIf="(returnFee || returnFee === 0) && !hideKeys['fee']" class="d-flex item-details"
							[class.justify-content-between]="summaryView">
							<span class="pe-1 item-details-title">Fee:</span>
							{{ ((returnFee | typeof) === 'number' && returnFee !==0) ?
							(returnFee / 100 | currency: 'USD':'symbol':'1.2-2') : 'Free'}}
						</div>
						<div
							*ngIf="itemOb.returnReason?.name && !hideKeys['returnReason'] && !itemHiddenFields['returnReason']">
							<span class="item-details-title">Return Reason:</span>
							{{itemOb.returnReason?.name}}
						</div>
						<div
							*ngIf="itemOb.returnReason?.proofOfReturnReason && !hideKeys['returnReason'] && !itemHiddenFields['returnReason']">
							<span class="item-details-title">
								{{'RETURN_REQUEST.PROOF_FOR_RETURN_REASON' | translate}}:
							</span>
							<div class="d-flex py-2 stack-images">
								<ng-container
									*ngFor="let proof of itemOb.returnReason?.proofOfReturnReason; index as i">
									<img tabindex="0" class="rounded border border-2 cursor-pointer border-white"
										[class.cursor-pointer]="!itemHiddenFields?.previewImgModal"
										(keydown)="!itemHiddenFields?.previewImgModal && openModalComponent(previewModal, i)"
										(click)="!itemHiddenFields?.previewImgModal && openModalComponent(previewModal, i)"
										[id]="proof.fileId" [src]="proof.fileUrl || proof" alt="proof-of-return-reason"
										width="40px" height="40px" />
								</ng-container>
							</div>
						</div>
						<div
							*ngIf="itemOb.refundMode?.name && !hideKeys['refundMode'] && !itemHiddenFields['refundMode']">
							<span class="item-details-title">Refund Method:</span>
							{{itemOb.refundMode?.name}}
						</div>
					</div>
				</div>
			</div>
			<div class="row mobile-show" *ngIf="showActionables && !hideKeys['returnDetailsButton']">
				<a [routerLink]="[returnDetailsUrl]" class="return-details-button"
					[captureEvent]="'click-return-details'">Return Details</a>
			</div>
		</div>

		<div *ngIf="!smallItemView" class="w-100 mb-3 mobile-show"></div>
		<div class="col col-auto d-flex align-items-start user-select-none return-button-col"
			*ngIf="showActionables && itemOb.returnEligibility && !hideKeys['returnButton']">
			<a (click)="onReturnButtonClick()" class="return-button return-main-button"
				[captureEvent]="'click-return-item-href'">Return
				<i-feather *ngIf="multiSkuEnabled" class="ms-1" name="plus"></i-feather>
			</a>
			<a [routerLink]="[returnsUrl]" class="return-button return-edit-button me-2"
				[captureEvent]="'click-edit-return-item'">Edit</a>
			<a href="javascript:void(0)" type="button" class="return-cancel-button" (click)="onCancelButtonClick()"
				[captureEvent]="'click-cancel-return-item'">Cancel</a>
		</div>
		<div class="col col-auto d-flex align-items-start mobile-hide"
			*ngIf="showActionables && !hideKeys['returnDetailsButton']">
			<a [routerLink]="[returnDetailsUrl]" class="return-details-button"
				[captureEvent]="'click-return-details'">Return Details</a>
		</div>
		<div class="row pt-2 g-0"
			*ngIf="itemOb.returnIlleligibleReason && !itemOb.returnEligibility && !itemOb.returnRequest?.length">
			<div class="col d-inline-flex gap-1 return-reason">
				<i-feather class="ms-3 me-1 return-reason-icon" name="slash"></i-feather>
				{{itemOb.returnIlleligibleReason}}
			</div>
		</div>
	</div>
</div>
<div class="container-fluid item-box-returned p-0" *ngIf="itemReturnedView">
	<div class="align-self-start">
		<a href="javascript:void(0)" *ngIf="showModal" (click)="openModal(infoModal, $event)"
			class="return-details-button" [captureEvent]="'click-preview-item-details'">{{itemOb?.title}}</a>
		<span *ngIf="!showModal" class="item-name">{{itemOb?.title}}</span>
		<div class="item-details item-qauntity mt-2">
			<span class="item-details-title">Qty:</span>
			{{itemOb?.quantity}}
		</div>
	</div>
</div>

<div class="modal-overlay" id="modalOverlay" #infoModalMobile *ngIf="!showLoader" (click)="closeMobileModal($event)">
	<div class="modal-mobile inactive mobile-show" (click)="false; $event.stopPropagation();">
		<div class="row" style="height: 2rem;">
			<div class="col d-flex justify-content-center align-items-center">
				<div class="modal-mobile-close-btn" (click)="closeMobileModal($event)"></div>
			</div>
		</div>
		<div class="row">
			<div class="col modal">
				<ng-container *ngTemplateOutlet="infoModal"></ng-container>
			</div>
		</div>
	</div>
</div>


<ng-template #previewModal let-modal>
	<app-ds-modal (close)="closeModalComponent()">
		<!-- Body content projection -->
		<div class="d-flex justify-content-end align-items-end p-3 w-100 h-100" modal-header-content>
			<i-feather name="x" (click)="modal.dismiss('Cross click')"></i-feather>
		</div>

		<!-- Body content projection -->
		<div class="user-select-none p-2 w-100 h-100" modal-body-content>
			<swiper-container id="previewModalSwiper" *ngIf="itemOb.returnReason?.proofOfReturnReason?.length" appSwiper
				#swiper [pagination]="true" [config]="swiperConfig" init="false">
				<swiper-slide *ngFor="let proof of itemOb.returnReason?.proofOfReturnReason">
					<div class="d-flex justify-content-center align-items-center swiper-wrapper">
						<!-- Start Request Status -->
						<img class="rounded border border-2 cursor-pointer border-white preview-image"
							[src]="proof.fileUrl || proof" alt="proof-of-return-reason" />
						<!-- End -->
					</div>
				</swiper-slide>
			</swiper-container>
		</div>

		<!-- Footer content projection -->
		<div class="d-flex justify-content-start align-items-start p-3 w-100 h-100" modal-footer-content>
			<div class="d-flex justify-content-between align-content-between w-100 swiper swiper-pagination-container">
				<i-feather [class.swiper-pagination-disabled]="this.swiperPageIndex-1 === 0"
					[class.cursor-pointer]="this.swiperPageIndex-1 !== 0" class="d-flex swiper-pagination-btn"
					name="arrow-left" (click)="paginatePreviewModal('PREV')"></i-feather>
				<div class="user-select-none swiper-pagination">
					{{this.swiperPageIndex}} of {{itemOb.returnReason!.proofOfReturnReason!.length}}
				</div>
				<i-feather
					[class.swiper-pagination-disabled]="itemOb.returnReason!.proofOfReturnReason!.length === this.swiperPageIndex"
					[class.cursor-pointer]="itemOb.returnReason!.proofOfReturnReason!.length !== this.swiperPageIndex"
					class="d-flex swiper-pagination-btn" name="arrow-right"
					(click)="paginatePreviewModal('NEXT')"></i-feather>
			</div>
		</div>
	</app-ds-modal>
</ng-template>

<ng-template #infoModal let-modal>
	<div class="modal-header">
		<div class="item-box">
			<img [src]="itemOb.image || '/assets/icons/package-item-placeholder.svg'" alt="item placeholder"
				class="img-fluid item-img-small" />
			<span class="modal-title item-name text-decoration-underline ps-3" id="modal-basic-title"
				*ngIf="itemOb.title">{{itemOb.title}}</span>
		</div>
		<i-feather class="mobile-hide" name="x" (click)="modal.dismiss('Cross click')"></i-feather>
	</div>
	<div class="modal-body">
		<div class="row mb-2" *ngIf="!itemHiddenFields['sku'] && itemOb.sku">
			<div class="col col-md-3">SKU</div>
			<div class="col">{{itemOb.sku}}</div>
		</div>
		<div class="row mb-2" *ngIf="!itemHiddenFields['quantity'] && itemOb.quantity">
			<div class="col col-md-3">Quantity</div>
			<div class="col">{{itemOb.quantity}}</div>
		</div>
		<div class="row mb-2" *ngIf="!itemHiddenFields['dimensions'] && itemOb?.size">
			<div class="col col-md-3">Dimensions</div>
			<div class="col">{{itemOb?.size?.length}}"L x {{itemOb?.size?.width}}"W x {{itemOb?.size?.height}}"H
			</div>
		</div>
		<div class="row mb-2" *ngIf="!itemHiddenFields['weight'] && itemOb.weight">
			<div class="col col-md-3">Weight</div>
			<div class="col">{{itemOb.weight}}</div>
		</div>
		<div class="row mb-2" *ngIf="!itemHiddenFields['price'] && itemOb.price">
			<div class="col col-md-3">Price</div>
			<div class="col" [class.text-decoration-line-through]="itemOb.sale_price">${{itemOb.price}}</div>
		</div>
		<div class="row mb-2" *ngIf="!itemHiddenFields['salesPrice'] && itemOb.sale_price">
			<div class="col col-md-3">Sale Price</div>
			<div class="col">${{itemOb.sale_price}}</div>
		</div>
		<div class="row mb-2" *ngIf="!itemHiddenFields['description'] && itemOb.description">
			<div class="col col-md-3">Description</div>
			<div class="col">{{itemOb.description}}</div>
		</div>
	</div>
</ng-template>

<ng-template #loadingItem>
	<div class="row flex-column item-box p-3 gx-0 mb-md-3 placeholder-loading" *ngIf="showLoader">
		<div class="d-flex">
			<div class="col-auto img-box linear-background"></div>
			<div class="item-details-box w-100 ps-2">
				<div class="d-flex justify-content-between mb-2">
					<div class="w-100 data-row  linear-background"></div>
				</div>
				<div class="mb-2 col-12 col-md-6">
					<div class="w-100 data-row  linear-background"></div>
				</div>
				<div class="d-flex mb-2">
					<div class="col-12 col-md-6 data-row linear-background"></div>
					<div class="d-none d-md-block col-md-1"></div>
					<div class="d-none d-md-block col-md-5 data-row linear-background"></div>
				</div>
				<div class="d-flex">
					<div class="col-12 col-md-6 data-row linear-background"></div>
					<div class="d-none d-md-block col-md-1"></div>
					<div class="d-none d-md-block col-md-5 ms-md-0 ms-2 data-row linear-background"></div>
				</div>

			</div>
		</div>
		<div class="options-row d-flex justify-content-between align-items-center mt-2 mt-md-3">
			<div class="col-6"></div>
			<div class="col-6 data-row data-row-ex linear-background"></div>
		</div>
	</div>
</ng-template>