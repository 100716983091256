<div class="return-smart-windows-container">
	<div class="d-flex flex-column overflow-hidden return-smart-windows-wrapper">
		<div class="d-flex gap-4 flex-column p-3 pb-0 return-smart-windows-content">
			<div class="d-flex gap-4 flex-column return-smart-windows-header">
				<div class="d-flex justify-content-center user-select-none f-18px calendar-header">
					{{monthTitle}}
				</div>
				<div class="d-flex overflow-x-auto">
					<div class="d-md-flex d-none justify-content-center align-items-center swiper-button-prev-nav">
						<i-feather name="chevron-left"></i-feather>
					</div>
					<swiper-container appSwiper #swiper [config]="swiperConfig" init="false" class="swiper-container">
						<swiper-slide *ngFor="let dateFragemen of dateFragements">
							<div class="d-flex justify-content-center align-items-center swiper-wrapper">
								<app-ds-button class="ds-button-calendar"
									(byButtonClick)="selectReturnSmartWindowsDate(dateFragemen)"
									[selected]="selectedDate.date === dateFragemen.date"
									[buttonClass]="'ds-button-calendar'" [buttonStyle]="{
										width: '100%',
										height: '100%',
										borderColor:'lightgray',
										padding:'unset',
										}">
									<div
										class="d-flex flex-column align-items-center justify-content-center calender-item">
										<div class="calender-day"
											[ngClass]="{'calender-date-selected':selectedDate.date === dateFragemen.date}">
											{{dateFragemen.day}}</div>
										<div class="calender-date"
											[ngClass]="{'calender-date-selected':selectedDate.date === dateFragemen.date}">
											{{dateFragemen.date}}</div>
									</div>
								</app-ds-button>
							</div>
						</swiper-slide>
					</swiper-container>
					<div class="d-md-flex d-none justify-content-center align-items-center swiper-button-next-nav">
						<i-feather name="chevron-right"></i-feather>
					</div>
				</div>
			</div>
			<div class="d-flex flex-column gap-4 overflow-auto user-select-none return-smart-windows-body">
				<ng-container
					*ngIf="selectedDateReturnSmartWindows.length && !returnSmartWindowsLoader; else noDataAvailable">
					<div class="d-flex flex-column gap-4 return-smart-windows-time-slots">
						<ng-container *ngIf="selectedReturnSmartWindow">
							<app-ds-button
								[buttonStyle]="{width: '100%', height:'3.5rem', borderColor:'lightgray', borderRadius:'0.5rem'}"
								[disabledHoverEffect]="true">
								<div
									class="d-flex justify-content-between align-items-center return-smart-windows-time-slot">
									<div class="d-flex gap-lg-3 gap-md-2 gap-3 align-items-center">
										<div class="check-box-container">
											<div class="d-flex align-items-center round">
												<input type="radio" name="smartWindowRadio"
													(click)="$event.stopPropagation()" [checked]="true"
													[id]="'smartWindowRadioId'">
												<label class="fs-6" [for]="'smartWindowRadioId'">
												</label>
											</div>
										</div>
										<span class="ps-2">
											{{getEpochToFormatedTime(selectedReturnSmartWindow)}}
										</span>
									</div>
									<i-feather class="d-flex close-icon" (click)="deselectReturnSmartWindow()"
										[name]="'X'"></i-feather>
								</div>
							</app-ds-button>
						</ng-container>
						<ng-container *ngIf="!selectedReturnSmartWindow">
							<app-ds-button *ngFor="let windows of selectedDateReturnSmartWindows; let  i = index"
								(byButtonClick)="selectReturnSmartWindow(windows)"
								[buttonStyle]="{width: '100%', height:'3.5rem', borderColor:'lightgray', borderRadius:'0.5rem'}"
								[disabledHoverEffect]="true">
								<div
									class="d-flex justify-content-between align-items-center return-smart-windows-time-slot">
									<div class="d-flex gap-lg-3 gap-md-2 gap-3 align-items-center">
										<div class="check-box-container">
											<div class="d-flex align-items-center round">
												<input type="radio" name="smartWindowRadio"
													(click)="selectReturnSmartWindow(windows); $event.stopPropagation()"
													[checked]="selectedReturnSmartWindow === windows"
													[id]="'smartWindowRadioId'+i">
												<label class="fs-6" [for]="'smartWindowRadioId'+i">
												</label>
											</div>
										</div>
										<span class="ps-2">
											{{getEpochToFormatedTime(windows)}}
										</span>
									</div>
								</div>
							</app-ds-button>
						</ng-container>
					</div>
				</ng-container>
				<div class="user-select-none return-smart-windows-pickup-instructions"
					[class.d-none]="!selectedDateReturnSmartWindows.length || returnSmartWindowsLoader">
					<span class="font-weight-500 fs-6">{{ "SMART_WINDOW.PICKUP_INSTRUCTIONS" | translate }}:</span>
					<textarea class="w-100 rounded border return-smart-window-input-textfield"
						[ngModel]="pickupInstruction" (ngModelChange)="setPickupInstruction($event)"
						name="pickupInstructions" rows="3" id="pickupInstructionsTextarea"></textarea>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-center align-items-center p-3 return-smart-windows-footer">
			<app-ds-button [disabled]="!selectedReturnSmartWindow" [buttonText]="'Save'"
				[buttonStyle]="{width: '17rem'}" [selected]="true"
				(byButtonClick)="saveReturnSmartWindowSlot()"></app-ds-button>
		</div>
	</div>
</div>

<ng-template #noDataAvailable>
	<div *ngIf="!returnSmartWindowsLoader"
		class="d-flex gap-2 flex-column justify-content-center align-items-center return-smart-windows-content-no-data">
		<img [src]="'/assets/icons/calender-no-date.svg'" alt="no-availability-img" />
		<span class="fs-6">Sorry, no availability.</span>
	</div>
	<div *ngIf="returnSmartWindowsLoader" class="loader-content-wrapper">
		<div *ngFor="let n of [1,2,3,4]" class="loader-placeholder pb-2">
			<div class="loader-animated-background"></div>
		</div>
	</div>
</ng-template>