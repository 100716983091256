import { Component, OnInit } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { StorageService } from './core/services/storage.service';
import { ReturnsConfigService } from './core/services/returns-config.service';
import { LoaderService } from './core/services/loader.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { ClevertapAnalyticsService } from './core/services/clevertap-analytics.service';

import { getOrderExternalIdFromUrl } from './store/selectors/order-id.selector';
import { OrderExternalIdState } from './store/states/order-id.state';
import { loginSuccess } from './store/actions/auth.action';
import { getConfig } from './store/selectors/config.selector';
import defaultLanguage from '@assets/i18n/en.json';

import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { Userpilot } from 'userpilot';
import { environment } from '@environments/environment';

Userpilot.initialize(environment.userPilotAuthKey);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'hosted-returns-frontend';

  constructor(
    private router: Router,
    public loaderService: LoaderService,
    public store: Store,
    private configService: ReturnsConfigService,
    private storageService: StorageService,
    private gaAnalyticsService: GoogleAnalyticsService,
    private ctAnalyticsService: ClevertapAnalyticsService,
    private translateService: TranslateService
  ) {
    this.translateService.addLangs(['en']);
    this.translateService.setTranslation('en', defaultLanguage);
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
    // on first page load remove loading spinner
    this.router.events.subscribe(event => {
      if (event instanceof ResolveEnd && !event.url.match('/preview/')) {
        loaderService.isPageLoading$.next(false);
      }
    });
  }

  onActivate($event: any) {
    if ($event instanceof PageNotFoundComponent) {
      document.title = 'Page not found';
    }
  }

  ngOnInit(): void {
    this.configService.setupDSGoogleAnalytics();

    this.store
      .select(getOrderExternalIdFromUrl)
      .pipe()
      .subscribe((data: OrderExternalIdState) => {
        const orderExternalId = data?.orderExternalId;
        if (orderExternalId) {
          this.storageService.setNewKey(orderExternalId);
          const authResp = this.storageService.get('authResp');
          if (authResp) {
            this.store.dispatch(loginSuccess({ resp: authResp }));
          }
        }
      });

    this.store
      .select(getConfig)
      .pipe()
      .subscribe(config => {
        if (config) {
          this.configService.setupTheme(config);
          this.configService.setFontVariables(config);
          this.configService.setPageTitleAndFavicon(config);
          this.gaAnalyticsService.setupGoogleAnalytics(config);
          this.ctAnalyticsService.setupClevertapAnalytics(config);
        }
      });
  }
}
