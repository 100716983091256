import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';

import { CoreComponentsModule } from '@core/components/core-components.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    AuthRoutingModule,
    CoreComponentsModule,
    SharedModule,
    NgOtpInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [AuthRoutingModule],
})
export class AuthModule {}
