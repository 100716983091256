<div class="upload-file-component">
    <div class="d-flex justify-content-between upload-file-container">
        <div class="d-flex flex-column gap-2 w-100 upload-file-left-section">
            <div class="upload-file-heading">
                {{heading | translate}}<sup class="text-danger" *ngIf="isFileSelectMandatory">*</sup>
            </div>
            <div *ngIf="selectedFiles.length" class="d-flex gap-2 upload-file-preview">
                <ng-container *ngFor="let file of selectedFiles">
                    <div
                        class="d-flex justify-content-center align-items-center position-relative upload-file-preview-container">
                        <img [id]="file.fileId" [src]="file.fileUrl || file.base64File"
                            [class.fail-file]="!file.isLoading && file.isUploaded === false" alt="upload-file"
                            width="50px" height="50px" />
                        <div class="d-flex position-absolute justify-content-center align-items-center cursor-pointer retry-file"
                            *ngIf="!file.isLoading && file.isUploaded === false" tabindex="0"
                            (keydown)="retryFileSelect(file)" (click)="retryFileSelect(file)"
                            ngbTooltip="{{'GLOBAL.RETRY' | translate}}" placement="bottom"
                            [tooltipClass]="'upload-file-tooltip'">
                            <i-feather name="rotate-cw"></i-feather>
                        </div>
                        <i-feather name="x-circle" (click)="deleteImage(file)"
                            [class.fail-file]="!file.isLoading && file.isUploaded === false"
                            class="d-flex position-absolute cursor-pointer delete-file"
                            *ngIf="!file.isLoading"></i-feather>
                        <div class="d-flex justify-content-center align-items-center position-absolute upload-file-loading"
                            *ngIf="file.isLoading">
                            <app-bubble-loader></app-bubble-loader>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="d-flex d-md-none w-100">
                <ng-container *ngTemplateOutlet="addImageBtn; context: { mobileView: true }"></ng-container>
            </div>
            <div class="upload-file-subtext">
                <li translate [translateParams]="{value: maxFileCount}">{{subText}}</li>
            </div>
        </div>
        <div class="upload-file-right-section w-100 d-none d-md-flex justify-content-end">
            <ng-container *ngTemplateOutlet="addImageBtn"></ng-container>
        </div>
    </div>
</div>

<ng-template #addImageBtn let-mobileView="mobileView">
    <app-ds-button [class.w-100]="mobileView" *ngIf="!fileSelectLimitReached" (byButtonClick)="openFileSelector()"
        [disabledHoverEffect]="true"
        [buttonStyle]="{ borderColor:'lightgray', width: mobileView ? '100%' : 'revert-layer' }">
        <div class="d-flex gap-2 justify-content-center align-items-center">
            <img [src]="btnIcon" alt="add-img-icon" />
            <span>{{btnText | translate}}</span>
            <input type="file" #fileInput (change)="fileSelected($event)" multiple [accept]="supportedFiles"
                [hidden]="true" />
        </div>
    </app-ds-button>
</ng-template>