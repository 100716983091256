import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import {
  SmartWindow,
  SmartWindowReturn,
  ReturnWindow,
  ReturnSmartWindowsResponse,
} from '@core/models/return-smart-windows';
import { SwiperOptions } from 'swiper/types';
import { setReturnSmartWindows } from '@store/actions/return-smart-windows.action';
import { ReturnService } from '@features/returns/services/returns.service';
import {
  DateFragment,
  getMonthTitle,
  getNextNDates,
  generateHumanReadableTimeFormatFromEpoch,
} from '@core/utils/date-utils';
import { SwiperDirective } from '@shared/directive';
import { selectReturnSmartWindows } from '@store/selectors/return-smart-windows.selector';
import { SmartWindowState } from '@store/states/return-smart-windows.state';

@Component({
  selector: 'app-return-smart-windows',
  templateUrl: './return-smart-windows.component.html',
  styleUrls: ['./return-smart-windows.component.scss'],
})
export class ReturnSmartWindowsComponent implements OnInit, OnDestroy {
  @ViewChild(SwiperDirective)
  swiperDirective!: SwiperDirective;

  @Input() locationExternalId!: string;
  @Input() returnMethodCode!: string;
  @Input() returnSmartWindow?: ReturnWindow;
  @Input() returnSmartWindowDate?: DateFragment;
  @Input() pickupInstruction? = '';
  @Output() byClose = new EventEmitter();
  @Output() setReturnSmartWindow = new EventEmitter();
  @Output() setReturnSmartWindowPickupInstruction = new EventEmitter();

  swiperConfig!: SwiperOptions;

  returnSmartWindowsLoader = false;
  returnSmartWindowsError = false;
  returnSmartWindows: SmartWindow[] = [];
  selectedDateReturnSmartWindows: ReturnWindow[] = [];
  selectedReturnSmartWindow?: ReturnWindow;
  selectedPickupInstruction? = '';
  selectedDate!: DateFragment;
  monthTitle!: string;
  smartWindowsSubscription!: Subscription;
  dateFragements!: DateFragment[];
  maxCalendarDateCount = 7; // Update this for Number of Dates

  constructor(
    private store: Store,
    private returnService: ReturnService
  ) { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    if (!NullEmptyChecker.isDeepNull(this.locationExternalId)) {
      this.dateFragements = getNextNDates(this.maxCalendarDateCount);
      this.monthTitle = getMonthTitle(this.dateFragements);
      const initialSlide = this.dateFragements.findIndex(
        dateFragement => dateFragement.date === this.returnSmartWindowDate?.date
      );

      this.swiperConfig = {
        navigation: {
          nextEl: '.swiper-button-next-nav',
          prevEl: '.swiper-button-prev-nav',
        },
        initialSlide,
        slideToClickedSlide: true,
        slidesOffsetAfter: 30,
        allowTouchMove: true,
        mousewheel: true,
        slidesPerGroup: 1,
        slidesPerView: 5,
        breakpoints: {
          2560: {
            slidesPerView: 7,
          },
          1920: {
            slidesPerView: 5.5,
          },
          1600: {
            slidesPerView: 5.3,
          },
          1500: {
            slidesPerView: 4.5,
          },
          1440: {
            slidesPerView: 4.5,
          },
          1224: {
            slidesPerView: 4,
          },
          1150: {
            slidesPerView: 3.5,
          },
          1024: {
            slidesPerView: 3.3,
          },
          990: {
            slidesPerView: 3,
          },
          820: {
            slidesPerView: 2.5,
          },
          768: {
            slidesPerView: 2.3,
          },
          595: {
            slidesPerView: 4.5,
          },
          480: {
            slidesPerView: 4,
          },
          425: {
            slidesPerView: 4,
          },
          375: {
            slidesPerView: 4,
          },
          320: {
            slidesPerView: 3.5,
          },
          280: {
            slidesPerView: 3,
          },
          220: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        },
        spaceBetween: 10,
      };

      this.selectedReturnSmartWindow = this.returnSmartWindow;
      this.selectedPickupInstruction = this.pickupInstruction;

      this.selectReturnSmartWindowsDate(
        this.returnSmartWindowDate ?? this.dateFragements[0]
      );
    }
  }

  initReturnSmartWindow(storeExternalId: string, startDate: string) {
    return new Promise(resolve => {
      this.smartWindowsSubscription = this.store
        .select(selectReturnSmartWindows)
        .pipe()
        .subscribe(async (smartWindows: SmartWindowState | null) => {
          if (!NullEmptyChecker.isNull(smartWindows)) {
            const key = `${this.returnMethodCode}-${storeExternalId}-${this.selectedDate.date}`;

            const smartWindow = smartWindows![key];
            if (!NullEmptyChecker.isNull(smartWindow)) {
              this.returnSmartWindows = smartWindow;
            } else {
              await this.getReturnSmartWindow(storeExternalId, startDate);
            }
          } else {
            await this.getReturnSmartWindow(storeExternalId, startDate);
          }
          resolve(this.returnSmartWindows);
        });
    });
  }

  getReturnSmartWindow(storeExternalId: string, startDate: string) {
    this.returnSmartWindowsLoader = true;
    const requestOb = {
      storeExternalIds: [storeExternalId],
      startDate,
      numberOfDays: 1,
    };

    return new Promise((resolve, reject) => {
      this.returnService.getReturnSmartWindow(requestOb).subscribe({
        next: smartWindowsResp => {
          this.returnSmartWindowsLoader = false;
          const smartWindows = <ReturnSmartWindowsResponse>smartWindowsResp;
          if (NullEmptyChecker.isNonEmptyArray(smartWindows?.data)) {
            this.returnSmartWindowsError = false;
            this.returnSmartWindows = smartWindows.data;
            this.store.dispatch(
              setReturnSmartWindows({
                smartWindows: this.returnSmartWindows,
                storeExternalId,
                returnMethodCode: this.returnMethodCode,
                date: this.selectedDate.date,
              })
            );
            resolve(this.returnSmartWindows);
          } else {
            reject(this.returnSmartWindows);
            this.returnSmartWindowsError = true;
          }
        },
        error: error => {
          reject(this.returnSmartWindows);
          this.returnSmartWindowsError = true;
          this.returnSmartWindowsLoader = false;
          console.error('Error while fetching return smart windows=>', error);
        },
      });
    });
  }

  async selectReturnSmartWindowsDate(selectedDate: DateFragment) {
    const isDateChange = selectedDate.date !== this.returnSmartWindowDate?.date;

    if (isDateChange) {
      this.deselectReturnSmartWindow();
    }

    this.selectedDate = selectedDate;
    const [formatedSelectedDate] = selectedDate.dateObj
      .toISOString()
      .split('T');

    await this.initReturnSmartWindow(
      this.locationExternalId,
      formatedSelectedDate
    );
    this.smartWindowsSubscription.unsubscribe();

    if (NullEmptyChecker.isNonEmptyArray(this.returnSmartWindows)) {
      this.selectedDateReturnSmartWindows =
        this.returnSmartWindows[0].return.filter(
          (swr: SmartWindowReturn) => swr.date === formatedSelectedDate
        )[0].windows;
    }
  }

  getEpochToFormatedTime(window: ReturnWindow) {
    return generateHumanReadableTimeFormatFromEpoch(
      window!.pickupTime!.startsAt!,
      window!.pickupTime!.endsAt!,
      (window.tz || window.timeZone)!
    );
  }

  changeReturnSmartWindowDate(window: ReturnWindow) {
    this.setReturnSmartWindow.emit({ window, date: this.selectedDate });
  }

  setPickupInstruction(pickupInstruction: string) {
    this.selectedPickupInstruction = pickupInstruction;
  }

  selectReturnSmartWindow(window: ReturnWindow) {
    this.selectedReturnSmartWindow = window;
  }

  deselectReturnSmartWindow() {
    this.selectedReturnSmartWindow = undefined;
  }

  saveReturnSmartWindowSlot() {
    this.setReturnSmartWindow.emit({
      window: this.selectedReturnSmartWindow,
      date: this.selectedDate,
      timezone: NullEmptyChecker.isNonEmptyArray(this.returnSmartWindows) ?  this.returnSmartWindows[0].timeZone : "" ,
    });
    this.setReturnSmartWindowPickupInstruction.emit(
      this.selectedPickupInstruction
    );
    this.byClose.emit();
  }

  ngOnDestroy(): void {
    if (this.smartWindowsSubscription)
      this.smartWindowsSubscription.unsubscribe();
  }
}
