import { createReducer, on } from '@ngrx/store';
import { showToastError } from '@store/actions/error.action';
import { ErrorState, initialErrorState } from '@store/states/error.state';

const _errorReducer = createReducer(
  initialErrorState,
  on(showToastError, (state, action): ErrorState => {
    return {
      ...state,
      showToast: action.showToast,
      errorMsg: action.errorMsg,
      close: action.close,
      data: action.data,
      timeoutMs: action.timeoutMs,
    };
  })
);

export function errorReducer(state: ErrorState, action: any) {
  return _errorReducer(state, action);
}
