import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { BaseService } from './base.service';
import { catchError, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { loginSuccess } from '@store/actions/auth.action';

@Injectable({
  providedIn: 'root',
})
export class ItemListService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) {
    super();
  }

  getItemList() {
    return this.httpClient
      .get(`${this.serverRootPathV2}returns/lookup/using-token`)
      .pipe(
        tap((val: any) => {
          this.store.dispatch(loginSuccess({ resp: val }));
        }),
        catchError(e => {
          let errorMsg: string;
          console.error('error in fetching itemlist', e);
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }

  updatePhoneNumber(invoiceIds: string[], phoneNumber: string) {
    const requestPayload = {
      invoiceIds,
      data: { customer: { phone: phoneNumber } },
    };
    return this.httpClient
      .patch(`${this.serverRootPathV2}returns/invoices/bulk`, requestPayload)
      .pipe(
        tap((val: any) => {
          return val;
        }),
        catchError(e => {
          let errorMsg: string;
          console.error('error in updating phone number', e);
          if (e.error?.message) {
            errorMsg = e.error.message;
          } else {
            errorMsg = 'Something went wrong';
          }
          return throwError(() => new Error(errorMsg));
        })
      );
  }
}
